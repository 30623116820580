import DeviceTrendParam from "../enums/DeviceTrendParamEnum";
import DeviceTypeEnum from "../enums/DeviceTypeEnum";
import GlucoseReadingTagsEnum from "../enums/GlucoseReadingTagsEnum";
import { QueryString_stringify } from "../helpers/QueryStringHelper";
import DeviceReadingType from "../types/DeviceReadingType";
import LatestReadingsType from "../types/LatestReadingsType";
import ReadingCompliancePeriodType from "../types/ReadingCompliancePeriodType";
import ReadingDaysType from "../types/ReadingDaysType";
import { apiReadings } from "./AxiosService";

interface ReadingTrendsByMemberParams {
  device_type: DeviceTrendParam[];
  trend_days?: number[];
  glucose_reading_tags?: GlucoseReadingTagsEnum[];
  enddate?: number;
}

interface ReadingsByMemberParams {
  device_type?: DeviceTypeEnum;
  aggregate?: "compliance_periods" | "reading_days";
  sort?: string;
  limit?: number;
  startdate?: number;
  enddate?: number;
}

const readingsService = apiReadings
  .enhanceEndpoints({
    addTagTypes: []
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Queries
      getReadingsByMember: builder.query<
        DeviceReadingType[],
        { memberId: string; paramsObject: ReadingsByMemberParams }
      >({
        query: ({ memberId, paramsObject }) => {
          const params = QueryString_stringify(paramsObject);

          return {
            url: `/readings/patient/${memberId}?${params}`,
            method: "GET"
          };
        },
        providesTags: []
      }),

      getReadingCompliancePeriodsByMember: builder.query<
        ReadingCompliancePeriodType[],
        { memberId: string; limit: number }
      >({
        query: ({ memberId, limit = 2 }) => {
          const params = QueryString_stringify({
            aggregate: "compliance_periods",
            limit
          });
          return {
            url: `/readings/patient/${memberId}?${params}`,
            method: "GET"
          };
        },
        providesTags: []
      }),
      getReadingDaysByMember: builder.query<
        ReadingDaysType,
        { memberId: string; paramsObject: ReadingsByMemberParams }
      >({
        query: ({ memberId, paramsObject }) => {
          const params = QueryString_stringify({
            aggregate: "reading_days",
            ...paramsObject
          });
          return {
            url: `/readings/patient/${memberId}?${params}`,
            method: "GET"
          };
        },
        providesTags: []
      }),
      getLatestReadingsByMember: builder.query<
        LatestReadingsType,
        { memberId: string; devices: DeviceTrendParam[] }
      >({
        query: ({ memberId, devices }) => {
          return {
            url: `/readings/patient/${memberId}/latest?device_type=${devices}`,
            method: "GET"
          };
        },
        transformResponse: (response: LatestReadingsType) => {
          let finalResponse = response;
          if (response.weight_scale) {
            finalResponse = {
              ...response,
              weight_scale: {
                ...response.weight_scale,
                weight: Number.parseFloat(
                  response.weight_scale.weight
                )?.toFixed(1)
              }
            };
          }
          return finalResponse;
        },
        providesTags: []
      }),
      getReadingsTrendsByMember: builder.query<
        DeviceReadingType,
        { memberId: string; paramsObject: ReadingTrendsByMemberParams }
      >({
        query: ({ memberId, paramsObject }) => {
          const params = QueryString_stringify(paramsObject);

          return {
            url: `/readings/patient/${memberId}/chart_trends?${params}`,
            method: "GET"
          };
        },
        providesTags: []
      })
    })
  });

export const {
  useGetReadingsByMemberQuery,
  useLazyGetReadingsByMemberQuery,
  useLazyGetReadingCompliancePeriodsByMemberQuery,
  useLazyGetReadingDaysByMemberQuery,
  useGetReadingsTrendsByMemberQuery,
  useGetLatestReadingsByMemberQuery
} = readingsService;
