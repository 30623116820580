import RouteComponentType from "../types/RouteComponentType";
import ComponentLoader from "./ComponentLoader";
import { v4 as uuidv4 } from "uuid";
import { ComponentCardContainer } from "../styling/StyleComponents";

interface IProps {
  components: RouteComponentType<any>[];
}

const getComponents = (components: RouteComponentType<any>[]) => {
  return components?.map(
    (component: RouteComponentType<any>, index: number) => {
      // we need a uuid to give a unique key to prevent the same React component on different pages
      // (for example, All Patients and Awaiting Provider)
      // from sharing the same state when navigating between those routes
      const key = `${index}-${uuidv4()}`;
      return <ComponentLoader key={key} component={component} />;
    }
  );
};

const RouteWrapper = ({ components }: IProps) => {
  const comps = getComponents(components);
  return <ComponentCardContainer>{comps}</ComponentCardContainer>;
};

export default RouteWrapper;
