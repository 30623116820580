import Table from "../../components/Table/Table";
import {
  ComponentHeader,
  TableComponentContainer
} from "../../styling/StyleComponents";
import { TableProps } from "../../components/Table/TableTypes";
import styled from "@emotion/styled";
import useGetRecentOrders from "../../hooks/data_loaders/useGetRecentOrders";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import ErrorComponent from "../../components/ErrorComponent";
import { checkIdValid } from "common/helpers/helpers";
import { Typography } from "@mui/material";
import useSanitizedParams from "../../hooks/useSanitizedParams";

const TableHeading = styled(ComponentHeader)`
  margin: 24px 0px;
`;

const DAYS = 15;

const RecentOrdersTable = ({ tableColumns, externalLink }: TableProps) => {
  const params = useSanitizedParams();
  const { nurseId, staffId } = params;
  const userId = nurseId || staffId;
  const isValidId = checkIdValid(userId);

  const { data, isLoading, error, isError } = useGetRecentOrders(userId, 15);

  return (
    <TableComponentContainer>
      <TableHeading>{`Recent Orders (last ${DAYS} days)`}</TableHeading>
      {isLoading && <LoadingFallback delay={500} count={10} />}
      {data && (
        <Table
          data={data}
          tableProps={{ externalLink }}
          tableColumns={tableColumns}
          tableHeader="{{COUNT}} orders"
          noDataText={`No recent orders in the last ${DAYS} days found.`}
          estimateRowSize={(index: number) => {
            const item = data[index];
            const itemsAmount = item?.order?.items?.length;

            return Math.max(79, 55 + 12 * itemsAmount);
          }}
        />
      )}
      {isError && <ErrorComponent error={error} />}
      {!isValidId && (
        <Typography
          variant="body1"
          color="error"
        >{`Error: Invalid Nurse ID ${userId}`}</Typography>
      )}
    </TableComponentContainer>
  );
};

export default RecentOrdersTable;
