import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { ReactElement, ReactNode } from "react";
import { blue } from "./colors";

interface MUITooltipProps {
  title?: ReactNode;
  placement?: TooltipProps["placement"];
  enterDelay?: TooltipProps["enterDelay"];
  leaveDelay?: TooltipProps["leaveDelay"];
  children?: ReactElement;
  backgroundColor?: string;
}

interface StyledTooltipProps extends TooltipProps {
  backgroundColor: string;
}

const StyledTooltip = styled(
  ({ className = "defaultTooltipClassname", ...props }: StyledTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
  { shouldForwardProp: (prop) => prop !== "backgroundColor" }
)(({ theme, backgroundColor }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: "white",
    backgroundColor: backgroundColor,
    width: "max-content",
    maxWidth: "70vw",
    padding: "1.3ch 1.5ch",
    borderRadius: "8px",
    // tbd use Paper component for box shadow? need to investigate
    boxShadow:
      "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 0px 16px -4px rgba(16, 24, 40, 0.15)",
    fontSize: "16px",
    fontFamily: theme.typography.fontFamily,
    transition: "all 0.66s ease-out"
  }
}));

function MUITooltip({
  title,
  placement = "right",
  enterDelay = 0,
  leaveDelay = 0,
  children,
  backgroundColor = blue[700]
}: MUITooltipProps) {
  return (
    <StyledTooltip
      placement={placement}
      enterDelay={enterDelay}
      leaveDelay={leaveDelay}
      title={title}
      backgroundColor={backgroundColor}
    >
      {children}
    </StyledTooltip>
  );
}

export default MUITooltip;
