import { useMemo } from "react";

import RolesEnum from "common/enums/RolesEnum";
import { useGetMemberSurveysQuery } from "common/services/SurveyService";
import {
  HeaderComponent,
  TableComponentContainer
} from "../../styling/StyleComponents";
import Table from "../../components/Table/Table";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import SurveyLinkedEntitiesEnum from "common/enums/SurveyLinkedEntitiesEnum";
import ErrorComponent from "../../components/ErrorComponent";
import { TableColumn } from "../../components/Table/TableTypes";
import { Typography, styled } from "@mui/material";
import { getNameOrUsername } from "common/helpers/helpers";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import { MEMBERS_PATH } from "../../routes/RouteComponents";
import useSanitizedParams from "../../hooks/useSanitizedParams";
import MemberDetailsHeader from "../MemberDetails/Header/MemberDetailsHeader";
import { Flexbox } from "../../styling/NewStyleComponents";
import MemberDetailsLayout from "../MemberDetails/MemberDetailsLayout";

const MemberDetailsOverviewContainer = styled("div")`
  position: sticky;
  overflow: hidden;
  margin: 2.5%;
`;

interface IProps {
  submitter_roles?: RolesEnum[];
  componentHeader?: string;
  tableHeader?: string;
  linked_entities?: SurveyLinkedEntitiesEnum[];
  size?: number;
  tableColumns?: TableColumn[];
  showHeader?: boolean;
  hideReadings?: boolean;
}

const DEFAULT_TABLE_COLUMNS = [
  { name: "name" },
  { name: "status" },
  { name: "phone" },
  { name: "birthdate" },
  {
    id: "submitter_name",
    name: "name",
    accessor: "submitter",
    header: "Submitter"
  },
  {
    name: "roles",
    accessor: "submitter.roles",
    header: "Submitter Role"
  },
  {
    name: "default",
    id: "reasonCategory",
    accessor: "category",
    header: "Category"
  },
  {
    name: "default",
    id: "subCategory",
    accessor: "subCategory",
    header: "Sub Category"
  }
];

const ReportedCancellationsByMember = ({
  submitter_roles,
  componentHeader,
  tableHeader,
  linked_entities = [
    SurveyLinkedEntitiesEnum.PATIENT,
    SurveyLinkedEntitiesEnum.SUBMITTER
  ],
  size,
  tableColumns = DEFAULT_TABLE_COLUMNS,
  showHeader = true,
  hideReadings = false
}: IProps) => {
  const params = useSanitizedParams();

  const { memberId } = params;

  const { data, isLoading, error } = useGetMemberSurveysQuery({
    patient_id: memberId,
    submitter_roles,
    linked_entities,
    size
  });

  const { data: patient } = useGetMemberWithUsernameQuery(
    {
      username: memberId
    },
    { skip: memberId === undefined }
  );

  const processedData = useMemo(() => {
    if (data === undefined) return undefined;
    return data.map((item) => {
      const answerCategory = item.patient_survey_answers.find(
        (answer) => answer.category !== "General"
      );

      const notes = item.patient_survey_answers.find(
        (item) => item.category === "General" && item.question === "Notes"
      );

      return {
        ...item,
        category: answerCategory?.category,
        subCategory: answerCategory?.answer,
        notes: notes?.answer
      };
    });
  }, [data]);

  const tableHeaderProcessed = useMemo(() => {
    if (tableHeader === undefined || processedData === undefined)
      return undefined;
    return tableHeader.replaceAll("{{COUNT}}", "" + processedData.length);
  }, [tableHeader, processedData]);

  return (
    <MemberDetailsOverviewContainer>
      <Flexbox
        flexDirection="column"
        height="inherit"
        width="inherit"
        gap="16px"
        overflow="hidden"
      >
        <MemberDetailsHeader key={`${memberId}-header-cancellation`} />
        <MemberDetailsLayout
          key={`${memberId}-layout-orders`}
          memberId={memberId}
          hideReadings={hideReadings}
        >
          <TableComponentContainer
            hideBorder={!showHeader}
            width="calc(95% - 2px)"
            margin="0"
          >
            {showHeader && (
              <>
                <HeaderComponent componentHeader={componentHeader} />

                <Typography variant="h6">
                  {getNameOrUsername(patient?.patient)}
                </Typography>
              </>
            )}
            {isLoading && <LoadingFallback delay={500} count={10} />}

            {processedData && (
              <Table
                tableColumns={tableColumns}
                tableProps={{
                  externalLink: MEMBERS_PATH
                }}
                tableHeader={tableHeaderProcessed}
                data={processedData}
              />
            )}

            {error && <ErrorComponent error={error} />}
          </TableComponentContainer>
        </MemberDetailsLayout>
      </Flexbox>
    </MemberDetailsOverviewContainer>
  );
};

export default ReportedCancellationsByMember;
