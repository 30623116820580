import { Dispatch, SetStateAction, ChangeEvent } from "react";
import styled from "@emotion/styled";
import { useFormik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { ErrorText, TurqoiseButton, WhiteButton } from "../../styling";
import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  ModalHeaderFlexbox,
  StyledModal
} from "../../styling/StyleModal";
import {
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import ValidateAddressResponseType from "common/types/ValidateAddress/ValidateAddressResponseType";
import AddressValidationStatusEnum from "common/enums/AddressValidationStatusEnum";
import ValidateAddressMessageType from "common/types/ValidateAddress/ValidateAddressMessageType";
import {
  isValidUSPostalCode,
  formatName,
  isStateAbbreviation,
  validateFieldLength,
  getStates
} from "common/helpers/helpers";
import { DangerIcon } from "../../assets/images/icons";
import { TextFieldComponent } from "../../helpers/components/Forms/FormHelpers";

const stateDropdownOptions = getStates();

const defaultFormStyles = { mb: "20px" };

const StyledDangerIcon = styled(DangerIcon)`
  width: 23px;
  height: 23px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 4px;
`;

const RadioGroupHeading = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #434656;
`;

interface FormValues {
  addressSource: "original" | "matched" | "input" | null;
  city: string;
  country: string;
  postal_code: string;
  state: string;
  street1: string;
  street2: string;
}

interface IProps {
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  addresses: ValidateAddressResponseType;
  key: string;
  executeOnSubmit: (address: object) => void;
}

function DisplayMessages({
  messages
}: {
  messages: ValidateAddressMessageType[];
}) {
  if (!messages) {
    return null;
  }

  return (
    <>
      {messages.map((message, index) => {
        return (
          <ErrorText key={index}>
            {formatName(message.level)}: {message.description}
          </ErrorText>
        );
      })}
    </>
  );
}

const PatientDataFormValidateAddressModal = ({
  modalOpen,
  setModalOpen,
  addresses,
  executeOnSubmit
}: IProps) => {
  const { original_address, matched_address, status, messages } =
    addresses || {};

  const validate = (values: FormValues) => {
    const errors = {};

    if (values.addressSource === "input") {
      if (!values.street1) {
        errors["street1"] = "Required";
      }
      if (!validateFieldLength(2, 50, values["street1"])) {
        errors["street1"] = "Please enter a value between 2 and 50 characters.";
      }

      if (values.street2 && !validateFieldLength(2, 50, values["street2"])) {
        errors["street2"] = "Please enter a value up to 50 characters.";
      }

      if (!values.city) {
        errors["city"] = "Required";
      }
      if (!validateFieldLength(2, 50, values["city"])) {
        errors["city"] = "Please enter a value between 2 and 50 characters.";
      }

      if (!values.state) {
        errors["state"] = "Required";
      }

      if (!isStateAbbreviation(values.state)) {
        errors["state"] = "Please enter a valid US state abbreviation.";
      }

      if (!values.postal_code) {
        errors["postal_code"] = "Required";
      }

      if (!isValidUSPostalCode(values.postal_code)) {
        errors["postal_code"] = "Please enter a valid US postal code.";
      }
    }
    if (!values.addressSource) {
      errors["addressSource"] = "Required";
    }

    return errors;
  };

  const setFieldValue = (key, value) => {
    formik.setFieldValue(key, value).catch((error) => {});
  };

  const onSubmit = async (values: FormValues) => {
    const { addressSource } = values;
    let finalAddress;
    if (addressSource === "original") {
      // use original address
      finalAddress = original_address;
    } else if (addressSource === "matched") {
      // use matched address
      finalAddress = matched_address;
    } else {
      // use input address
      finalAddress = {
        street1: values.street1,
        street2: values.street2,
        city: values.city,
        state: values.state,
        postal_code: values.postal_code,
        country: "US"
      };
    }

    setModalOpen(false);
    formik.resetForm();
    executeOnSubmit(finalAddress);
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      addressSource: null,
      city: original_address?.city || "",
      country: original_address?.country || "",
      postal_code: original_address?.postal_code || "",
      state: original_address?.state || "",
      street1: original_address?.street1 || "",
      street2: original_address?.street2 || ""
    },
    enableReinitialize: true,
    validate,
    onSubmit
  });

  const handleModalClose = () => {
    formik.resetForm();
    setModalOpen(false);
  };

  const handleRadioButtonChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(
      "addressSource",
      (event.target as HTMLInputElement).value
    );
  };

  return (
    <StyledModal
      isOpen={modalOpen}
      contentLabel="Assign Provider to Patient Modal"
      modalHeight="70vh"
      onRequestClose={handleModalClose}
    >
      <Form onSubmit={formik.handleSubmit}>
        <ModalHeader onRequestClose={handleModalClose}>
          <ModalHeaderFlexbox>
            <div>
              {status === AddressValidationStatusEnum.VALID ? (
                "Choose Member Address"
              ) : (
                <>
                  <StyledDangerIcon />
                  &nbsp;Member Address Validation Failed
                </>
              )}
            </div>
          </ModalHeaderFlexbox>
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%"
            }}
          >
            <div>
              <RadioGroup
                value={formik.values.addressSource}
                onChange={handleRadioButtonChange}
              >
                <DisplayMessages messages={messages} />
                <Typography variant="body1">
                  Select the correct patient address:
                </Typography>
                {original_address && (
                  <StyledFormControlLabel
                    key="original"
                    value="original"
                    control={<Radio />}
                    label={
                      <>
                        <RadioGroupHeading>Original Address</RadioGroupHeading>
                        <br />
                        {original_address.street1}
                        {original_address.street2
                          ? `, ${original_address.street2}`
                          : ""}
                        ,&nbsp;{original_address.city},&nbsp;
                        {original_address.state},&nbsp;
                        {original_address.country},&nbsp;
                        {original_address.postal_code}
                      </>
                    }
                  />
                )}
                {matched_address && (
                  <StyledFormControlLabel
                    key="matched"
                    value="matched"
                    control={<Radio />}
                    label={
                      <>
                        <RadioGroupHeading>Suggested Address</RadioGroupHeading>
                        <br />
                        {matched_address.street1}
                        {matched_address.street2
                          ? `, ${matched_address.street2}`
                          : ""}
                        ,&nbsp;{matched_address.city},&nbsp;
                        {matched_address.state},&nbsp;
                        {matched_address.country},&nbsp;
                        {matched_address.postal_code}
                      </>
                    }
                  />
                )}

                {status !== AddressValidationStatusEnum.VALID && (
                  <>
                    <Typography variant="body1" sx={{ marginTop: "8px" }}>
                      Or update the address:
                    </Typography>
                    <StyledFormControlLabel
                      key="input"
                      value="input"
                      control={<Radio />}
                      label={
                        <RadioGroupHeading>Enter new address</RadioGroupHeading>
                      }
                    />
                  </>
                )}
              </RadioGroup>
              {formik?.values?.addressSource === "input" && (
                <div style={{ transition: "height 0.66s ease-out" }}>
                  <TextFieldComponent
                    sx={defaultFormStyles}
                    formik={formik}
                    fieldName={"street1"}
                    label={"Street 1"}
                    placeholder={"Enter Street 1"}
                    fullWidth={true}
                    setFieldValue={setFieldValue}
                    disabled={formik.values.addressSource !== "input"}
                  />
                  <TextFieldComponent
                    sx={defaultFormStyles}
                    formik={formik}
                    fieldName={"street2"}
                    label={"Street 2"}
                    placeholder={"Enter Street 2"}
                    fullWidth={true}
                    setFieldValue={setFieldValue}
                    disabled={formik.values.addressSource !== "input"}
                  />
                  <TextFieldComponent
                    sx={defaultFormStyles}
                    formik={formik}
                    fieldName={"city"}
                    label={"City"}
                    placeholder={"Enter City"}
                    fullWidth={true}
                    setFieldValue={setFieldValue}
                    disabled={formik.values.addressSource !== "input"}
                  />
                  <TextFieldComponent
                    sx={defaultFormStyles}
                    formik={formik}
                    fieldName={"state"}
                    label={"State"}
                    select={true}
                    SelectProps={{
                      variant: "outlined",
                      MenuProps: { PaperProps: { sx: { maxHeight: 200 } } }
                    }}
                    placeholder={"Enter State"}
                    fullWidth={true}
                    setFieldValue={setFieldValue}
                    disabled={formik.values.addressSource !== "input"}
                  >
                    {stateDropdownOptions.map((state) => (
                      <MenuItem key={state.value} value={state.value}>
                        {state.label}
                      </MenuItem>
                    ))}
                  </TextFieldComponent>

                  <TextFieldComponent
                    sx={defaultFormStyles}
                    formik={formik}
                    fieldName={"country"}
                    label={"Country"}
                    placeholder={"Enter Country"}
                    fullWidth={true}
                    select={true}
                    setFieldValue={setFieldValue}
                    value={"US"}
                    disabled={formik.values.addressSource !== "input"}
                  >
                    <MenuItem key="US" value="US">
                      US
                    </MenuItem>
                  </TextFieldComponent>
                  <TextFieldComponent
                    sx={defaultFormStyles}
                    formik={formik}
                    fieldName={"postal_code"}
                    label={"Postal Code"}
                    placeholder={"Enter Postal Code"}
                    fullWidth={true}
                    setFieldValue={setFieldValue}
                    disabled={formik.values.addressSource !== "input"}
                  />
                </div>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <ModalFooterButtons>
            <TurqoiseButton
              disabled={!formik.dirty || !formik.isValid}
              type="submit"
            >
              Use Address
            </TurqoiseButton>
            <WhiteButton onClick={handleModalClose}>Cancel</WhiteButton>
          </ModalFooterButtons>
        </ModalFooter>
      </Form>
    </StyledModal>
  );
};

export { PatientDataFormValidateAddressModal };
