import { useState } from "react";
import { Typography } from "@mui/material";
import { DateTime } from "luxon";

import CareFlowFieldComponentEnum from "common/enums/CareFlowFieldComponentEnum";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import DeviceTrendParam from "common/enums/DeviceTrendParamEnum";

import { Column } from "../../../styling/StyleComponents";
import ChartControls from "../../MemberDetails/Readings/ChartControls";
import GlucoseChart from "../../MemberDetails/Readings/GlucoseChart";
import BloodPressureChart from "../../MemberDetails/Readings/BloodPressureChart";
import PulseChart from "../../MemberDetails/Readings/PulseChart";
import OximeterChart from "../../MemberDetails/Readings/OximeterChart";
import WeightScaleChart from "../../MemberDetails/Readings/WeightScaleChart";
import MemberAgreements from "../../MemberDetails/Profile/MemberAgreements";
import OrderHistoryTable from "../../OrderDevices/OrderHistoryTable";
import VisitFieldConnectWithMember from "./VisitFieldConnectWithMember";
import VisitFieldMemberRelationshipNotes from "./VisitFieldMemberRelationshipNotes";
import UpcomingAppointments from "../../MemberDetails/Appointments/UpcomingAppointments";
import { DAYS_TO_LOOK_AHEAD } from "../../MemberDetails/Appointments/constants";
import VisitFieldBillingEntryNurse from "./VisitFieldBillingEntryNurse";

import VisitFieldGenerateNotes from "./VisitFieldGenerateNotes";
import VisitFieldStartEncounter from "./VisitFieldStartEncounter";

import { gray } from "../../../styling/colors";
import VisitFieldQuote from "./VisitFieldQuote";
import VisitFieldInLicensedState from "./VisitFieldInLicensedState";

const VisitContentComponentField = ({
  field,
  width,
  visit
}: {
  field: CareFlowFieldType;
  width: number;
  visit: GetVisitResponseType;
}) => {
  const memberId = visit?.patient_id;
  const { data: member } = useGetMemberWithUsernameQuery(
    {
      username: memberId,
      linked_entities:
        field.value === CareFlowFieldComponentEnum.MISSING_AGREEMENTS_TABLE
          ? [MemberLinkedEntitiesEnum.MISSING_LEGAL_FORMS_FULL]
          : []
    },
    { skip: memberId === undefined }
  );

  const [showAverages, setShowAverages] = useState<boolean>(true);
  const [dateFilter, setDateFilter] = useState<number>(30);

  const handleDataChange = ({ dateFilter, showAverages }) => {
    setDateFilter(dateFilter);
    setShowAverages(showAverages);
  };

  switch (field.component_type) {
    case CareFlowFieldComponentEnum.GLUCOSE_READING_CHART:
      return (
        <Column>
          <ChartControls
            readingType={DeviceTrendParam.GLUCOSE}
            handleDataChange={handleDataChange}
          />
          <GlucoseChart
            patient={member}
            days={dateFilter}
            showAverages={showAverages}
          />
        </Column>
      );
    case CareFlowFieldComponentEnum.BLOOD_PRESSURE_READING_CHART:
      return (
        <Column>
          <ChartControls
            readingType={DeviceTrendParam.BLOOD_PRESSURE}
            handleDataChange={handleDataChange}
          />
          <BloodPressureChart
            patient={member}
            days={dateFilter}
            showAverages={showAverages}
          />
        </Column>
      );
    case CareFlowFieldComponentEnum.PULSE_READING_CHART:
      return (
        <Column>
          <ChartControls
            readingType={DeviceTrendParam.PULSE}
            handleDataChange={handleDataChange}
          />
          <PulseChart
            patient={member}
            days={dateFilter}
            showAverages={showAverages}
          />
        </Column>
      );
    case CareFlowFieldComponentEnum.OXIMETER_READING_CHART:
      return (
        <Column>
          <ChartControls
            readingType={DeviceTrendParam.OXIMETER}
            handleDataChange={handleDataChange}
          />
          <OximeterChart
            patient={member}
            days={dateFilter}
            showAverages={showAverages}
          />
        </Column>
      );
    case CareFlowFieldComponentEnum.WEIGHT_SCALE_READING_CHART:
      return (
        <Column>
          <ChartControls
            readingType={DeviceTrendParam.WEIGHT_SCALE}
            handleDataChange={handleDataChange}
          />
          <WeightScaleChart
            patient={member}
            days={dateFilter}
            showAverages={showAverages}
          />
        </Column>
      );
    case CareFlowFieldComponentEnum.MISSING_AGREEMENTS_TABLE:
      return (
        <MemberAgreements
          hideCompletedAgreements
          hideHeader
          patient={member}
          width={width}
        />
      );
    case CareFlowFieldComponentEnum.ORDER_HISTORY:
      return (
        <OrderHistoryTable memberId={memberId} memberDetails width={width} />
      );
    case CareFlowFieldComponentEnum.CONNECT_WITH_MEMBER:
      return (
        <VisitFieldConnectWithMember
          member={member}
          field={field}
          visit={visit}
        />
      );
    case CareFlowFieldComponentEnum.MEMBER_RELATIONSHIP_NOTES:
      return <VisitFieldMemberRelationshipNotes field={field} />;
    case CareFlowFieldComponentEnum.UPCOMING_APPOINTMENTS: {
      const now = DateTime.now().endOf("minute");
      const endDate = now.endOf("day").plus({ days: DAYS_TO_LOOK_AHEAD });
      return (
        <UpcomingAppointments
          memberId={memberId}
          startDate={now}
          endDate={endDate}
          isStaffDetails={false}
        />
      );
    }
    case CareFlowFieldComponentEnum.BILLING_ENTRY_NURSE:
      return <VisitFieldBillingEntryNurse field={field} visit={visit} />;
    case CareFlowFieldComponentEnum.GENERATE_NOTES:
      return <VisitFieldGenerateNotes field={field} visit={visit} />;
    case CareFlowFieldComponentEnum.START_ENCOUNTER:
      return <VisitFieldStartEncounter visit={visit} />;
    case CareFlowFieldComponentEnum.END_ENCOUNTER:
      return <VisitFieldStartEncounter visit={visit} />;
    case CareFlowFieldComponentEnum.QUOTE:
      return <VisitFieldQuote field={field} />;
    case CareFlowFieldComponentEnum.IN_LICENSED_STATE:
      return <VisitFieldInLicensedState field={field} visit={visit} />;
    default:
      return (
        <Typography variant="body1" color={gray[900]}>
          {field.component_type} COMPONENT IS UNDER CONSTRUCTION
        </Typography>
      );
  }
};

export default VisitContentComponentField;
