import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { CheckCircle } from "@mui/icons-material";

import { AlertButtonType, ModalSize } from "common/redux/AlertSlice";
import { Alert_close } from "common/helpers/AlertHelper";
import { RootState, useAppDispatch } from "common/redux";

import {
  ErrorTextSpan,
  TurqoiseButton,
  WhiteButton,
  DestructiveButton
} from "../../styling";
import { DangerIcon, HelpIcon } from "../../assets/images/icons";

import {
  StyledModal,
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader
} from "../../styling/StyleModal";
import { useTheme } from "@mui/material";

const getDimensionsFromSize = (size: ModalSize) => {
  switch (size) {
    case "small":
      return { width: "300px", height: "350px" };
    case "medium":
      return { width: "640px", height: "400px" };
    case "large":
      return { width: "800px", height: "450px" };
    default:
      return { width: size?.width, height: size?.height };
  }
};

const StyledDangerIcon = styled(DangerIcon)`
  width: 23px;
  height: 23px;
`;

const Container = styled.div`
  z-index: 300000;
`;

// Look for a better name? Pretty sure we can improve naming here.
const ReduxModal = () => {
  const dispatch = useAppDispatch();
  // const { visible, title, content, buttons, options, type, loading } =
  const { visibleModals } = useSelector((state: RootState) => state.alert);

  return (
    <Container>
      {visibleModals?.map((modal) => {
        const {
          visible,
          title,
          content,
          buttons,
          options,
          type,
          loading,
          id,
          row,
          size,
          hideCloseIcon = false
        } = modal;

        const { width, height } = getDimensionsFromSize(size);

        return (
          <StyledModal
            key={id}
            isOpen={visible}
            contentLabel={title}
            modalHeight={height}
            modalWidth={width}
            shouldCloseOnEsc={options?.cancelable}
            onRequestClose={() => Alert_close({ dispatch, id })}
          >
            <RenderHeader
              title={title}
              type={type}
              id={id}
              hideCloseIcon={hideCloseIcon}
            />
            <ModalBody>{content}</ModalBody>
            {buttons?.length > 0 && (
              <ModalFooter>
                <ModalFooterButtons row={row}>
                  {buttons?.map((button) => {
                    return (
                      <RenderButton
                        key={button.text}
                        button={button}
                        loading={button?.hasLoadingState && loading}
                      />
                    );
                  })}
                </ModalFooterButtons>
              </ModalFooter>
            )}
          </StyledModal>
        );
      })}
    </Container>
  );
};

interface IHeaderProps {
  title?: string;
  type: string | undefined;
  id?: string;
  hideCloseIcon?: boolean;
}

const RenderHeader = ({ title, type, id, hideCloseIcon }: IHeaderProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  switch (type) {
    case "error":
    case "warning":
      return (
        <ModalHeader
          hideCloseIcon={hideCloseIcon}
          onRequestClose={() => Alert_close({ dispatch, id })}
        >
          <StyledDangerIcon />
          &nbsp;
          <ErrorTextSpan>{title}</ErrorTextSpan>
        </ModalHeader>
      );
    case "success":
      return (
        <ModalHeader
          hideCloseIcon={hideCloseIcon}
          onRequestClose={() => Alert_close({ dispatch, id })}
        >
          <CheckCircle style={{ color: theme.palette.primary.main }} />
          {title}
        </ModalHeader>
      );
    case "info":
      return (
        <ModalHeader
          hideCloseIcon={hideCloseIcon}
          onRequestClose={() => Alert_close({ dispatch, id })}
        >
          <HelpIcon />
          {title}
        </ModalHeader>
      );
    default:
      return (
        <ModalHeader
          hideCloseIcon={hideCloseIcon}
          onRequestClose={() => Alert_close({ dispatch, id })}
        >
          {title}
        </ModalHeader>
      );
  }
};

interface IButtonProps {
  button: AlertButtonType;
  loading?: boolean;
}

const RenderButton = ({ button, loading }: IButtonProps) => {
  const { style, text, onPress } = button;

  function clickHandler() {
    onPress && onPress();
  }

  switch (style) {
    case "cancel":
      return (
        <WhiteButton onClick={clickHandler} loading={loading}>
          {text}
        </WhiteButton>
      );
    case "destructive":
      return (
        <DestructiveButton loading={loading} onClick={clickHandler}>
          {text}
        </DestructiveButton>
      );
    default:
      return (
        <TurqoiseButton loading={loading} onClick={clickHandler} type="submit">
          {text}
        </TurqoiseButton>
      );
  }
};

export default ReduxModal;
