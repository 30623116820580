import { useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import { useFormik } from "formik";
import { MenuItem, TextField } from "@mui/material";

import { useGetProvidersMetadataQuery } from "common/services/ProvidersMetadataService";
import { ProviderMetadataType } from "common/types/ProviderMetadataType";
import { useGetAthenaMemberDetailsQuery } from "common/services/MemberRegistrationService";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import { useUpdateMemberCarersMutation } from "common/services/MemberRegistrationService";
import { getNameOrUsername } from "common/helpers/helpers";

import {
  DestructiveButton,
  ErrorText,
  TurqoiseButton,
  WhiteButton
} from "../../../../styling";
import ErrorComponent from "../../../../components/ErrorComponent";
import LoadingFallback from "common/helpers/components/LoadingFallback";

import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  StyledModal
} from "../../../../styling/StyleModal";
import { useDeleteCarerMemberProviderMutation } from "common/services/CarerMembersService";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import { isDeleteProviderAllowed } from "common/enums/RolesEnum";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface FormValues {
  provider: ProviderMetadataType;
}

interface IProps {
  modalOpen: boolean;
  setModalStateCallback: (val: string, errorMessage?: string) => void;
  selectedPatientId: string | undefined;
}

const AssignProviderPatientModal = ({
  modalOpen,
  setModalStateCallback,
  selectedPatientId
}: IProps) => {
  const { currentRole } = useSelector((state: RootState) => state.auth);

  const { data: member, error: patientError } = useGetMemberWithUsernameQuery(
    {
      username: selectedPatientId,
      linked_entities: [MemberLinkedEntitiesEnum.PROVIDER]
    },
    { skip: selectedPatientId === undefined }
  );

  const athenaId = member?.patient?.external_accounts?.athena;
  const {
    data: athenaPatient,
    isSuccess,
    isLoading,
    error: athenaPatientError
  } = useGetAthenaMemberDetailsQuery(athenaId, {
    skip: athenaId === undefined
  });

  const { data: providersMetadata, error: providersMetadataError } =
    useGetProvidersMetadataQuery(
      {
        state: athenaPatient?.state,
        linked_entities: [MemberLinkedEntitiesEnum.PROVIDER]
      },
      {
        skip: athenaPatient === undefined
      }
    );

  const sortedProviders = useMemo(() => {
    if (providersMetadata === undefined) return;
    return [...providersMetadata].sort((a, b) => {
      return a?.provider?.last?.localeCompare(b?.provider?.last);
    });
  }, [providersMetadata]);

  const [
    updatePatientCarers,
    {
      isSuccess: updateProviderSuccess,
      isLoading: updateProviderLoading,
      error: updateProviderError
    }
  ] = useUpdateMemberCarersMutation();

  const [
    deleteCarerPatientProviderMutation,
    {
      isSuccess: deleteProviderSuccess,
      isLoading: deleteProviderLoading,
      error: deleteCarerPatientProviderError
    }
  ] = useDeleteCarerMemberProviderMutation();

  useEffect(() => {
    if (updateProviderSuccess || deleteProviderSuccess) {
      handleModalClose();
    }
  }, [updateProviderSuccess, deleteProviderSuccess]);

  const validate = (values: FormValues) => {
    const errors = {};

    if (!values.provider) {
      errors["provider"] = "Required";
    }

    return errors;
  };

  const onSubmit = async (values: FormValues) => {
    await updatePatientCarers({
      patient_id: selectedPatientId,
      carers: {
        provider_id: values?.provider.provider.user_id
      }
    });
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      provider: null
    },
    validate,
    onSubmit
  });

  const handleModalClose = () => {
    formik.resetForm();
    setModalStateCallback("close");
  };

  const selectedProvider = formik.values.provider;

  const error =
    patientError ||
    athenaPatientError ||
    providersMetadataError ||
    updateProviderError ||
    deleteCarerPatientProviderError;

  const assignedProvider = member?.patient_provider;

  return (
    <StyledModal
      isOpen={modalOpen}
      contentLabel="Assign Provider to Patient Modal"
      modalHeight="70vh"
      onRequestClose={handleModalClose}
    >
      <Form onSubmit={formik.handleSubmit}>
        <ModalHeader onRequestClose={handleModalClose}>
          Assign Provider to {getNameOrUsername(member?.patient)}
        </ModalHeader>
        <ModalBody>
          {member && athenaId === undefined && (
            <ErrorComponent error="Error: no Athena ID found." />
          )}

          {isLoading && <LoadingFallback count={5} />}
          {isSuccess && sortedProviders?.length === 0 && (
            <ErrorText>
              Error: no providers found in member's state of residence.
            </ErrorText>
          )}
          {isSuccess && sortedProviders?.length > 0 && (
            <TextField
              value={
                selectedProvider !== null
                  ? selectedProvider.provider.user_id
                  : ""
              }
              label="Provider"
              select
              fullWidth
              onChange={(event) => {
                formik.setFieldValue(
                  "provider",
                  sortedProviders.find(
                    (provider) =>
                      provider.provider.user_id === event.target.value
                  )
                );
              }}
            >
              {sortedProviders?.map((provider) => (
                <MenuItem
                  key={provider.provider.user_id}
                  value={provider.provider.user_id}
                >
                  {getNameOrUsername(provider?.provider)}
                </MenuItem>
              ))}
            </TextField>
          )}

          <br />
          {error && <ErrorComponent error={error} />}
        </ModalBody>
        <ModalFooter>
          <ModalFooterButtons>
            {isDeleteProviderAllowed(currentRole) && (
              <DestructiveButton
                loading={deleteProviderLoading}
                onClick={() =>
                  deleteCarerPatientProviderMutation({
                    patient_id: selectedPatientId
                  })
                }
                disabled={assignedProvider === undefined}
              >
                Unassign
              </DestructiveButton>
            )}
            <TurqoiseButton
              loading={updateProviderLoading}
              disabled={!formik.dirty || !formik.isValid}
              type="submit"
            >
              Assign
            </TurqoiseButton>
            <WhiteButton onClick={handleModalClose}>Cancel</WhiteButton>
          </ModalFooterButtons>
        </ModalFooter>
      </Form>
    </StyledModal>
  );
};

export { AssignProviderPatientModal };
