import { TextField } from "@mui/material";
import { isFalsy } from "common/helpers/helpers";

interface IProps {
  placeholder?: string;
  name?: string;
  id?: string;
  "data-testid"?: string;
  min?: number;
  max?: number;
  label?: string;
  value: string | number;
  onValueChange: (n: number) => void;
  sx?: any;
  error?: any;
}

const NumberInput = ({
  min,
  max,
  onValueChange,
  value,
  error,
  ...props
}: IProps) => {
  return (
    <TextField
      type="text"
      inputMode="numeric"
      sx={{ width: "105px" }}
      // This converts value from number to string, because MUI is ignoring the number,
      // and therefore we can set multiple leading zero's on the textfield.
      value={value === undefined ? "" : value + ""}
      error={!isFalsy(error)}
      helperText={error}
      {...props}
      onChange={(e) => {
        const value = e.target.value.replace(/\D/g, "");

        if (value === "") {
          onValueChange(undefined);
          return;
        }
        const number = +value;
        if (
          (min !== undefined && number < min) ||
          (max !== undefined && number > max)
        ) {
          // do nothing
          return;
        }

        onValueChange(number);
      }}
    />
  );
};

export default NumberInput;
