import {
  ALL_MEMBERS,
  ASSIGNED_MEMBERS,
  AWAITING_PROVIDER,
  ORDER_DETAILS,
  MEMBERS_WITH_NO_ACTIVITY,
  REPORT_DETAILS,
  REPORT_DETAILS_BY_MEMBER,
  REPORT_LIST,
  MEMBER_CHART,
  MEMBER_CHART_CALENDARING,
  COMPLETED_TODOS,
  STAFF_DETAILS,
  NURSE_SCHEDULE,
  NURSE_SCHEDULES,
  MY_TODOS,
  VISITS,
  SCHEDULE_TODO
} from "../RouteComponents";
import {
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_DASHBOARD,
  SIDEBAR_MY_MEMBERS,
  SIDEBAR_ORDERS,
  SIDEBAR_REPORTS,
  SIDEBAR_SUPPORT,
  SIDEBAR_SECTIONS
} from "../RouteSidebar";
import RolesEnum from "common/enums/RolesEnum";
import { PersonIcon } from "../../assets/images/icons";
import EnvVars from "common/config/EnvVars";

const TH_NURSE = {
  label: "Telehealth Nurse",
  routes: [
    {
      path: "/",
      components: [
        SCHEDULE_TODO(EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV !== "prod"),
        ASSIGNED_MEMBERS({
          promiseFunctionNames: ["getOrdersUsingPatientId"],
          tableColumns: [
            {
              name: "name",
              accessor: "patient.fullname",
              size: 180
            },
            { name: "status" },
            { name: "phone" },
            { name: "birthdate", size: 130 }
            /*{ name: "carerPatientTableDelivered", size: 120 },*/
          ],
          componentHeader: "My Members"
        })
      ]
    },
    NURSE_SCHEDULES,
    NURSE_SCHEDULE,
    {
      path: "/members",
      components: [ALL_MEMBERS({})]
    },
    STAFF_DETAILS,
    MY_TODOS,
    COMPLETED_TODOS,
    {
      path: "/awaiting-provider",
      components: [
        AWAITING_PROVIDER({
          tableColumns: [
            { name: "name" },
            { name: "phone" },
            { name: "birthdate" },
            { name: "nurseAssigned" }
          ]
        })
      ]
    },
    ...MEMBER_CHART(RolesEnum.TH_NURSE),
    ...MEMBER_CHART_CALENDARING(),
    {
      // my patients route
      path: "/members/nurseId/:nurseId",
      components: [
        MEMBERS_WITH_NO_ACTIVITY({}),
        ASSIGNED_MEMBERS({
          recentOrdersSinceDays: 15,
          promiseFunctionNames: [
            "getEncountersData",
            "getPeriodReadings",
            "getOrdersUsingPatientId",
            "getReadingsByPatientId"
          ]
        })
      ]
    },
    {
      path: "/orders",
      components: [
        {
          type: "order-devices"
        }
      ]
    },
    {
      path: "/devices/:memberId",
      components: [
        {
          type: "order-devices"
        }
      ]
    },
    ORDER_DETAILS(),
    {
      path: "/my-profile",
      components: [
        {
          type: "profile"
        }
      ]
    },
    {
      path: "/help",
      components: [
        {
          type: "help"
        }
      ]
    },
    REPORT_LIST,
    REPORT_DETAILS,
    REPORT_DETAILS_BY_MEMBER,
    VISITS
  ],
  sidebar: [
    SIDEBAR_DASHBOARD,
    {
      sectionName: SIDEBAR_SECTIONS.members,
      items: [SIDEBAR_MY_MEMBERS, SIDEBAR_ALL_MEMBERS, SIDEBAR_ORDERS]
    },
    {
      sectionName: SIDEBAR_SECTIONS.nurses,
      items: [
        {
          itemName: "Nurse Schedules",
          itemLink: "/nurse-schedules",
          ItemIcon: PersonIcon
        }
      ]
    },
    SIDEBAR_REPORTS,
    SIDEBAR_SUPPORT
  ]
};

export default TH_NURSE;
