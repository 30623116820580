import { Checkbox, FormControlLabel, styled, Typography } from "@mui/material";
import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";
import { gray } from "../../../styling/colors";

interface IProps {
  field: CareFlowFieldType;
}

const MessageIOSTypography = styled(Typography)`
  text-align: end;
  color: white;
`;

const MessageIOSContainer = styled("div")`
  display: flex;

  align-self: end;
  background-color: #007aff;
  border-radius: 10px;
  padding: 10px;
  max-width: 60%;
`;

const CHECKBOX_TEXT = "I have informed the member of call recording";

const VisitFieldQuote = ({ field }: IProps) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="body1" color={gray[900]}>
        Inform the member of call recording
      </Typography>
      <br />

      <MessageIOSContainer>
        <MessageIOSTypography variant="body1">
          {field.value}
        </MessageIOSTypography>
      </MessageIOSContainer>

      <br />
      <FormControlLabel
        key={CHECKBOX_TEXT}
        value={CHECKBOX_TEXT}
        control={<Checkbox />}
        label={CHECKBOX_TEXT}
      />
    </div>
  );
};

export default VisitFieldQuote;
