import { useMemo } from "react";
import {
  ComponentHeader,
  ErrorText,
  TableComponentContainer
} from "../../styling/StyleComponents";
import { useGetTeamsQuery } from "common/services/TeamsService";
import TeamDetails from "./TeamDetails";
import { TableProps } from "../../components/Table/TableTypes";
import ErrorComponent from "../../components/ErrorComponent";
import { useGetLeaderSummaryByLeaderIdQuery } from "common/services/ReportsService";
import { checkIdValid, getNameOrUsername } from "common/helpers/helpers";
import { Typography } from "@mui/material";
import useSanitizedParams from "../../hooks/useSanitizedParams";

const SelectedTeam = ({
  externalLink,
  tableColumns = [
    { name: "teamMemberName" },
    { name: "patientsAssigned" },
    // TBD - fix this MTD time entered field on the backend before commenting this back in
    // { name: "myTeamTimeEntered", size: 200 },
    { name: "patientCount", size: 150 },
    { name: "deleteTeamMember" }
  ]
}: TableProps) => {
  const params = useSanitizedParams();

  const { userId } = params;
  const isValidId = checkIdValid(userId);

  const { data, error, isError, isSuccess } =
    useGetLeaderSummaryByLeaderIdQuery(
      {
        team_leader_id: userId
      },
      { skip: userId === undefined || isValidId === false }
    );
  const { data: teamData } = useGetTeamsQuery(
    {
      leader_id: userId
    },
    { skip: userId === undefined || isValidId === false }
  );

  const team = useMemo(() => {
    if (teamData === undefined || teamData.length === 0 || data === undefined)
      return undefined;

    return {
      ...teamData[0],
      team_members: [...data].sort((a, b) =>
        getNameOrUsername(a.carer).localeCompare(getNameOrUsername(b.carer))
      )
    };
  }, [teamData, data]);

  if (!isValidId)
    return (
      <Typography variant="body1">{`Invalid User ID ${userId}`}</Typography>
    );

  return (
    <TableComponentContainer>
      <ComponentHeader>Team Report</ComponentHeader>

      {isError && <ErrorComponent error={error} />}
      {isSuccess && team === undefined && <ErrorText>No team found</ErrorText>}

      {team && data && (
        <TeamDetails
          params={{
            team: team,
            outerContainerStyles: { showBorder: false }
          }}
          externalLink={externalLink}
          tableColumns={tableColumns}
        />
      )}
    </TableComponentContainer>
  );
};

export default SelectedTeam;
