import { useEffect, useMemo, useState } from "react";
import {
  Tab,
  Tabs,
  Button,
  Typography,
  styled,
  InputAdornment,
  Box,
  MenuItem,
  TextField
} from "@mui/material";

import { useSelector } from "react-redux";

import LoadingFallback from "common/helpers/components/LoadingFallback";
import { RootState } from "common/redux";

import RolesEnum from "common/enums/RolesEnum";

import ErrorComponent from "../../../components/ErrorComponent";

import ComponentLoader from "../../../routes/ComponentLoader";
import { NURSE_LIST } from "../../../routes/RouteComponents";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import useGetAuthenticatedUser from "common/hooks/useGetAuthenticatedUser";

import { Flexbox } from "../../../styling/NewStyleComponents";

import { useGetManagerNursePtoQuery } from "common/services/CalendarService";
import { FilterAltOutlined, LoopOutlined, Search } from "@mui/icons-material";
import Table from "../../../components/Table/Table";
import { firstLastUsernameSearch } from "../../../helpers/helpers";
import { checkIdValid } from "common/helpers/helpers";
import { useGetTeamsQuery } from "common/services/TeamsService";
import { TeamTypeEnum } from "common/enums/TeamTypeEnum";
import DebouncedInput from "../../../components/Input/DebouncedInput";
import AddTimeOffModal from "./AddTimeOffModal";
import { TurqoiseButton } from "../../../styling";
import { useGetSortedMembersWithActiveNursesQuery } from "common/services/ReportsService";
import UserStatusEnum from "common/enums/UserStatusEnum";

const Container = styled("div")`
  margin: 20px 2.5%;
  overflow: scroll;
`;

const StyledTabs = styled(Tabs)`
  margin: 0px;
`;

export const StyledInput = styled(DebouncedInput)`
  background: white;
  width: auto;
`;

const Row = styled("div")`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin: 10px;
`;

enum TabPanelIndex {
  nurse_schedules = 0,
  same_day_time_off = 1
}

const NurseSchedulesRender = ({
  currentRole,
  tabIndex,
  setTabIndex,
  teamsData,
  nurses
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { tab } = useParams();

  const [selectedTeamLeaderId, setSelectedTeamLeaderId] = useState<string>("");

  const {
    data: managerNursePtoData,
    isFetching: isManagerNursePtoFetching,
    error: managerNursePtoError
  } = useGetManagerNursePtoQuery(
    {
      manager_id: selectedTeamLeaderId
    },
    {
      skip: tab !== "same_day_time_off" || !checkIdValid(selectedTeamLeaderId)
    }
  );

  useEffect(() => {
    switch (tab) {
      case "schedules":
        setTabIndex(TabPanelIndex.nurse_schedules);
        break;
      case "same_day_time_off":
        setTabIndex(TabPanelIndex.same_day_time_off);
        break;
    }
  }, [currentRole, tab]);

  const pathname = useMemo(() => {
    const paths = location.pathname.split("/");
    if (paths?.length === 3) paths.pop();

    return paths.join("/");
  }, [location]);

  const [addTimeOffModalOpen, setAddTimeOffModalOpen] =
    useState<boolean>(false);

  const [searchText, setSearchText] = useState<string>("");
  const handleText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newText = event.target.value;
    setSearchText(newText);
  };

  const resetDisabled = useMemo(() => {
    return selectedTeamLeaderId?.length === 0 && searchText === "";
  }, [selectedTeamLeaderId, searchText]);

  const handleReset = () => {
    setSelectedTeamLeaderId("");
    setSearchText("");
  };

  useEffect(() => {
    if (tab === "nurse_schedules") {
      // set the tab to the first tab the user can view
      setTabIndex(TabPanelIndex.nurse_schedules);
      navigate("/nurses-schedules/schedules");
    } else if (tab === "same_day_time_off") {
      setTabIndex(TabPanelIndex.same_day_time_off);
      navigate("/nurses-schedules/same_day_time_off");
    }
  }, [pathname, tab, tabIndex, currentRole]);

  const filteredManagerNursePtoData = useMemo(() => {
    if (managerNursePtoError) return null;
    let data = managerNursePtoData;
    if (searchText?.length > 0) {
      data = firstLastUsernameSearch(
        searchText,
        managerNursePtoData,
        "staff.fullname",
        "staff.fullname"
      );
    }

    return data;
  }, [
    managerNursePtoData,
    selectedTeamLeaderId,
    searchText,
    managerNursePtoError
  ]);

  return (
    <>
      <Container>
        <Typography variant="h3" color="black" paddingLeft="16px">
          Nurses Schedules
        </Typography>

        <br />
        <Flexbox justifyContent="space-between" alignItems="center">
          <StyledTabs
            value={tabIndex}
            aria-label="Member Details Profile Tabs"
            style={{}}
          >
            <Tab
              label={"Schedules"}
              style={{ fontWeight: "600" }}
              onClick={() => navigate("/nurses-schedules/schedules")}
              value={TabPanelIndex.nurse_schedules}
            />

            <Tab
              label={"Same Day Time Off"}
              style={{ fontWeight: "600" }}
              onClick={() => navigate("/nurses-schedules/same_day_time_off")}
              value={TabPanelIndex.same_day_time_off}
            />
          </StyledTabs>
          <TurqoiseButton
            sx={{ width: "auto" }}
            onClick={() => setAddTimeOffModalOpen(true)}
          >
            Add Time Off
          </TurqoiseButton>
        </Flexbox>
      </Container>

      {tabIndex === TabPanelIndex.nurse_schedules && (
        <>
          {[
            NURSE_LIST({
              componentHeader: "",
              roles: [RolesEnum.TH_NURSE],
              externalLink: "/staff/staffId/:nurseId/schedule",
              tableColumns: [
                {
                  name: "fullname",
                  accessor: "user.fullname",
                  filterEnabled: true,
                  filterFn: "fuzzy",
                  size: 300
                }
              ]
            })
          ].map((component) => (
            <ComponentLoader key={`${component.type}`} component={component} />
          ))}
        </>
      )}

      {tabIndex === TabPanelIndex.same_day_time_off && (
        <>
          <Flexbox
            justifyContent="space-between"
            alignItems="center"
            margin="0 20px"
          >
            <Box>
              <Row>
                <FilterAltOutlined color="primary" />
                <Typography variant="h6" color="primary">
                  Filter by
                </Typography>

                {teamsData && (
                  <TextField
                    value={selectedTeamLeaderId}
                    select
                    label={"Team"}
                    sx={{ width: 200, backgroundColor: "white" }}
                    id={"Team"}
                    defaultValue={""}
                    SelectProps={{
                      variant: "outlined",
                      value: selectedTeamLeaderId,
                      onChange: (event) => {
                        // we have an error with test data where multiple teams have the same leader
                        setSelectedTeamLeaderId(event.target.value as string);
                      }
                    }}
                  >
                    {teamsData.map((value) => (
                      <MenuItem
                        key={value.leader_id + value.name}
                        value={value.leader_id}
                      >
                        {value.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}

                <Button
                  startIcon={<LoopOutlined />}
                  onClick={handleReset}
                  disabled={resetDisabled}
                >
                  Reset
                </Button>
              </Row>
            </Box>

            <Box>
              <StyledInput
                sx={{ width: "auto" }}
                debounceTimeout={300}
                value={searchText}
                onChange={handleText}
                type="text"
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ backgroundColor: "transparent" }}
                    >
                      <Search />
                    </InputAdornment>
                  )
                }}
              />
            </Box>
          </Flexbox>

          <Box sx={{ margin: "0 18px 0 32px" }}>
            {isManagerNursePtoFetching && <LoadingFallback />}
            {managerNursePtoError && !isManagerNursePtoFetching && (
              <ErrorComponent
                error={managerNursePtoError}
                showErrorResponseMessage
                hideErrorCode
              />
            )}
            {filteredManagerNursePtoData?.length >= 0 &&
              !isManagerNursePtoFetching && (
                <Table
                  tableColumns={[
                    {
                      name: "ptoNurseName"
                    },
                    {
                      name: "ptoNurseDuration"
                    },
                    {
                      name: "ptoNurseToReassign"
                    }
                  ]}
                  tableProps={{
                    navigate
                  }}
                  noDataText="No data found."
                  data={filteredManagerNursePtoData}
                />
              )}
          </Box>
        </>
      )}

      <br />

      <AddTimeOffModal
        isVisible={addTimeOffModalOpen}
        onRequestClose={() => setAddTimeOffModalOpen(false)}
        nurses={nurses}
        managerId={selectedTeamLeaderId}
      />
    </>
  );
};

const NurseSchedules = () => {
  const { tab } = useParams();

  const { currentRole, user } = useSelector((state: RootState) => state.auth);

  const {
    data: teamsData,
    isFetching: isFetchingTeams,
    error: teamsError
  } = useGetTeamsQuery({ types: [TeamTypeEnum.TH_NURSES] });

  const {
    data: nurses,
    error: nursesError,
    isFetching: isFetchingNurses
  } = useGetSortedMembersWithActiveNursesQuery(
    {
      carer_roles: [RolesEnum.TH_NURSE, RolesEnum.THN_MANAGER],
      carer_status: [UserStatusEnum.ACTIVE],
      getNurseListForAppointments: true,
      is_excluded_from_scheduling: false
    },
    {}
  );

  const { data: authenticatedUser } = useGetAuthenticatedUser();

  const [tabIndex, setTabIndex] = useState<number | string>(
    TabPanelIndex[tab] ?? 0
  );

  const isFetching = isFetchingNurses || isFetchingTeams;

  return (
    <>
      {user && currentRole && authenticatedUser ? (
        <NurseSchedulesRender
          teamsData={teamsData}
          currentRole={currentRole}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          nurses={nurses}
        />
      ) : (
        <>
          {isFetching && <LoadingFallback />}
          {teamsError && <ErrorComponent error={teamsError} />}
          {nursesError && <ErrorComponent error={nursesError} />}
        </>
      )}
    </>
  );
};

export default NurseSchedules;
