import { RootState } from "common/redux";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import config from "../routes";

const useRouteConfigByRole = () => {
  const { currentRole } = useSelector((state: RootState) => state.auth);

  const permissions = config.permission;

  const roleConfig = useMemo(() => {
    if (currentRole === null) return undefined;

    const roleConfig = permissions[currentRole];
    return { ...roleConfig, role: currentRole };
  }, [permissions, currentRole]);

  return roleConfig;
};

export default useRouteConfigByRole;
