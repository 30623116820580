import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography, styled } from "@mui/material";

import LoadingFallback from "common/helpers/components/LoadingFallback";

import ErrorComponent from "../../../components/ErrorComponent";

import { useNavigate, useParams } from "react-router-dom";

import { Flexbox } from "../../../styling/NewStyleComponents";

import {
  useGetCalendarEventsQuery,
  useReassignCalendarEventMutation
} from "common/services/CalendarService";
import Table from "../../../components/Table/Table";
import {
  checkIdValid,
  getNameOrUsername,
  isFalsy
} from "common/helpers/helpers";
import DebouncedInput from "../../../components/Input/DebouncedInput";
import { useGetUserWithUsernameQuery } from "common/services/UserService";
import { Spinner, TurqoiseButton } from "../../../styling";
import { DateTime } from "luxon";
import AppointmentTypeEnum from "common/enums/Calendaring/Appointments/AppointmentTypeEnum";
import { useGetStaffAvailabilityRescheduleEventQuery } from "common/services/PanelManagementService";
import { StyledModal } from "../../../styling/StyleModal";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import { useAppDispatch } from "common/redux";

const Container = styled("div")`
  margin: 20px 2.5%;
  overflow: scroll;
`;

export const StyledInput = styled(DebouncedInput)`
  background: white;
  width: auto;
`;

const Row = styled("div")`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin: 10px;
`;

function ReassignEventAutomatically({
  staffId,
  startDate,
  endDate,
  oldStaffId,
  eventId,
  resetState,
  rescheduleEventData
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const previousNurseName = getNameOrUsername(
    rescheduleEventData?.original_event?.staff,
    false
  );
  const nextNurseName =
    getNameOrUsername(rescheduleEventData?.updated_event?.staff, false) ??
    "Name not found";

  const previousTimeStart = DateTime.fromISO(
    rescheduleEventData?.original_event?.start_time
  );

  const previousTimeEnd = DateTime.fromISO(
    rescheduleEventData?.original_event?.end_time
  );

  const nextTimeStart = DateTime.fromISO(
    rescheduleEventData?.updated_event?.start_time
  );

  const nextTimeEnd = DateTime.fromISO(
    rescheduleEventData?.updated_event?.end_time
  );

  const previousTimeText = `${previousTimeStart.toFormat("ccc, LLL d hh:mm a")} - ${previousTimeEnd.toFormat("hh:mm a ZZZZ")}`;
  const nextTimeText = `${nextTimeStart.toFormat("ccc, LLL d hh:mm a")} - ${nextTimeEnd.toFormat("hh:mm a ZZZZ")}`;

  const [
    reassignCalendarEvent,
    { data: reassignCalendarEventData, error: reassignCalendarEventError }
  ] = useReassignCalendarEventMutation();

  useEffect(() => {
    if (reassignCalendarEventError) {
      Alert_show({
        dispatch,
        id: "reassignCalendarEventError",
        title: "Error",
        content: <ErrorComponent error={"There was an unexpected error."} />,
        type: "error",
        size: "small",
        hideCloseIcon: true,
        buttons: [
          {
            text: "Reassign Manually",
            style: "default",
            onPress: () => {
              Alert_close({ dispatch, id: "reassignCalendarEventError" });
              resetState();
              navigate(`/nurses-schedules/manual-reassignment/${eventId}`);
            }
          },
          {
            text: "Cancel",
            style: "cancel",
            onPress: () => {
              Alert_close({ dispatch, id: "reassignCalendarEventError" });
              resetState();
            }
          }
        ]
      });
    }
  }, [reassignCalendarEventError]);

  useEffect(() => {
    if (staffId && startDate && endDate && eventId) {
      Alert_show({
        dispatch,
        id: "reassignResults",
        title: "Reassign Results",
        hideCloseIcon: true,
        content: (
          <div>
            <div>
              The system found an available target time for the appointment,
              please review and confirm.
            </div>
            <div
              style={{
                borderRadius: "8px",
                height: "64px",
                border: "1px solid #d0d5dd",
                margin: "8px 0"
              }}
            >
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  alignItems: "center"
                }}
              >
                <div style={{ margin: "8px", width: "50px" }}>From:</div>
                <div>
                  <b>
                    {previousNurseName}
                    <br />
                    {previousTimeText}
                  </b>
                </div>
              </div>
            </div>
            <div
              style={{
                borderRadius: "8px",
                height: "64px",
                border: "1px solid #d0d5dd",
                margin: "8px 0"
              }}
            >
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  alignItems: "center"
                }}
              >
                <div style={{ margin: "8px", width: "50px" }}>To:</div>
                <div>
                  <b>
                    {nextNurseName}
                    <br />
                    {nextTimeText}
                  </b>
                </div>
              </div>
            </div>
          </div>
        ),
        type: "default",
        size: { width: "640px", height: "500px" },
        buttons: [
          {
            text: "Confirm",
            style: "default",
            onPress: () => {
              reassignCalendarEvent({
                staffId,
                oldStaffId,
                eventId,
                body: {
                  staff_id: staffId,
                  start_date: startDate,
                  end_date: endDate
                }
              });
              Alert_close({ dispatch, id: "reassignResults" });
            }
          },
          {
            text: "Select Manually",
            style: "cancel",
            onPress: () => {
              resetState();
              Alert_close({ dispatch, id: "reassignResults" });
              navigate(`/nurses-schedules/manual-reassignment/${eventId}`);
            }
          }
        ]
      });
    }
  }, [staffId, startDate, endDate, eventId]);

  useEffect(() => {
    if (reassignCalendarEventData) {
      Alert_show({
        dispatch,
        hideCloseIcon: true,
        id: "reassignCalendarEventSuccess",
        title: "Success",
        content: "The appointment has been reassigned successfully",
        type: "default",
        size: "small",
        buttons: [
          {
            text: "Close",
            style: "default",
            onPress: () => {
              Alert_close({ dispatch, id: "reassignCalendarEventSuccess" });
              resetState();
            }
          }
        ]
      });
    }
  }, [reassignCalendarEventData]);

  return <></>;
}

function GetRescheduleEventData({
  selectedEventId,
  setLoadingModalOpen,
  oldStaffId,
  resetRoot
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [staffId, setStaffId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const resetState = useCallback(() => {
    console.log("resetting state");
    resetRoot();
    setTimeout(() => {
      setStaffId("");
      setStartDate("");
      setEndDate("");
    }, 50);
  }, [setLoadingModalOpen]);

  const { data: rescheduleEventData, error: rescheduleEventError } =
    useGetStaffAvailabilityRescheduleEventQuery(
      { event_id: selectedEventId },
      { skip: !selectedEventId }
    );

  useEffect(() => {
    if (rescheduleEventError) {
      setLoadingModalOpen(false);
      Alert_show({
        dispatch,
        id: "rescheduleEventError",
        title: "Reassign Failed",
        content: <ErrorComponent error={"There was an unexpected error."} />,
        type: "error",
        size: "small",
        hideCloseIcon: true,
        buttons: [
          {
            text: "Reassign Manually",
            style: "default",
            onPress: () => {
              setLoadingModalOpen(false);
              Alert_close({ dispatch, id: "rescheduleEventError" });
              navigate(
                `/nurses-schedules/manual-reassignment/${selectedEventId}`
              );
            }
          },
          {
            text: "Cancel",
            style: "cancel",
            onPress: () => {
              setLoadingModalOpen(false);
              Alert_close({ dispatch, id: "rescheduleEventError" });
            }
          }
        ]
      });
    }
  }, [rescheduleEventError]);

  useEffect(() => {
    if (rescheduleEventData) {
      setStaffId(rescheduleEventData?.updated_event?.staff?.staff_id);
      setStartDate(rescheduleEventData?.updated_event?.start_time);
      setEndDate(rescheduleEventData?.updated_event?.end_time);
    }
  });

  return (
    <>
      {selectedEventId && rescheduleEventData && (
        <ReassignEventAutomatically
          staffId={staffId}
          startDate={startDate}
          endDate={endDate}
          oldStaffId={oldStaffId}
          eventId={selectedEventId}
          resetState={resetState}
          rescheduleEventData={rescheduleEventData}
        />
      )}
    </>
  );
}

const SameDayTimeOffRender = ({
  nurseName,
  today,
  calendarEventsData,
  oldStaffId
}) => {
  const navigate = useNavigate();
  const [loadingModalOpen, setLoadingModalOpen] = useState<boolean>(false);
  const [selectedEventId, setSelectedEventId] = useState("");
  const resetRoot = useCallback(() => {
    setSelectedEventId("");
    setLoadingModalOpen(false);
  }, []);

  const filteredCalendarEventsData = useMemo(() => {
    return calendarEventsData?.filter((item) => {
      const isMemberAppointment =
        item?.appointment_type === AppointmentTypeEnum.TELEHEALTH_NURSE_SETUP ||
        item?.appointment_type === AppointmentTypeEnum.NURSE_FOLLOWUP ||
        item?.appointment_type === AppointmentTypeEnum.PROVIDER_FOLLOWUP;
      return isMemberAppointment;
    });
  }, [calendarEventsData]);

  useEffect(() => {
    if (selectedEventId) {
      setLoadingModalOpen(true);
    }
  }, [selectedEventId]);

  return (
    <>
      <Container>
        <Typography variant="h3" color="black">
          {nurseName}'s Appointments / {today}
        </Typography>

        <br />
        <Flexbox justifyContent="space-between" alignItems="center">
          <Typography>
            Reassigning this nurse's appointments scheduled for today due to
            unplanned time off.
          </Typography>
          <TurqoiseButton sx={{ width: "auto" }} onClick={() => navigate(-1)}>
            Back
          </TurqoiseButton>
        </Flexbox>
      </Container>

      <Box margin="0 20px">
        {filteredCalendarEventsData?.length >= 0 && (
          <Table
            tableColumns={[
              {
                name: "calendarVisitsStartEnd",
                header: "Appointment Time",
                size: 80
              },
              {
                name: "appointmentMemberAttendee"
              },
              {
                name: "ptoReassign",
                size: 40
              }
            ]}
            tableProps={{
              setSelectedEventId
            }}
            noDataText="No data found."
            data={filteredCalendarEventsData}
          />
        )}
      </Box>

      <br />

      {selectedEventId && (
        <GetRescheduleEventData
          selectedEventId={selectedEventId}
          setLoadingModalOpen={setLoadingModalOpen}
          oldStaffId={oldStaffId}
          resetRoot={resetRoot}
        />
      )}

      <StyledModal
        isOpen={loadingModalOpen}
        modalHeight="72px"
        modalWidth="180px"
        minWidth="180px"
        contentLabel="Working"
      >
        <Flexbox height="100%" justifyContent="center">
          <Flexbox
            position="relative"
            width="60px"
            height="100%"
            alignItems="center"
          >
            <Spinner loading="true" />
          </Flexbox>
          <Flexbox height="100%" alignItems="center">
            Working
          </Flexbox>
        </Flexbox>
      </StyledModal>
    </>
  );
};

const SameDayTimeOff = () => {
  const { nurseId } = useParams();
  const invalidUserId = !isFalsy(nurseId) && !checkIdValid(nurseId);

  const {
    data: nurseData,
    isFetching: isNurseDataFetching,
    error: nurseDataError
  } = useGetUserWithUsernameQuery(
    {
      username: nurseId
    },
    { skip: invalidUserId }
  );

  const now = useMemo(() => DateTime.now(), []);
  const start = useMemo(() => now.startOf("day"), [now]);
  const end = useMemo(() => now.endOf("day"), [now]);
  const today = useMemo(() => now.toFormat("cccc, LLL d"), [now]);

  const {
    data: calendarEventsData,
    isFetching: calendarEventsisFetching,
    error: calendarEventsError
  } = useGetCalendarEventsQuery(
    {
      staff_id: nurseId,
      startdate: start,
      enddate: end
    },
    {
      skip: invalidUserId
    }
  );

  const isFetching = isNurseDataFetching || calendarEventsisFetching;

  return (
    <Container>
      {invalidUserId && (
        <Typography
          margin="20px"
          variant="body1"
          color="error"
        >{`Error: Invalid User ID ${nurseId}`}</Typography>
      )}
      {!invalidUserId && calendarEventsData && nurseData ? (
        <SameDayTimeOffRender
          nurseName={getNameOrUsername(nurseData?.user, false)}
          today={today}
          calendarEventsData={calendarEventsData}
          oldStaffId={nurseId}
        />
      ) : (
        <>
          {isFetching && <LoadingFallback />}
          {nurseDataError && <ErrorComponent error={nurseDataError} />}
          {calendarEventsError && (
            <ErrorComponent error={calendarEventsError} />
          )}
        </>
      )}
    </Container>
  );
};

export default SameDayTimeOff;
