enum ReduxTagEnum {
  User = "User",
  Member = "Member",
  Encounter = "Encounter",
  Team = "Team",
  Order = "Order",
  CarerMemberType = "CarerMemberType",
  Report = "Report",
  ProviderMetadata = "ProviderMetadata",
  Survey = "Survey",
  Device = "Device",
  Consents = "Consents",
  LicensedStates = "LicensedStates",
  Video = "Video",
  Tasks = "Tasks",
  Appointments = "Appointments",
  Messaging = "Messaging",
  Calendar = "Calendar",
  ManagerPto = "ManagerPto",
  Availability = "Availability",
  MemberEngagement = "Member Engagement",
  Visits = "Visits",
  EncounterVisits = "EncounterVisits"
}

export default ReduxTagEnum;
