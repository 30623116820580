import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useGetCalendarEventsQuery } from "common/services/CalendarService";
import { useGetVisitsQuery } from "common/services/VisitsService";

const useGetCalendarVisits = ({
  staff_id,
  patient_id,
  startdate,
  enddate,
  sort = "asc",
  skip = false
}: {
  staff_id?: string;
  patient_id?: string;
  startdate: DateTime;
  enddate: DateTime;
  sort?: "asc" | "desc";
  skip?: boolean;
}) => {
  const {
    data: calendarEventsData,
    isUninitialized: calendarEventsIsUninitialized,
    isSuccess: calendarEventsIsSuccess,
    isFetching: calendarEventsisFetching,
    error: calendarEventsError,
    refetch: refetchCalendarEvents
  } = useGetCalendarEventsQuery(
    {
      staff_id: staff_id,
      patient_id: patient_id,
      startdate: startdate,
      enddate: enddate
    },
    {
      // TBD remove when switching to appointments endpoint
      // this skip condition is used to prevent the query from running if the staff_id is not valid
      // we won't need this file anymore when the backend is updated to get staff schedules using the appointments endpoint
      // skip: isFalsy(staff_id) || !checkIdValid(staff_id) ||
      skip
    }
  );

  const {
    data: visitsData,
    isUninitialized: visitsDataIsUninitialized,
    isSuccess: visitsDataIsSuccess,
    isFetching: visitsDataisFetching,
    error: visitsError,
    refetch: refetchVisits
  } = useGetVisitsQuery(
    {
      staff_id: staff_id,
      patient_id: patient_id,
      calendar_event_start_after: startdate,
      calendar_event_end_before: enddate
    },
    {
      // TBD remove when switching to appointments endpoint
      // this skip condition is used to prevent the query from running if the staff_id is not valid
      // we won't need this file anymore when the backend is updated to get staff schedules using the appointments endpoint
      // skip: isFalsy(staff_id) || !checkIdValid(staff_id) ||
      skip
    }
  );

  const [finalData, setFinalData] = useState<any[]>();
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (visitsData && calendarEventsData) {
      setIsProcessing(true);
      const visitsMap = {};
      visitsData.forEach((visit) => {
        const calendarId = visit.calendar_id;
        if (!visitsMap[calendarId]) {
          visitsMap[calendarId] = [visit];
        } else {
          visitsMap[calendarId].push(visit);
        }
      });

      // possible tbd - check for why calendar events are missing after calling /appointments/end as an NP
      // this depends on the backend
      // if we can use the /appointments/staff endpoint, then we can delete this file and not worry about it

      let mergedData = calendarEventsData
        .map((item) => {
          const visits = visitsMap?.[item?.event_id] || [];
          return {
            ...item,
            visits
          };
        })
        .sort((a, b) => {
          if (sort === "asc") {
            return a.startdate < b.startdate ? -1 : 1;
          } else {
            return a.startdate > b.startdate ? -1 : 1;
          }
        });

      setFinalData(mergedData);
      setIsProcessing(false);
    }
  }, [visitsData, calendarEventsData]);

  useEffect(() => {
    if (calendarEventsError || visitsError) {
      setIsProcessing(false);
    }
  }, [calendarEventsError, visitsError]);

  const isFetching =
    calendarEventsisFetching || visitsDataisFetching || isProcessing;

  function refetch() {
    setIsProcessing(true);
    if (!calendarEventsIsUninitialized) {
      refetchCalendarEvents();
    }
    if (!visitsDataIsUninitialized) {
      refetchVisits();
    }

    if (calendarEventsIsUninitialized && visitsDataIsUninitialized) {
      setIsProcessing(false);
    }
  }

  return {
    refetch,
    data: finalData,
    isSuccess: calendarEventsIsSuccess && visitsDataIsSuccess,
    isFetching,
    isError: calendarEventsError || visitsError,
    error: visitsError ?? calendarEventsError
  };
};

export default useGetCalendarVisits;
