import { ChangeEvent, useMemo } from "react";
import { PHONE_NUMBER_REGEXP, maskPhoneNumber } from "common/helpers/helpers";
import { TextFieldProps } from "@mui/material";
import { StyledTextField } from "../../helpers/components/Forms/FormHelpers";

// Formik legacy, this class should not need to take care of this.
// Need to refactor.
interface IProps {
  field?: any;
  form?: any;
  disableinput?: boolean;
}

const PhoneInputField = (props: IProps & TextFieldProps) => {
  const { field, form, value, onChange } = props;

  const valueFinal = useMemo(() => {
    const val = value || field?.value;
    return maskPhoneNumber(val);
  }, [value, field?.value]);

  function onValueChange(
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    if (form?.setFieldValue !== undefined)
      form?.setFieldValue(field.name, event.target.value);

    if (onChange !== undefined) onChange(event);
  }

  return (
    <StyledTextField
      {...props}
      placeholder={
        field !== undefined ? `Enter ${field.name} number` : props.placeholder
      }
      onChange={(event) => {
        if (PHONE_NUMBER_REGEXP.test(event.target.value)) {
          onValueChange(event);
        }
      }}
      value={valueFinal}
      autoComplete="tel-national"
      inputProps={{ maxLength: 16 }}
      fullWidth
    />
  );
};

export default PhoneInputField;
