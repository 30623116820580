import { CircularProgress, Typography } from "@mui/material";
import { useGetVisitNotesQuery } from "common/services/VisitsService";
import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import { gray } from "../../../styling/colors";
import { useEffect, useState } from "react";

import { DateTime } from "luxon";
import { Column } from "../../../styling/StyleComponents";
import { useAppDispatch } from "common/redux";
import { setGeneratedNotes } from "common/redux/VisitsSlice";

interface IProps {
  field: CareFlowFieldType;
  visit: GetVisitResponseType;
}

const VisitFieldGenerateNotes = ({ field, visit }: IProps) => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useGetVisitNotesQuery({
    visit_id: visit.visit_id
  });

  const [dateTimeGenerated, setDateTimeGenerated] = useState<DateTime>();

  useEffect(() => {
    if (data) {
      setDateTimeGenerated(DateTime.now());
      dispatch(setGeneratedNotes(data.notes));
    }
  }, [data]);

  return (
    <Column>
      {isLoading && <CircularProgress />}
      {data && (
        <Typography variant="body1" color={gray[900]} whiteSpace={"pre-wrap"}>
          {data.notes}
        </Typography>
      )}
      {dateTimeGenerated && (
        <Typography alignSelf={"flex-end"} variant="body1" color={gray[900]}>
          Generated {dateTimeGenerated.toRelative()}
        </Typography>
      )}
    </Column>
  );
};

export default VisitFieldGenerateNotes;
