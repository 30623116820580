import { DateTime } from "luxon";

import { apiPAB } from "./AxiosService";

import ReduxTagEnum from "../enums/ReduxTagEnum";
import { providesList } from "./ServicesCacheUtils";
import SurveyType from "../types/SurveyType";
import RolesEnum from "../enums/RolesEnum";
import { QueryString_stringify } from "../helpers/QueryStringHelper";
import MemberSurveyType from "../types/MemberSurveyType";
import SurveyLinkedEntitiesEnum from "../enums/SurveyLinkedEntitiesEnum";

const providesTags = (result: SurveyType | undefined) => {
  return providesList<SurveyType, ReduxTagEnum.Survey>(
    [result],
    ReduxTagEnum.Survey,
    (item: SurveyType) => item?.survey?.id
  );
};

const providesTagsMemberSurvey = (result: MemberSurveyType[] | undefined) => {
  return providesList<MemberSurveyType, ReduxTagEnum.Member>(
    result,
    ReduxTagEnum.Member,
    (item: MemberSurveyType) => item.patient?.patient_id
  );
};

export const surveyService = apiPAB
  .enhanceEndpoints({
    addTagTypes: [ReduxTagEnum.Survey, ReduxTagEnum.Member]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getSurvey: builder.query<SurveyType, void>({
        query: () => {
          return {
            url: `/surveys/PATIENT_ATTRITION/versions/1`,
            method: "GET"
          };
        },
        providesTags
      }),
      getMemberSurveys: builder.query<
        MemberSurveyType[],
        {
          patient_id?: string;
          submitter_roles?: RolesEnum[];
          startDate?: DateTime;
          endDate?: DateTime;
          linked_entities?: SurveyLinkedEntitiesEnum[];
          size?: number;
        }
      >({
        query: ({
          patient_id,
          submitter_roles,
          startDate,
          endDate,
          linked_entities,
          size
        }) => {
          const queryParams = QueryString_stringify({
            patient_id,
            submitter_roles,
            since: startDate ? startDate.startOf("day").toISO() : undefined,
            until: endDate ? endDate.endOf("day").toISO() : undefined,
            survey_type: "PATIENT_ATTRITION",
            linked_entities,
            size,
            sort: "DESC"
          });

          return {
            url: `/surveys/patient-surveys?${queryParams}`,
            method: "GET"
          };
        },
        providesTags: providesTagsMemberSurvey
      }),
      getMemberSurveysReports: builder.query<
        MemberSurveyType[],
        {
          patient_id?: string;
          submitter_roles?: RolesEnum[];
          startDate?: DateTime;
          endDate?: DateTime;
          linked_entities?: SurveyLinkedEntitiesEnum[];
          size?: number;
        }
      >({
        query: ({
          patient_id,
          submitter_roles,
          startDate,
          endDate,
          linked_entities,
          size
        }) => {
          const queryParams = QueryString_stringify({
            patient_id,
            submitter_roles,
            since: startDate ? startDate.startOf("day").toISO() : undefined,
            until: endDate ? endDate.endOf("day").toISO() : undefined,
            survey_type: "PATIENT_ATTRITION",
            linked_entities,
            size,
            sort: "DESC"
          });

          return {
            url: `/surveys/patient-surveys?${queryParams}`,
            method: "GET"
          };
        },
        transformResponse: (response: MemberSurveyType[]) => {
          const filteredData = response?.map((item) => {
            const patient_survey_answers = item?.patient_survey_answers;
            const filteredAnswers = patient_survey_answers?.filter(
              (item) => item?.question !== "Notes"
            );
            return {
              ...item,
              patient_survey_answers: filteredAnswers
            };
          });
          return filteredData;
        },
        providesTags: providesTagsMemberSurvey
      }),
      //Mutations
      sendAnswers: builder.mutation<
        any,
        Omit<
          {
            surveyData: SurveyType;
            answers: { [key: string]: string };
            patient_id: string;
          },
          "id"
        >
      >({
        query: ({ surveyData, answers, patient_id }) => {
          const formattedAnswers = Object.keys(answers).map((key) => {
            const field = surveyData.survey_version.fields.find(
              (field) => field.id === key
            );
            return {
              survey_field_id: key,
              answer: answers[key],

              category: field.category,
              question: field.question
            };
          });

          const body = {
            patient_id,
            survey_version_id: surveyData.survey_version.survey_version_id,
            survey_type: surveyData.survey.type,
            answers: formattedAnswers
          };

          return {
            url: `/surveys/patient-surveys`,
            method: "POST",
            data: body
          };
        },
        invalidatesTags: (result, error, arg) =>
          result ? [{ type: ReduxTagEnum.Survey, id: result?.survey.id }] : []
      })
    })
  });

export const {
  useGetSurveyQuery,
  useGetMemberSurveysQuery,
  useGetMemberSurveysReportsQuery,
  useSendAnswersMutation
} = surveyService;
