import { apiAppointments } from "./AxiosService";
import { QueryString_stringify } from "../helpers/QueryStringHelper";
import ReduxTagEnum from "../enums/ReduxTagEnum";
import { DateTime } from "luxon";
import AppointmentEventResponseType from "../types/Appointments/AppointmentEventResponseType";
import StartEncounterResponseType from "../types/Appointments/StartEncounterResponseType";
import StartEncounterRequestType from "../types/Appointments/StartEncounterRequestType";
import EndEncounterRequestType from "../types/Appointments/EndEncounterRequestType";
import { encountersService } from "./EncountersService";
import { visitsService } from "./VisitsService";

export const DELAY_AFTER_APPOINTMENTS_REQUEST_COMPLETED = 2000;

interface StaffAppointmentsParams {
  staff_id?: string;
  patient_id?: string;
  startdate?: DateTime;
  enddate?: DateTime;
  sort?: "asc" | "desc";
}

interface PatientAppointmentParams {
  patient_id: string;
  startdate: DateTime;
  enddate: DateTime;
  sort?: "asc" | "desc";
}

const appointmentsService = apiAppointments
  .enhanceEndpoints({
    addTagTypes: [
      ReduxTagEnum.Calendar,
      ReduxTagEnum.Availability,
      ReduxTagEnum.Appointments,
      ReduxTagEnum.EncounterVisits
    ]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Queries
      getStaffSchedule: builder.query<
        AppointmentEventResponseType[],
        StaffAppointmentsParams
      >({
        query: ({
          staff_id,
          startdate,
          enddate
        }: {
          staff_id?: string;
          patient_id?: string;
          startdate: DateTime;
          enddate: DateTime;
        }) => {
          const params = QueryString_stringify({
            startdate: startdate.toUTC().toISO(),
            enddate: enddate.toUTC().toISO()
          });
          return {
            url: `/appointments/staff/${staff_id}?${params}`,
            method: "GET"
          };
        },
        providesTags: (result, error, arg) => {
          return [{ type: ReduxTagEnum.Appointments, id: arg.staff_id }];
        },
        transformResponse: (
          response: AppointmentEventResponseType[],
          meta,
          arg
        ) => {
          return response.sort((a, b) => {
            if (arg.sort === "asc") {
              return a.event.startdate < b.event.startdate ? -1 : 1;
            } else {
              return a.event.startdate > b.event.startdate ? -1 : 1;
            }
          });
        }
      }),
      getPatientAppointments: builder.query<
        AppointmentEventResponseType[],
        PatientAppointmentParams
      >({
        query: ({
          patient_id,
          startdate,
          enddate
        }: {
          patient_id?: string;
          startdate: DateTime;
          enddate: DateTime;
        }) => {
          const params = QueryString_stringify({
            startdate: startdate.toUTC().toISO(),
            enddate: enddate.toUTC().toISO()
          });
          return {
            url: `/appointments/patients/${patient_id}?${params}`,
            method: "GET"
          };
        },
        providesTags: (result, error, arg) => {
          return [{ type: ReduxTagEnum.Appointments, id: arg.patient_id }];
        },
        transformResponse: (
          response: AppointmentEventResponseType[],
          meta,
          arg
        ) => {
          return response.sort((a, b) => {
            if (arg.sort === "asc") {
              return a.event.startdate < b.event.startdate ? -1 : 1;
            } else {
              return a.event.startdate > b.event.startdate ? -1 : 1;
            }
          });
        }
      }),
      // Mutations
      startEncounter: builder.mutation<
        StartEncounterResponseType,
        { body: StartEncounterRequestType }
      >({
        query: ({ body }) => ({
          url: `/appointments/start_encounter`,
          method: "PUT",
          data: body
        })
      }),
      endAppointment: builder.mutation<any, { body: EndEncounterRequestType }>({
        query: ({ body }) => ({
          url: `/appointments/end`,
          method: "PUT",
          data: body
        }),
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            const patient_id = props.body.patient_id;
            await queryFulfilled;
            setTimeout(() => {
              dispatch(
                appointmentsService.util.invalidateTags([
                  { type: ReduxTagEnum.Appointments, id: patient_id }
                ])
              );
              dispatch(
                encountersService.util.invalidateTags([ReduxTagEnum.Encounter])
              );
              dispatch(
                visitsService.util.invalidateTags([
                  ReduxTagEnum.EncounterVisits
                ])
              );
            }, DELAY_AFTER_APPOINTMENTS_REQUEST_COMPLETED);
          } catch (error) {}
        }
      })
    })
  });

export const {
  useGetStaffScheduleQuery,
  useGetPatientAppointmentsQuery,
  useStartEncounterMutation,
  useEndAppointmentMutation
} = appointmentsService;

export default appointmentsService;
