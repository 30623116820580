import { useParams } from "react-router-dom";

interface Params {
  memberId?: string;
  orderId?: string;
  userId?: string;
  staffId?: string;
  user_id?: string;
  nurseId?: string;
  athenaId?: string;
  code?: string;
  readingType?: string;
}

const LENGTH_IDS = {
  keys: ["memberId", "orderId", "userId", "user_id", "nurseId", "staffId"],
  maxLength: 24
};

const useSanitizedParams = () => {
  const params = useParams();

  const sanitizedParams: Params = {};

  Object.keys(params).forEach((key) => {
    if (LENGTH_IDS.keys.includes(key)) {
      sanitizedParams[key] = params[key].substring(0, LENGTH_IDS.maxLength);
    } else {
      sanitizedParams[key] = params[key];
    }
  });

  return sanitizedParams;
};

export default useSanitizedParams;
