import { useEffect } from "react";
import MemberType from "common/types/MemberType";
import styled from "@emotion/styled";
import { RootState, useAppDispatch } from "common/redux";
import { useStartEncounterMutation } from "common/services/AppointmentsService";
import VisitStatusEnum from "common/enums/Calendaring/Visits/VisitStatusEnum";
import VisitReasonsEnum from "common/enums/Calendaring/Visits/VisitReasonsEnum";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import ErrorComponent from "../../../components/ErrorComponent";
import { LoadingButton } from "@mui/lab";
import { isFalsy } from "common/helpers/helpers";
import { Athena } from "../../../assets/images/icons";
import { CustomTooltip } from "../../../styling/StyleComponents";
import { Flexbox } from "../../../styling/NewStyleComponents";
import { DateTime } from "luxon";
import VisitMotivationTypesEnum from "common/enums/Calendaring/Visits/VisitMotivationTypesEnum";
import { gray } from "../../../styling/colors";
import { Box } from "@mui/material";
import useGetAuthenticatedUser from "common/hooks/useGetAuthenticatedUser";
import UserLinkedEntitiesEnum from "common/enums/UserLinkedEntitiesEnum";
import { useGetCalendarEventsQuery } from "common/services/CalendarService";
import { canStartEncounter } from "common/enums/RolesEnum";
import { useSelector } from "react-redux";

const StyledAthenaIcon = styled(Athena)`
  height: 26px;
  width: 28px;
  margin-bottom: -8px;
`;

const BUTTON_WIDTH = "200px";
const BUTTON_HEIGHT = "50px";

const Row = styled("div")`
  display: flex;
  gap: 8px;
`;

const RenderButton = ({
  onClick,
  loading,
  disabled
}: {
  onClick: () => void;
  loading: boolean;
  disabled: boolean;
}) => {
  return (
    <Box>
      <LoadingButton
        sx={{ fontSize: "12px" }}
        variant="outlined"
        color="primary"
        id="provider-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={onClick}
        style={{
          display: "block",
          textAlign: "center",
          textTransform: "none",
          width: BUTTON_WIDTH,
          height: BUTTON_HEIGHT
        }}
        loading={loading}
        disabled={disabled}
      >
        <Flexbox gap="4px">
          <StyledAthenaIcon />
          START ENCOUNTER
        </Flexbox>
      </LoadingButton>
    </Box>
  );
};

export default function StartEncounterButton(
  props: Readonly<{
    patient: MemberType;
  }>
) {
  const { patient } = props;

  const dispatch = useAppDispatch();

  const { currentRole } = useSelector((state: RootState) => state.auth);

  const [
    startEncounter,
    {
      data: startEncounterData,
      isLoading: startEncounterLoading,
      error: startEncounterError
    }
  ] = useStartEncounterMutation({});

  const { data: authenticatedUserMetadata } = useGetAuthenticatedUser({
    linkedEntities: [UserLinkedEntitiesEnum.METADATA]
  });

  const memberId = patient?.patient?.patient_id;
  const {
    data: memberScheduleData,
    isFetching: memberScheduleIsFetching,
    error: memberScheduleError
  } = useGetCalendarEventsQuery(
    {
      patient_id: memberId,
      startdate: DateTime.now().startOf("day"),
      enddate: DateTime.now().endOf("day")
    },
    { skip: isFalsy(memberId) || !canStartEncounter(currentRole) }
  );

  const onClick = async () => {
    // We need to grab appointment data from the calendar apis and pass it to the startEncounter mutation if applicable
    // https://copilotiq.atlassian.net/browse/ENG-5195
    const hasScheduledAppointment = memberScheduleData?.length > 0;

    await startEncounter({
      body: {
        patient_id: patient?.patient?.patient_id,
        status: VisitStatusEnum.IN_PROGRESS,
        // TNs starting an encounter only need this reason code for a nurse visit
        // this code needs to be changed if we support more roles or appointment types
        // previously it was in StartEncounterModal
        visit_type: VisitReasonsEnum.REMOTE_PATIENT_MONITORING,

        // We need to grab appointment data from the calendar apis and pass it to the startEncounter mutation if applicable
        // https://copilotiq.atlassian.net/browse/ENG-5195
        motivation_reason: hasScheduledAppointment
          ? VisitMotivationTypesEnum.APPOINTMENT
          : VisitMotivationTypesEnum.PATIENT_INITIATED,
        ...(hasScheduledAppointment && {
          calendar_id: memberScheduleData?.[0]?.event_id,
          calendar_event_start: memberScheduleData?.[0]?.startdate,
          calendar_event_end: memberScheduleData?.[0]?.enddate
        })
      }
    });
  };

  useEffect(() => {
    if (startEncounterData) {
      const id = "startEncounterSuccess";
      Alert_show({
        dispatch,
        id,
        title: "Success! Athena Appointment Created",
        content: (
          <>
            Your Athena appointment was created for this member. Please find it
            there and check in.
            <br />
          </>
        ),
        type: "success",
        size: "medium",
        buttons: [
          {
            text: "Done",
            style: "cancel",
            onPress: () => Alert_close({ dispatch, id })
          }
        ]
      });
    }
  }, [startEncounterData]);

  useEffect(() => {
    if (startEncounterError) {
      const id = "startEncounterError";
      Alert_show({
        dispatch,
        id,
        title: "Error creating Athena appointment",
        content: <ErrorComponent error={startEncounterError} />,
        type: "error",
        size: "small",
        buttons: [
          {
            text: "Done",
            style: "cancel",
            onPress: () => Alert_close({ dispatch, id })
          }
        ]
      });
    }
  }, [startEncounterError]);

  if (!canStartEncounter(currentRole)) {
    return null;
  }

  if (memberScheduleError) {
    return (
      <Row>
        <CustomTooltip
          placement="bottom"
          backgroundColor={gray[200]}
          title={
            <ErrorComponent
              error={
                "Error getting calendar events for member. Please refresh and retry."
              }
            />
          }
        >
          <RenderButton
            onClick={onClick}
            loading={startEncounterLoading}
            disabled
          />
        </CustomTooltip>
      </Row>
    );
  }

  if (isFalsy(authenticatedUserMetadata?.metadata?.athena_provider_id)) {
    return (
      <Row>
        <CustomTooltip
          placement="bottom"
          title={
            <div>
              You do not have a valid Athena Provider ID in RemoteIQ.
              <br />
              Please reach out to support to add it to your account
            </div>
          }
        >
          <RenderButton
            onClick={onClick}
            loading={startEncounterLoading}
            disabled
          />
        </CustomTooltip>
      </Row>
    );
  }

  return (
    <Row>
      <RenderButton
        onClick={onClick}
        loading={startEncounterLoading || memberScheduleIsFetching}
        disabled={!isFalsy(startEncounterData)}
      />
    </Row>
  );
}
