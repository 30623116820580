import RolesEnum from "common/enums/RolesEnum";
import {
  ALL_MEMBERS,
  ALL_RECENT_ORDERS,
  MEMBER_CHART,
  ORDER_DETAILS,
  REPORT_DETAILS,
  REPORT_DETAILS_BY_MEMBER,
  REPORT_LIST,
  MEMBER_CHART_CALENDARING
} from "../RouteComponents";
import {
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_DASHBOARD,
  SIDEBAR_ORDERS,
  SIDEBAR_REPORTS,
  SIDEBAR_SECTIONS,
  SIDEBAR_SUPPORT
} from "../RouteSidebar";

const MEMBER_CARE_SPECIALIST = {
  label: "Member Care Specialist",
  routes: [
    {
      path: "/",
      components: [ALL_MEMBERS({})]
    },
    {
      path: "/members",
      components: [ALL_MEMBERS({})]
    },
    {
      path: "/orders",
      components: [
        {
          type: "order-devices"
        },
        ALL_RECENT_ORDERS()
      ]
    },
    ...MEMBER_CHART_CALENDARING(),
    ORDER_DETAILS(),
    ...MEMBER_CHART(RolesEnum.MEMBER_CARE_SPECIALIST),
    REPORT_LIST,
    REPORT_DETAILS,
    REPORT_DETAILS_BY_MEMBER,
    {
      path: "/help",
      components: [
        {
          type: "help"
        }
      ]
    },
    {
      path: "/my-profile",
      components: [
        {
          type: "profile"
        }
      ]
    }
  ],
  sidebar: [
    SIDEBAR_DASHBOARD,
    {
      sectionName: SIDEBAR_SECTIONS.members,
      items: [SIDEBAR_ALL_MEMBERS, SIDEBAR_ORDERS]
    },
    SIDEBAR_REPORTS,
    SIDEBAR_SUPPORT
  ]
};

export default MEMBER_CARE_SPECIALIST;
