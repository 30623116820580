import RolesEnum from "common/enums/RolesEnum";
import {
  ALL_MEMBERS,
  MEMBER_CHART,
  REPORT_DETAILS,
  REPORT_DETAILS_BY_MEMBER,
  REPORT_LIST
} from "../RouteComponents";
import {
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_DASHBOARD,
  SIDEBAR_REPORTS,
  SIDEBAR_SECTIONS,
  SIDEBAR_SUPPORT
} from "../RouteSidebar";

const SALES_DIRECTOR = {
  label: "Sales Director",
  routes: [
    {
      path: "/",
      components: [ALL_MEMBERS({})]
    },
    {
      path: "/members",
      components: [ALL_MEMBERS({})]
    },
    ...MEMBER_CHART(RolesEnum.SALES_DIRECTOR),
    REPORT_LIST,
    REPORT_DETAILS,
    REPORT_DETAILS_BY_MEMBER,
    {
      path: "/my-profile",
      components: [
        {
          type: "profile"
        }
      ]
    },
    {
      path: "/help",
      components: [
        {
          type: "help"
        }
      ]
    }
  ],
  sidebar: [
    SIDEBAR_DASHBOARD,
    {
      sectionName: SIDEBAR_SECTIONS.members,
      items: [SIDEBAR_ALL_MEMBERS]
    },
    SIDEBAR_REPORTS,
    SIDEBAR_SUPPORT
  ]
};

export default SALES_DIRECTOR;
