import MemberDetailsHeader from "./Header/MemberDetailsHeader";
import MemberDetailsLayout from "./MemberDetailsLayout";
import useSanitizedParams from "../../hooks/useSanitizedParams";
import { DateTime } from "luxon";
import OrderHistoryTable from "../OrderDevices/OrderHistoryTable";
import RefillForm from "../OrderDevices/RefillForm";
import {
  Box,
  Button,
  Card,
  CardContent,
  Popover,
  Tab,
  Tabs,
  Typography,
  styled,
  useTheme
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import OrderDeviceForm from "../OrderDevices/OrderDeviceForm";
import {
  useGetDevicesQuery,
  useUpdateDeviceStatusMutation
} from "common/services/DevicesService";
import DeviceType from "common/types/DeviceType";
import {
  checkIdValid,
  getNameOrUsername,
  mapDeviceTypetoDeviceTrend
} from "common/helpers/helpers";
import { useGetUserWithUsernameQuery } from "common/services/UserService";
import DeviceStatusEnum from "common/enums/DeviceStatusEnum";
import AddressSourceEnum from "common/enums/AddressSourceEnum";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";

import { Flexbox } from "../../styling/NewStyleComponents";
import {
  canSeeSupportOrderForm,
  hasToHideRefillMemberSupplies
} from "common/enums/RolesEnum";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import SupportOrderForm from "../OrderDevices/SupportOrderForm";

import { CustomTooltip, StatusBadge } from "../../styling/StyleComponents";
import StyledIconButton from "../../components/Button/StyledIconButton";
import { SupportAgent } from "@mui/icons-material";
import { DeviceSupportModal } from "../../helpers/components/Forms/DeviceSupportModal/DeviceSupportModal";

const MemberDetailsOrdersContainer = styled("div")`
  position: sticky;
  overflow: hidden;
  margin: 2.5%;
`;

const StyledCard = styled(Card)`
  margin: 10px 40px 0px 0px;
  padding: 12px;
`;

const Column = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const StyledPopover = styled(Popover)``;

const PopoverItem = styled(Typography)`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px 10px;
  &:hover {
    background: ${({ theme }) => theme.palette.grey[100]};
    cursor: pointer;
  }
`;

const RowButton = ({ onClick, title, subtitle }) => {
  const theme = useTheme();
  return (
    <Button
      sx={{ fontSize: "20px" }}
      variant="outlined"
      color="primary"
      id="provider-button"
      aria-controls={open ? "basic-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={open ? "true" : undefined}
      onClick={onClick}
      style={{
        display: "block",
        textAlign: "center",
        textTransform: "none",
        width: 200
      }}
    >
      <Typography color={theme.palette.text.primary} variant="h6">
        {title}
      </Typography>
      <Typography
        color={theme.palette.grey[600]}
        variant="body2"
        overflow="hidden"
        textOverflow="ellipsis"
        noWrap
      >
        {subtitle}
      </Typography>
    </Button>
  );
};

interface DeviceComponentProps {
  device: DeviceType;
  memberId: string;
}

const DeviceSupportButton = ({ device, memberId }) => {
  const theme = useTheme();
  const [deviceSupportModalOpen, setDeviceSupportModalOpen] =
    useState<boolean>(false);
  return (
    <div>
      <StyledIconButton
        Icon={SupportAgent}
        iconColor={theme.palette.primary.main}
        onClick={() => setDeviceSupportModalOpen(true)}
      />
      <DeviceSupportModal
        modalOpen={deviceSupportModalOpen}
        setModalStateCallback={(val) =>
          setDeviceSupportModalOpen(val === "open")
        }
        selectedDevice={device}
        memberId={memberId}
      />
    </div>
  );
};

const DeviceComponent = ({ device, memberId }: DeviceComponentProps) => {
  const { currentRole } = useSelector((state: RootState) => state.auth);
  const theme = useTheme();
  const navigate = useNavigate();

  const { data: orderedByData } = useGetUserWithUsernameQuery({
    username: device.ordered_by
  });
  const [updateDeviceStatusMutation] = useUpdateDeviceStatusMutation();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const isDeviceSupportAllowed = canSeeSupportOrderForm(currentRole);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleDeviceLost = () => {
    updateDeviceStatusMutation({
      device_id: device.device_id,
      status: DeviceStatusEnum.PATIENT_LOST
    });
    handlePopoverClose();
  };

  const handleOrderClick = () => {
    if (device.extra.order_id) {
      navigate(`/orders/${device.extra.order_id}`);
    }
  };

  const open = Boolean(anchorEl);

  return (
    <StyledCard>
      <CardContent>
        <Column>
          <Row>
            <Row>
              <Typography variant="h5">{device.extra.type}</Typography>
              {device?.status_reason ? (
                <CustomTooltip
                  backgroundColor="#ffffff"
                  placement="bottom"
                  title={
                    <Typography variant="body1" color="text.primary">
                      {device?.status_reason?.split("_").join(" ")}
                    </Typography>
                  }
                >
                  <Flexbox>
                    <StatusBadge status={device.status} />
                  </Flexbox>
                </CustomTooltip>
              ) : (
                <Flexbox>
                  <StatusBadge status={device.status} />
                </Flexbox>
              )}

              {isDeviceSupportAllowed && (
                <DeviceSupportButton device={device} memberId={memberId} />
              )}
            </Row>
          </Row>
          {device?.extra?.system === "S_100Plus" && (
            <Typography variant="h6" color="text.black">
              Legacy Device
            </Typography>
          )}
          {device.extra.vendor_device_id && (
            <Typography variant="body2" color="text.secondary">
              Serial Number: {device.extra.vendor_device_id}
            </Typography>
          )}
          <br />

          <Row>
            {device.activated_on && (
              <RowButton
                title={"Activated On"}
                subtitle={DateTime.fromISO(device.activated_on).toFormat(
                  "MM/dd/yyyy"
                )}
                onClick={handleOrderClick}
              />
            )}
            {device.ordered_by && (
              <RowButton
                title={"Ordered By"}
                subtitle={
                  orderedByData ? getNameOrUsername(orderedByData.user) : "-"
                }
                onClick={handleOrderClick}
              />
            )}
          </Row>
        </Column>
      </CardContent>

      <StyledPopover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Card>
          {/* <PopoverItem onClick={() => console.log("test")}>
            Report an issue with device
          </PopoverItem>
          <Divider /> */}
          <PopoverItem onClick={handleDeviceLost}>
            Mark as lost/stolen
          </PopoverItem>
        </Card>
      </StyledPopover>
    </StyledCard>
  );
};

const MemberDetailsOrders = ({
  hideReadings = false
}: {
  hideReadings?: boolean;
}) => {
  const params = useSanitizedParams();
  const { currentRole } = useSelector((state: RootState) => state.auth);
  const { memberId } = params;
  const isValidId = checkIdValid(memberId);

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [addressChanged, setAddressChanged] = useState<boolean>(false);
  const [addressSource, setAddressSource] = useState<AddressSourceEnum>(
    AddressSourceEnum.ATHENA
  );

  const { data: patient } = useGetMemberWithUsernameQuery(
    {
      username: memberId
    },
    { skip: memberId === undefined || isValidId === false }
  );

  const { data: devicesData } = useGetDevicesQuery(
    { memberId: memberId },
    {
      skip: memberId === undefined || isValidId === false
    }
  );

  const handleTabSelect = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  if (!isValidId)
    return (
      <Typography variant="body1">{`Invalid Member ID ${memberId}`}</Typography>
    );

  return (
    <MemberDetailsOrdersContainer>
      <Flexbox
        flexDirection="column"
        height="inherit"
        width="inherit"
        gap="16px"
        overflow="hidden"
      >
        <MemberDetailsHeader key={`${memberId}-header-orders`} />
        <MemberDetailsLayout
          key={`${memberId}-layout-orders`}
          memberId={memberId}
          hideReadings={hideReadings}
        >
          <Tabs
            value={tabIndex}
            onChange={handleTabSelect}
            aria-label="Order Devices"
          >
            <Tab
              data-testid={"OrdersTab"}
              label={"Orders"}
              style={{ fontWeight: "600" }}
            />
            <Tab
              data-testid={"DevicesTab"}
              label={"Devices"}
              style={{ fontWeight: "600" }}
            />
            {canSeeSupportOrderForm(currentRole) && (
              <Tab label={"Support Orders"} style={{ fontWeight: "600" }} />
            )}
          </Tabs>

          {tabIndex === 0 && (
            <>
              {!hasToHideRefillMemberSupplies(currentRole) && (
                <StyledCard>
                  <RefillForm
                    memberId={memberId}
                    setAddressChanged={setAddressChanged}
                    addressChanged={addressChanged}
                    addressSource={addressSource}
                    setAddressSource={setAddressSource}
                  />
                </StyledCard>
              )}
              <OrderHistoryTable memberId={memberId} memberDetails />
            </>
          )}
          {tabIndex === 1 && (
            <>
              <StyledCard>
                <OrderDeviceForm
                  memberId={memberId}
                  setAddressChanged={setAddressChanged}
                  addressChanged={addressChanged}
                  addressSource={addressSource}
                  setAddressSource={setAddressSource}
                />
              </StyledCard>

              {devicesData?.map((device) => {
                return (
                  <DeviceComponent
                    key={device.device_id}
                    device={device}
                    memberId={memberId}
                  />
                );
              })}
            </>
          )}
          {tabIndex === 2 && <SupportOrderForm patient={patient} />}
        </MemberDetailsLayout>
      </Flexbox>
    </MemberDetailsOrdersContainer>
  );
};

export default MemberDetailsOrders;
