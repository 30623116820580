import styled from "@emotion/styled";
import { useFormik } from "formik";
import {
  Box,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import { TurqoiseButton, WhiteButton } from "../../../../styling";
import ErrorComponent from "../../../../components/ErrorComponent";
import { DateTime } from "luxon";
import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  StyledModal
} from "../../../../styling/StyleModal";
import VisitReasonsEnum from "common/enums/Calendaring/Visits/VisitReasonsEnum";
import { useEffect } from "react";
import {
  Alert_close,
  Alert_loading,
  Alert_show
} from "common/helpers/AlertHelper";
import { RootState, useAppDispatch } from "common/redux";
import CommunicationTypeEnum from "common/enums/Calendaring/CommunicationTypeEnum";
import ComplexityEnum from "common/enums/Calendaring/Appointments/ComplexityEnum";
import { Flexbox } from "../../../../styling/NewStyleComponents";
import {
  InfoOutlined,
  PhoneOutlined,
  VideocamOutlined
} from "@mui/icons-material";
import { gray } from "../../../../styling/colors";
import NumberInput from "../../../../components/Input/NumberInput";
import { CustomTooltip } from "../../../../styling/StyleComponents";
import { getComplexityTypes } from "common/helpers/EncounterHelper";
import useGetDelayedLoadingBoolean from "common/hooks/useGetDelayedLoadingBoolean";
import useRouteConfigByRole from "../../../../hooks/useRouteConfigByRole";
import { ProviderRoles } from "common/enums/RolesEnum";
import useGetAuthenticatedUser from "common/hooks/useGetAuthenticatedUser";
import UserLinkedEntitiesEnum from "common/enums/UserLinkedEntitiesEnum";
import { useUpdateMemberCarersMutation } from "common/services/MemberRegistrationService";
import LocalizedStrings from "common/localizations/LocalizedStrings";
import { useSelector } from "react-redux";
import {
  useCreateEncounterMutation,
  useUpdateEncounterMutation
} from "common/services/EncountersService";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface EncounterReason {
  label: string;
  value: VisitReasonsEnum;
}

interface IProps {
  modalOpen: boolean;
  setModalOpen: (val: boolean) => void;
  memberId: string | undefined;
  isProvider: boolean;
  encounterReasons: EncounterReason[];
  memberName: string;
  hasProviderSubmittedEncounter: boolean;
  hasInvalidProviderAssigned: boolean;
  default_total_time?: number;
  default_visit_type?: VisitReasonsEnum;
  default_modality?: CommunicationTypeEnum;
  default_complexity?: ComplexityEnum;
  selected_encounter_id?: string;
}

interface FormValues {
  total_time: number;
  visit_type: VisitReasonsEnum;
  modality: CommunicationTypeEnum;
  complexity: ComplexityEnum;
}

export const SubmitEncounterModal = ({
  modalOpen,
  setModalOpen,
  memberId,
  isProvider,
  encounterReasons,
  memberName,
  hasProviderSubmittedEncounter,
  hasInvalidProviderAssigned,
  default_total_time,
  default_visit_type,
  default_modality,
  default_complexity,
  selected_encounter_id
}: IProps) => {
  const dispatch = useAppDispatch();
  const roleConfig = useRouteConfigByRole();
  const currentUserIsProvider = ProviderRoles.includes(roleConfig.role);

  const { data: authenticatedUser } = useGetAuthenticatedUser({
    linkedEntities: [UserLinkedEntitiesEnum.METADATA]
  });

  const { user } = useSelector((state: RootState) => state.auth);

  const departmentId = authenticatedUser?.metadata?.athena_dpt_id;

  // const [
  //   endAppointment,
  //   {
  //     data: endAppointmentData,
  //     isSuccess: endAppointmentSuccess,
  //     fulfilledTimeStamp: endAppointmentFulfilledTimestamp,
  //     isLoading: endAppointmentLoading,
  //     error: endAppointmentError,
  //     reset: resetEndAppointment
  //   }
  // ] = useEndAppointmentMutation({});

  const [
    createEncounter,
    {
      data: createEncounterData,
      isSuccess: createEncounterSuccess,
      fulfilledTimeStamp: createEncounterFulfilledTimestamp,
      isLoading: createEncounterLoading,
      error: createEncounterError,
      reset: resetEndAppointment
    }
  ] = useCreateEncounterMutation({});

  const [
    updateEncounterMutation,
    {
      isError: updateEncounterIsError,
      isLoading: updateEncounterIsLoading,
      error: updateEncounterError,
      isSuccess: updateEncounterIsSuccess,
      reset: resetUpdateEncounter
    }
  ] = useUpdateEncounterMutation();

  const [
    updatePatientCarers,
    {
      error: updateProviderError,
      isSuccess: isupdateProviderSuccess,
      isError: isUpdateProviderError
    }
  ] = useUpdateMemberCarersMutation();

  useEffect(() => {
    const id = "updateProviderAssignment";
    if (isupdateProviderSuccess) {
      Alert_close({ dispatch, id });
      resetEndAppointment();
    } else if (isUpdateProviderError) {
      Alert_close({ dispatch, id });
      resetEndAppointment();

      // const totalTime = endAppointmentData?.total_time;
      const totalTime = createEncounterData?.duration;

      if (totalTime && departmentId) {
        Alert_show({
          dispatch,
          id,
          title: "Update Provider Assignment",
          content: (
            <>
              <div>
                Successfully submitted {totalTime} minutes for this member.
                <br /> Should you be{" "}
                <b data-testid="usualProviderMessage">
                  set as the usual provider for {memberName}
                </b>
                {/* */}?
              </div>
              {isUpdateProviderError && (
                // @ts-ignore
                <ErrorComponent error={updateProviderError} />
              )}
            </>
          ),
          type: "warning",
          size: "small",
          buttons: [
            {
              text: LocalizedStrings.cancel,
              style: "cancel",
              onPress: () => {
                Alert_close({ dispatch, id });
                resetEndAppointment();
              }
            },
            {
              text: LocalizedStrings.submit,
              style: "default",
              onPress: async () => {
                await updateProviderHandler(user?.user_id);
              }
            }
          ]
        });
      }
    }
  }, [isupdateProviderSuccess, updateProviderError]);

  useEffect(() => {
    if (
      createEncounterSuccess &&
      currentUserIsProvider &&
      (!hasProviderSubmittedEncounter || hasInvalidProviderAssigned)
    ) {
      handleModalClose();
      // const totalTime = endAppointmentData?.total_time;
      const totalTime = createEncounterData?.duration;

      if (totalTime) {
        const id = "updateProviderAssignment";
        Alert_show({
          dispatch,
          id,
          title: "Update Provider Assignment",
          content: (
            <div>
              Successfully submitted {totalTime} minutes for this member. Should
              you be{" "}
              <b data-testid="usualProviderMessage">
                set as the usual provider for {memberName}
              </b>
              {/* */}?
            </div>
          ),
          type: "warning",
          size: "medium",
          buttons: [
            {
              text: LocalizedStrings.cancel,
              style: "cancel",
              onPress: () => {
                Alert_close({ dispatch, id });
                resetEndAppointment();
              }
            },
            {
              text: LocalizedStrings.submit,
              style: "default",
              onPress: async () => {
                Alert_loading({ dispatch, id });
                await updateProviderHandler(user?.user_id);
                Alert_close({ dispatch, id });
                resetEndAppointment();
              },
              hasLoadingState: true
            }
          ]
        });
      }
    } else if (createEncounterSuccess) {
      handleModalClose();

      const id = "submitTimeSuccess";
      Alert_show({
        dispatch,
        id,
        title: "Success! Time Submitted",
        content: (
          <>
            Your time was successfully submitted! <br />
          </>
        ),
        type: "success",
        size: "small",
        buttons: [
          {
            text: "Done",
            style: "cancel",
            onPress: () => Alert_close({ dispatch, id })
          }
        ]
      });
    }
  }, [
    createEncounterSuccess,
    createEncounterData,
    memberName,
    hasProviderSubmittedEncounter,
    hasInvalidProviderAssigned
  ]);

  useEffect(() => {
    if (updateEncounterIsError) {
      const id = "updateError";
      Alert_show({
        dispatch,
        id,
        title: "Something went wrong updating the encounter.",
        content: (
          <ErrorComponent
            error={updateEncounterError}
            showErrorResponseMessage
            hideErrorCode
          />
        ),
        size: "small",
        type: "warning"
      });
    }
  }, [updateEncounterIsError, updateEncounterError]);

  useEffect(() => {
    if (updateEncounterIsSuccess) {
      handleModalClose();
    }
  }, [updateEncounterIsSuccess]);

  async function updateProviderHandler(provider_id: string) {
    await updatePatientCarers({
      patient_id: memberId,
      carers: {
        provider_id: provider_id
      }
    });
  }

  async function updateEncounterHandler(data) {
    await updateEncounterMutation({
      encounterId: selected_encounter_id,
      data
    });
  }

  const validate = (values: FormValues) => {
    const errors = {};

    if (!values.total_time) {
      errors["total_time"] = "Required";
    }

    if (!values.visit_type && !default_visit_type) {
      errors["visit_type"] = "Required";
    }
    if (!values.modality) {
      errors["modality"] = "Required";
    }
    if (
      values.modality === CommunicationTypeEnum.VIDEO &&
      values.visit_type !== VisitReasonsEnum.ASYNC_REMOTE_PATIENT_MONITORING &&
      !values.complexity
    ) {
      errors["complexity"] = "Required";
    }

    // if we are editing, check if the values are the same before allowing submission
    if (
      default_visit_type &&
      values.total_time === default_total_time &&
      values.modality === default_modality &&
      values.complexity === default_complexity
    ) {
      errors["visit_type"] = "Please make a change before submitting";
    }

    return errors;
  };

  const onSubmit = async (values: FormValues) => {
    if (default_visit_type && selected_encounter_id) {
      const updateBody: any = {};
      if (values.total_time !== default_total_time) {
        updateBody.duration = values.total_time;
      }

      if (values.complexity !== default_complexity) {
        updateBody.complexity = `CPT_${values.complexity}`;
      }

      if (values.modality !== default_modality) {
        updateBody.modality = values.modality;

        if (
          values.modality === CommunicationTypeEnum.PHONE &&
          updateBody.complexity
        ) {
          // don't send complexity for a phone encounter
          delete updateBody.complexity;
        }
      }

      await updateEncounterHandler(updateBody);
    } else {
      await createEncounter({
        body: {
          patient_id: memberId,
          submitted_by: user?.user_id,
          starts_on: DateTime.now().toUTC().toISO(),
          reason: values.visit_type,
          ...(values.complexity &&
            // only send complexity for video calls
            values.modality === CommunicationTypeEnum.VIDEO && {
              complexity: `CPT_${values.complexity}`
            }),
          duration: values.total_time,
          ...(values.modality && { modality: values.modality })
        }
      });
    }
    // await endAppointment({
    //   body: {
    //     patient_id: memberId,
    //     // default outcome/disposition to COMPLETED
    //     // tbd double check if we need to send disposition once this code goes live in 2.10.0
    //     // disposition: VisitDispositionEnum.COMPLETED,
    //     visit_type: values.visit_type,
    //     ...(values.complexity && { complexity: values.complexity }),
    //     total_time: values.total_time,
    //     ...(values.modality && {
    //       contact_attempts: [{ communication_type: values.modality }]
    //     })
    //   }
    // });
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      total_time: default_total_time,
      visit_type: default_visit_type,
      // only providers can select video calls for now
      modality: isProvider ? default_modality : CommunicationTypeEnum.PHONE,
      complexity: default_complexity
    },
    validate,
    onSubmit
  });

  const handleModalClose = () => {
    formik.resetForm();
    resetEndAppointment();
    setModalOpen(false);
  };

  const isCreateLoading = useGetDelayedLoadingBoolean(
    createEncounterLoading,
    createEncounterFulfilledTimestamp,
    createEncounterData,
    // DELAY_AFTER_APPOINTMENTS_REQUEST_COMPLETED +
    500
  );

  const isUpdateLoading = useGetDelayedLoadingBoolean(
    updateEncounterIsLoading,
    createEncounterFulfilledTimestamp,
    createEncounterData,
    500
  );

  return (
    <StyledModal
      isOpen={modalOpen}
      contentLabel="Enter Time Spent Modal"
      modalHeight="max-content"
      onRequestClose={handleModalClose}
    >
      <Form onSubmit={formik.handleSubmit}>
        <ModalHeader onRequestClose={handleModalClose}>
          {selected_encounter_id && default_visit_type
            ? "Edit Encounter"
            : "Enter Time Spent"}
        </ModalHeader>
        <ModalBody>
          <Flexbox gap="20px" flexDirection="column">
            {!default_visit_type && (
              <Flexbox gap="8px" flexDirection="column">
                <InputLabel>
                  <Typography variant="h6" color="text.primary">
                    Visit Type
                  </Typography>
                </InputLabel>
                <TextField
                  data-testid="Visit Type"
                  value={formik.values?.visit_type ?? ""}
                  placeholder="Select Visit Type"
                  select
                  fullWidth
                  onChange={(event) => {
                    formik.setFieldValue("visit_type", event.target.value);
                  }}
                >
                  {encounterReasons?.map((reason) => (
                    <MenuItem
                      key={reason.value}
                      value={reason.value}
                      data-testid={reason.label}
                    >
                      {reason.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Flexbox>
            )}
            {isProvider && (
              <Flexbox flexDirection="column" gap="8px" minWidth="max-content">
                <Typography variant="h6" color="text.primary">
                  Modality
                </Typography>
                <Box
                  sx={{ borderRadius: "8px", border: `1px solid ${gray[300]}` }}
                >
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={formik.values?.modality ?? ""}
                    onChange={(event) => {
                      if (event.target.value === CommunicationTypeEnum.PHONE) {
                        // reset complexity
                        formik.setFieldValue("complexity", default_complexity);
                      }
                      formik.setFieldValue("modality", event.target.value);
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        width: "100%",
                        margin: 0,
                        borderBottom: `1px solid ${gray[300]}`
                      }}
                      value="PHONE"
                      control={<Radio />}
                      data-testid="phone"
                      label={
                        <Typography
                          variant="body1"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px"
                          }}
                        >
                          Audio Call
                          <PhoneOutlined />
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      sx={{
                        width: "100%",
                        margin: 0
                      }}
                      value="VIDEO"
                      data-testid="video"
                      control={<Radio />}
                      label={
                        <Typography
                          variant="body1"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px"
                          }}
                        >
                          Video Call
                          <VideocamOutlined />
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </Box>
              </Flexbox>
            )}
            <Flexbox justifyContent="space-between">
              <Flexbox flexBasis="48%" gap="8px" flexDirection="column">
                <FormLabel>
                  <Typography variant="h6" color="text.primary">
                    <Flexbox alignItems="center" gap="2px">
                      Total Time{" "}
                      <CustomTooltip
                        backgroundColor={gray[200]}
                        title={
                          <Typography
                            variant="body1"
                            color="text.secondary"
                            maxWidth="225px"
                          >
                            This is the total time spent in service of members
                            including call attempts, async messaging, task
                            completion, charting, etc.
                          </Typography>
                        }
                      >
                        <InfoOutlined color="primary" fontSize="small" />
                      </CustomTooltip>
                    </Flexbox>
                  </Typography>
                </FormLabel>
                <NumberInput
                  sx={{ width: "100%" }}
                  placeholder="1-60 min"
                  id={"total_time"}
                  name={"total_time"}
                  data-testid="totalTimeInput"
                  value={formik.values?.total_time ?? ""}
                  error={formik.errors.total_time}
                  onValueChange={(value) => {
                    formik.setFieldValue("total_time", value);
                  }}
                  min={1}
                  max={60}
                />
              </Flexbox>
            </Flexbox>
            {formik.values.modality === CommunicationTypeEnum.VIDEO &&
              isProvider &&
              (formik.values.visit_type === VisitReasonsEnum.PROVIDER_INTAKE ||
                formik.values.visit_type ===
                  VisitReasonsEnum.CHRONIC_DISEASE_MANAGEMENT ||
                formik.values.visit_type === VisitReasonsEnum.REACTIVATING) && (
                <Flexbox gap="8px" flexDirection="column">
                  <FormLabel>
                    <Typography variant="h6" color="text.primary">
                      Complexity
                    </Typography>
                  </FormLabel>
                  <TextField
                    value={formik.values?.complexity ?? ""}
                    placeholder="Select Complexity"
                    select
                    fullWidth
                    onChange={(event) => {
                      formik.setFieldValue("complexity", event.target.value);
                    }}
                  >
                    {getComplexityTypes(formik.values.visit_type)?.map(
                      (type) => (
                        <MenuItem key={type.value} value={type.value}>
                          {type.label}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                </Flexbox>
              )}
          </Flexbox>
          {createEncounterError && (
            <ErrorComponent
              error={createEncounterError}
              showErrorResponseMessage={true}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <ModalFooterButtons>
            <TurqoiseButton
              loading={isCreateLoading || isUpdateLoading}
              disabled={!formik.dirty || !formik.isValid}
              type="submit"
              data-testid="submitButton"
            >
              Submit
            </TurqoiseButton>
            <WhiteButton onClick={handleModalClose}>Cancel</WhiteButton>
          </ModalFooterButtons>
        </ModalFooter>
      </Form>
    </StyledModal>
  );
};
