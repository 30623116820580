import { styled, Typography, useTheme } from "@mui/material";
import { useCollapse } from "react-collapsed";
import { DateTime } from "luxon";
import { blue } from "../styling/colors";
import { useEffect, useRef, useState } from "react";

const Column = styled("div")`
  display: inline-flex;
  flex-direction: column;
  margin: 5px;
`;

const Container = styled("div")`
  display: inline-flex;

  background-color: white;
  border-radius: 20px;
  border: 2px solid ${({ theme }) => theme.palette.primary.main};
  overflow: hidden;
  cursor: pointer;
  z-index: 10;
`;

const AnswerContainer = styled("div")`
  display: flex;
  flex: 1;
  padding: 10px;
  justify-content: center;
  background-color: white;
`;

const CategoryContainer = styled(AnswerContainer)`
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

const ExpandableContainer = styled("section")`
  background-color: ${() => blue[100]};
  border-radius: 0px 0px 20px 20px;

  border-left: 2px solid ${({ theme }) => theme.palette.primary.main};
  border-right: 2px solid ${({ theme }) => theme.palette.primary.main};
  border-bottom: 2px solid ${({ theme }) => theme.palette.primary.main};
  margin-top: -20px;
  z-index: 0;
  padding: 30px 10px 10px 10px;
`;

interface IProps {
  category: string;
  answer: string;
  notes: string;
  last_modified: string;
}

const CategoryChip = ({ category, answer, notes, last_modified }: IProps) => {
  const theme = useTheme();

  const containerRef = useRef<HTMLDivElement>(null);

  const { getCollapseProps, getToggleProps } = useCollapse();

  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    if (width === 0) setWidth(containerRef?.current?.clientWidth);
  }, [containerRef]);

  return (
    <Column ref={containerRef}>
      <Container {...getToggleProps()}>
        <CategoryContainer>
          <Typography color="white" variant="h6">
            {category}
          </Typography>
        </CategoryContainer>
        <AnswerContainer>
          <Typography color="primary" variant="h6">
            {answer}
          </Typography>
        </AnswerContainer>
      </Container>
      <ExpandableContainer sx={{ width }} {...getCollapseProps()}>
        <Typography color={theme.palette.secondary.main} variant="body1">
          Edited {DateTime.fromISO(last_modified).toFormat("MMM dd yyyy")}
        </Typography>
        <br />
        <Typography color={theme.palette.secondary.main} variant="body1">
          {notes}
        </Typography>
      </ExpandableContainer>
    </Column>
  );
};

export default CategoryChip;
