import { forwardRef, Ref, useImperativeHandle, lazy } from "react";
import { DateTime } from "luxon";
import { CircularProgress } from "@mui/material";

import ReportRefInterface from "./ReportRefInterface";
import MemberType from "common/types/MemberType";
import useGetMemberDataSummary from "common/hooks/useGetMemberDataSummary";
import {
  CopilotIQFullLogoBW,
  GlucosePdfIcon,
  GlucosePdfIconBW,
  BpPdfIcon,
  BpPdfIconBW
} from "../../../assets/images";
import { ErrorText } from "../../../styling";
const ReportBloodPressureChart = lazy(
  () => import("./ReportBloodPressureChart")
);
const ReportGlucoseChart = lazy(() => import("./ReportGlucoseChart"));

interface IProps {
  patient: MemberType;
  startDate: DateTime;
  endDate: DateTime;
}

const PatientChartShare = forwardRef(
  ({ patient, startDate, endDate }: IProps, ref: Ref<ReportRefInterface>) => {
    const { glucose, bloodPressure, isLoading, trends } =
      useGetMemberDataSummary({
        patient,
        dateFrom: startDate,
        dateTo: endDate,
        ignoreGlucoseOtherReadings: true,
        getTrends: true
      });

    useImperativeHandle(ref, () => ({
      getReadingsData: () => {
        return {
          startDate,
          endDate,
          glucose,
          bloodPressure,
          trends
        };
      }
    }));

    return (
      <div>
        {bloodPressure?.data?.length <= 0 && glucose?.data?.length <= 0 && (
          <>
            <br />
            <ErrorText>
              Warning: No readings taken in selected time range
            </ErrorText>
          </>
        )}
        {bloodPressure && bloodPressure?.data?.length >= 0 && (
          <>
            <ReportBloodPressureChart
              bloodPressureData={bloodPressure.data}
              bloodPressureError={bloodPressure.error}
              patient={patient}
              isBlackAndWhite={false}
              chartCssId="bpchartcontainer"
              startDate={startDate}
              endDate={endDate}
            />
          </>
        )}
        {glucose && glucose?.data?.length >= 0 && (
          <>
            <ReportGlucoseChart
              glucoseData={glucose.data}
              glucoseError={glucose.error}
              patient={patient}
              isBlackAndWhite={false}
              chartCssId="glucosechartcontainer"
              startDate={startDate}
              endDate={endDate}
            />
          </>
        )}
        {isLoading && <CircularProgress />}
        {/* hidden container to render elements to be exported */}
        <div style={{ position: "relative" }}>
          <div id="canvas" style={{ position: "absolute", zIndex: -1 }} />
          {bloodPressure && bloodPressure?.data?.length >= 0 && (
            <>
              <ReportBloodPressureChart
                bloodPressureData={bloodPressure.data}
                bloodPressureError={bloodPressure.error}
                patient={patient}
                isBlackAndWhite={true}
                chartCssId="bpchartcontainer-bw"
                startDate={startDate}
                endDate={endDate}
                // we need to hide black and white SVGs, but still render it so that the PDF can be generated
                style={{ position: "absolute", zIndex: -1 }}
              />
            </>
          )}
          {glucose && glucose?.data?.length >= 0 && (
            <>
              <ReportGlucoseChart
                glucoseData={glucose.data}
                glucoseError={glucose.error}
                patient={patient}
                isBlackAndWhite={true}
                startDate={startDate}
                endDate={endDate}
                chartCssId="glucosechartcontainer-bw"
                // we need to hide black and white SVGs, but still render it so that the PDF can be generated
                style={{ position: "absolute", zIndex: -1 }}
              />
            </>
          )}
          <CopilotIQFullLogoBW
            style={{ position: "absolute", zIndex: -1 }}
            id="copilot-logo-bw"
          />
          <GlucosePdfIcon
            style={{ position: "absolute", zIndex: -1 }}
            id="glucose-pdf-icon"
          />
          <GlucosePdfIconBW
            style={{ position: "absolute", zIndex: -1 }}
            id="glucose-pdf-icon-bw"
          />
          <BpPdfIcon
            style={{ position: "absolute", zIndex: -1 }}
            id="bp-pdf-icon"
          />
          <BpPdfIconBW
            style={{ position: "absolute", zIndex: -1 }}
            id="bp-pdf-icon-bw"
          />
        </div>
      </div>
    );
  }
);

export default PatientChartShare;
