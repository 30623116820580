import { Fragment, Ref, forwardRef, useImperativeHandle, useMemo } from "react";

import { DateTime } from "luxon";

import { useGetMemberSurveysReportsQuery } from "common/services/SurveyService";

import ReportRefInterface from "./ReportRefInterface";
import Table from "../../../components/Table/Table";
import { CircularProgress, Typography } from "@mui/material";
import { ErrorText } from "../../../styling";
import ErrorComponent from "../../../components/ErrorComponent";
import RolesEnum from "common/enums/RolesEnum";
import ReportEnum from "../../../enums/ReportEnum";

interface IProps {
  startDate: DateTime;
  endDate: DateTime;
  submitterRoles: RolesEnum[];
  id: ReportEnum;
  csvFilename: string;
}

const percentageSortingFn = (a, b) => {
  const percentageA = a.original.percentage;
  const percentageB = b.original.percentage;

  const numberA = parseInt(percentageA.replace("%", ""));
  const numberB = parseInt(percentageB.replace("%", ""));

  return numberA - numberB;
};

const summaryColumns = [
  { name: "default", id: "category", accessor: "category", header: "Category" },
  {
    name: "default",
    id: "count",
    accessor: "count",
    header: "Count"
  },
  {
    name: "default",
    id: "percentage",
    accessor: "percentage",
    header: "Percentage",
    sortingFn: percentageSortingFn
  }
];

const detailedColumns = (headers: string[]) => [
  { name: "default", id: "Category", header: headers[0], accessor: "answer" },
  { name: "number", id: "Count", header: headers[1], accessor: "count" },
  {
    name: "default",
    id: "Percentage",
    header: headers[2],
    accessor: "percentage",
    sortingFn: percentageSortingFn
  },
  {
    name: "default",
    id: "Overall Percentage",
    header: headers[3],
    accessor: "overallPercentage",
    sortingFn: percentageSortingFn
  }
];

const SummaryComponent = ({ data }) => {
  const total = useMemo(() => getTotal(data), [data]);

  return (
    <>
      <Typography variant="body1" color="text.secondary">
        {`Total: ${total}`}
      </Typography>
    </>
  );
};

const getTotal = (data) => {
  if (data === undefined) return 1;
  const count = data.reduce((prevValue, currentValue) => {
    return prevValue + currentValue.count;
  }, 0);
  return count;
};

interface AnswerType {
  count: number;
  optionCount: { [key: string]: number };
}

const ReportAttrition = forwardRef(
  (
    { startDate, endDate, submitterRoles, id, csvFilename }: IProps,
    ref: Ref<ReportRefInterface>
  ) => {
    const { data, isLoading, isError, isSuccess, error } =
      useGetMemberSurveysReportsQuery({
        submitter_roles: submitterRoles,
        startDate,
        endDate
      });

    const statisticsData = useMemo(() => {
      if (data === undefined) return undefined;
      let total = 0;
      let categoryData: { [key: string]: AnswerType } = {};

      // Sort by creation date
      const sortedData = [...data].sort((a, b) => {
        const aDate = DateTime.fromSQL(a.patient_survey.created_at);
        const bDate = DateTime.fromSQL(b.patient_survey.created_at);

        // Newest first
        return bDate.toMillis() - aDate.toMillis();
      });

      // prevent duplicates array
      let patients = [];

      sortedData.forEach((item) => {
        if (patients.includes(item.patient_survey.patient_id)) return;
        patients.push(item.patient_survey.patient_id);

        item.patient_survey_answers.forEach((answer) => {
          let answerCategory = categoryData[answer.category];
          if (answerCategory === undefined) {
            answerCategory = { optionCount: {}, count: 0 };
            categoryData[answer.category] = answerCategory;
          }
          if (answerCategory.optionCount[answer.answer] === undefined) {
            answerCategory.optionCount[answer.answer] = 0;
          }
          answerCategory.optionCount[answer.answer]++;
          answerCategory.count++;
          total++;
        });
      });
      const categories = Object.keys(categoryData);

      return categories.map((category) => {
        const answersData = categoryData[category].optionCount;
        const answers = Object.keys(answersData);

        const categoryCount = categoryData[category].count;
        return {
          category,
          count: categoryCount,
          answers: answers.map((answer) => {
            const count = answersData[answer];
            return {
              answer,
              count,
              percentage: `${Math.round((count * 100) / categoryCount)}%`,
              overallPercentage: `${Math.round((count * 100) / total)}%`
            };
          })
        };
      });
    }, [data]);

    const total = useMemo(() => getTotal(statisticsData), [statisticsData]);

    const summaryData = useMemo(() => {
      if (statisticsData === undefined) return undefined;

      return statisticsData.map((item) => {
        return {
          ...item,
          percentage: Math.round((item.count * 100) / total) + "%"
        };
      });
    }, [statisticsData, total]);

    useImperativeHandle(ref, () => ({
      getCSVReportData() {
        const formattedData = statisticsData.map(
          ({ category, count, answers }) => [
            category,
            answers.map((item) => item.answer).join(", "),
            count.toString(),
            `${Math.round((count * 100) / total)}%`
          ]
        );

        return {
          filename: csvFilename,
          columns: ["Category", "Sub Category ", "Count", "Percentage"],
          data: formattedData
        };
      },
      getReportData: () => {
        const total = getTotal(statisticsData);

        return [
          {
            title: "Cancellations Summary by Category",
            columnNames: ["Category", "Count", "Percentage"],
            data: statisticsData.map(({ category, count }) => [
              category,
              count.toString(),
              Math.round((count * 100) / total) + "%"
            ]),
            reportSummary: [`Total: ${total}`]
          },
          ...statisticsData.map(({ category, count, answers }, index) => {
            const isLast = index === statisticsData.length - 1;
            return {
              title: "Detailed",
              columnNames: [
                category,
                count.toString(),
                `${Math.round((count * 100) / total)}%`,
                "Overall Percentage"
              ],

              data: answers.map(
                ({ answer, count, overallPercentage, percentage }) => [
                  answer,
                  count.toString(),
                  percentage,
                  overallPercentage
                ]
              ),
              reportSummary: isLast && [`Total: ${total}`]
            };
          })
        ];
      }
    }));

    return (
      <Fragment key={id}>
        {data && data.length > 0 && (
          <>
            <Typography variant="h3">Summary by Category</Typography>
            <Table
              tableColumns={summaryColumns}
              data={summaryData}
              tableMaxHeight={"none"}
            />
            <br />
            <Typography variant="body1" color="text.secondary">
              {`Total: ${total}`}
            </Typography>

            <br />
            <br />

            <Typography variant="h3">Detailed</Typography>
            {statisticsData.map(({ category, count, answers }) => {
              return (
                <Table
                  key={category}
                  tableColumns={detailedColumns([
                    category,
                    "Count",
                    "Percentage",
                    "Overall Percentage"
                  ])}
                  data={answers}
                  tableMaxHeight={"none"}
                />
              );
            })}

            <br />
            <SummaryComponent data={statisticsData} />
          </>
        )}
        {isLoading && <CircularProgress />}
        {isSuccess && data && data.length === 0 && (
          <ErrorText>No data found.</ErrorText>
        )}
        {isError && <ErrorComponent error={error} />}
      </Fragment>
    );
  }
);

ReportAttrition.displayName = "ReportAttrition";

export default ReportAttrition;
