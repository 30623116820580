import { useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";

import { useGetOrdersByCreationDateQuery } from "common/services/OrdersService";
import OrderMemberType from "common/types/OrderMemberType";
import { useGetCarerMembersByNurseQuery } from "common/services/CarerMembersService";
import { checkIdValid } from "common/helpers/helpers";

const useGetRecentOrders = (nurseId: string, days = 15) => {
  const now = useMemo(() => {
    return DateTime.now().endOf("day");
  }, []);
  const isValidId = checkIdValid(nurseId);

  const [processedOrders, setProcessedOrders] = useState<OrderMemberType[]>();

  const { since, until } = useMemo(() => {
    const since = now.minus({ days });
    const until = now.plus({ days: 1 });
    return { since, until };
  }, []);

  const {
    data: orders,
    isLoading: isLoadingOrders,
    error: ordersError,
    isSuccess: isSuccessOrders
  } = useGetOrdersByCreationDateQuery(
    {
      since,
      until
    },
    { skip: nurseId === undefined || isValidId === false }
  );

  const {
    data: patientsList,
    isLoading: isLoadingPatientsList,
    error: patientsListError,
    isSuccess: isSuccessPatientsList
  } = useGetCarerMembersByNurseQuery(nurseId, {
    skip: nurseId === undefined || isValidId === false
  });

  useEffect(() => {
    if (orders && patientsList && !processedOrders) {
      let filteredList: any[] = [];

      patientsList.forEach((patient) => {
        const filteredOrders = orders.filter((item) => {
          return item?.order?.patient_id === patient?.patient?.patient_id;
        });

        const patientOrders = filteredOrders.map((item) => {
          const currentPatient = patientsList.find(
            (patient) =>
              // @ts-ignore
              patient?.patient?.patient_id === item?.order?.patient_id
          );

          // @ts-ignore
          const { first, middle, last, username } =
            currentPatient?.patient || {};
          return {
            ...item,
            patientDetails: { first, middle, last, username }
          };
        });

        if (patientOrders?.length > 0) {
          filteredList.push(...patientOrders);
        }
      });
      // de-dupe
      filteredList = [...new Set(filteredList)];
      const sortedOrders = filteredList.sort((a, b) => {
        const aDate = DateTime.fromSQL(a?.order?.created_at);
        const bDate = DateTime.fromSQL(b?.order?.created_at);
        return bDate.toMillis() - aDate.toMillis();
      });

      setProcessedOrders(sortedOrders);
    }
  }, [orders, patientsList, processedOrders]);

  const isLoading = isLoadingOrders || isLoadingPatientsList;

  return {
    data: processedOrders,
    isSuccess: isSuccessOrders && isSuccessPatientsList,
    isLoading,
    isError: ordersError || patientsListError,
    error: {
      orders: ordersError,
      patientsList: patientsListError
    }
  };
};

export default useGetRecentOrders;
