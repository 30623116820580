import { DateTime } from "luxon";
import RecurrenceFrequencyEnum from "../enums/Calendaring/Appointments/RecurrenceFrequencyEnum";
import timezones from "common/config/timezones.json";

function dateIsBlocked(availability) {
  // convert time to EST
  const timezone = "America/New_York";
  const start = DateTime.fromISO(availability.start).setZone(timezone);
  const end = DateTime.fromISO(availability.end).setZone(timezone);

  let isBlocked = false;
  // We want to block 11:30 am - 12:30 pm EST on Thursdays
  if (
    start.weekdayLong === "Thursday" &&
    ((start.hour === 11 && start.minute >= 30) ||
      (start.hour === 12 && start.minute < 30) ||
      (end.hour === 11 && end.minute > 30) ||
      (end.hour === 12 && end.minute <= 30))
  ) {
    isBlocked = true;
  }

  return isBlocked;
}

function getRecurrenceObject(
  recurrence: RecurrenceFrequencyEnum,
  startDate: string,
  endDate: string,
  timezone: string
) {
  const startDateObj = DateTime.fromISO(startDate).setZone(timezone);
  const endDateObj = DateTime.fromISO(endDate).setZone(timezone);
  switch (recurrence) {
    case RecurrenceFrequencyEnum.NEVER:
      return null;
    case RecurrenceFrequencyEnum.WEEKLY:
      return {
        frequency: RecurrenceFrequencyEnum.WEEKLY,
        date_of_week: startDateObj.weekdayLong.toLocaleUpperCase(),
        duration: endDateObj.diff(startDateObj, "minutes").minutes
      };
    case RecurrenceFrequencyEnum.BIWEEKLY:
      return {
        frequency: RecurrenceFrequencyEnum.BIWEEKLY,
        date_of_week: startDateObj.weekdayLong.toLocaleUpperCase(),
        duration: endDateObj.diff(startDateObj, "minutes").minutes
      };
    default:
      return null;
  }
}

function getAmericanTimezone(timezone: string) {
  const zone = timezones.find((tz) => tz.value === timezone);
  return zone?.label ? `${zone.label[0]}T` : "N/A";
}

const defaultPrefObject = {
  MORNING: false,
  MIDDAY: false,
  AFTERNOON: false
};

function transformTimeOfDayPrefArrayToObject(arr) {
  const prefObject = { ...defaultPrefObject };

  arr?.forEach((timeOfDay) => {
    prefObject[timeOfDay] = true;
  });

  return prefObject;
}

const MORNING_SLOTS = [
  {
    availability: {
      start: "8:30",
      end: "9:30"
    }
  },
  {
    availability: {
      start: "9:30",
      end: "10:30"
    }
  }
];

const MIDDAY_SLOTS = [
  {
    availability: {
      start: "10:30",
      end: "11:30"
    }
  },
  {
    availability: {
      start: "11:30",
      end: "12:30"
    }
  },
  {
    availability: {
      start: "1:00",
      end: "2:00"
    }
  }
];

const AFTERNOON_SLOTS = [
  {
    availability: {
      start: "2:00",
      end: "3:00"
    }
  },
  {
    availability: {
      start: "3:00",
      end: "4:00"
    }
  },
  {
    availability: {
      start: "4:00",
      end: "5:00"
    }
  }
];

const WEEKDAYS = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"];

export {
  dateIsBlocked,
  getRecurrenceObject,
  getAmericanTimezone,
  transformTimeOfDayPrefArrayToObject,
  defaultPrefObject,
  MORNING_SLOTS,
  MIDDAY_SLOTS,
  AFTERNOON_SLOTS,
  WEEKDAYS
};
