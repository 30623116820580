import { QueryString_stringify } from "../helpers/QueryStringHelper";
import { apiTasking } from "./AxiosService";
import ReduxTagEnum from "../enums/ReduxTagEnum";
import TaskCountType from "../types/TaskCountType";
import TaskType from "../types/TaskType";
import { PromiseWithKnownReason } from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";
import { AppDispatch } from "../redux";
import TaskTypeEnum from "../enums/TaskTypeEnum";
import TaskStatusEnum from "../enums/TaskStatusEnum";
import { DateTime } from "luxon";

async function clearCache(
  queryFulfilled: PromiseWithKnownReason<any, any>,
  dispatch: AppDispatch,
  id: string
) {
  try {
    await queryFulfilled;
    setTimeout(() => {
      dispatch(
        taskingService.util.invalidateTags([
          ReduxTagEnum.Tasks,
          { type: ReduxTagEnum.Tasks, id }
        ])
      );
    }, 3000);
  } catch (error) {}
}

export const taskingService = apiTasking
  .enhanceEndpoints({
    addTagTypes: [
      ReduxTagEnum.Member,
      ReduxTagEnum.LicensedStates,
      ReduxTagEnum.Tasks
    ]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getTasks: builder.query<
        TaskType[],
        {
          assignee_id?: string;
          patient_ids?: string;
          status?: TaskStatusEnum[];
          shouldShowBillingTasks?: boolean;
          modified_at_after?: DateTime;
          modified_at_before?: DateTime;
        }
      >({
        query: ({
          assignee_id,
          patient_ids,
          status,
          modified_at_after,
          modified_at_before
        }) => {
          const queryParams = QueryString_stringify({
            assignee_id,
            status,
            patient_ids,
            modified_at_after: modified_at_after
              ? modified_at_after.toISO()
              : undefined,
            modified_at_before: modified_at_before
              ? modified_at_before.toISO()
              : undefined
          });
          return {
            url: `/tasks?${queryParams}`,
            method: "GET"
          };
        },
        providesTags: (result, error, arg) => [
          { type: ReduxTagEnum.Tasks, id: arg.assignee_id }
        ],
        transformResponse: (response: TaskType[], meta, arg) => {
          if (!arg.shouldShowBillingTasks) {
            return response.filter(
              (task) =>
                task?.task?.type !== TaskTypeEnum.BILLING_MISSING_DIAGNOSIS &&
                task?.task?.type !== TaskTypeEnum.BILLING_MISSING_INSURANCE
            );
          }
          return response;
        }
      }),
      getTasksCount: builder.query<
        TaskCountType,
        {
          assignee_id: string;
          // tbd need to filter this on the backend
          shouldShowBillingTasks: boolean;
          modified_at_after?: DateTime;
          modified_at_before?: DateTime;
        }
      >({
        query: ({ assignee_id, modified_at_after, modified_at_before }) => {
          const queryParams = QueryString_stringify({
            assignee_id,
            modified_at_after: modified_at_after
              ? modified_at_after.toISO()
              : undefined,
            modified_at_before: modified_at_before
              ? modified_at_before.toISO()
              : undefined
          });
          return {
            url: `/tasks/aggregated?${queryParams}`,
            method: "GET"
          };
        },
        providesTags: (result, error, arg) => [
          { type: ReduxTagEnum.Tasks, id: arg.assignee_id }
        ]
      }),
      // mutations
      updateTaskStatus: builder.mutation<
        any,
        Omit<
          {
            task_id: string;
            assignee_id: string;
            status: string;
            body?: any;
          },
          "id"
        >
      >({
        query: ({ task_id, status, body }) => {
          return {
            url: `/tasks/${task_id}/status/${status}`,
            method: "PUT",
            data: body
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: ReduxTagEnum.Tasks, id: arg.assignee_id }
        ]
      }),
      addActionsToTask: builder.mutation<
        any,
        Omit<
          {
            task_id: string;
            body?: any;
            assignee_id: string;
            refreshDataRouteChanged?: boolean;
          },
          "id"
        >
      >({
        query: ({ task_id, body }) => {
          return {
            url: `/tasks/${task_id}/actions`,
            method: "PUT",
            data: body
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: ReduxTagEnum.Tasks, id: arg.assignee_id }
        ],
        // we need this here to handle the case where a user clicks "Call" on an unread task
        // this navigates them to a new route, we want to refetch task data
        // and see the "READ" task status update
        async onQueryStarted(
          { assignee_id, refreshDataRouteChanged },
          { dispatch, queryFulfilled }
        ) {
          // only manually clear cache like this if we navigate to a new route. Otherwise, invalidatesTags should work
          if (!refreshDataRouteChanged) return;
          clearCache(queryFulfilled, dispatch, assignee_id);
        }
      })
    })
  });

export const {
  useGetTasksQuery,
  useGetTasksCountQuery,
  useUpdateTaskStatusMutation,
  useAddActionsToTaskMutation
} = taskingService;
