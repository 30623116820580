import { getErrorMessage, isFalsy } from "common/helpers/helpers";
import { ErrorText } from "../styling";

interface IProps {
  header?: string;
  footer?: string;
  error?: any; //TODO: fix typescript. Should be: AxiosError | string;
  style?: any;
  showErrorResponseMessage?: boolean;
  hideErrorCode?: boolean;
}

const ErrorComponent = ({
  header,
  footer,
  error,
  showErrorResponseMessage,
  hideErrorCode,
  style
}: IProps) => {
  if (isFalsy(error)) return null;

  let errorString = "";
  if (typeof error === "string") {
    errorString = error;
  } else {
    errorString = getErrorMessage(error, {
      showErrorResponseMessage,
      hideErrorCode
    });
  }

  return (
    <ErrorText style={style}>
      <>
        {header}
        {header != undefined && <br />}
        {errorString}
        {footer != undefined && <br />}
        {footer}
      </>
    </ErrorText>
  );
};
export default ErrorComponent;
