import { useMemo } from "react";
import { ComponentHeader } from "../../styling";
import {
  ErrorText,
  TableComponentContainer
} from "../../styling/StyleComponents";
import { useGetTeamsQuery } from "common/services/TeamsService";
import TeamDetails from "./TeamDetails";
import { TableProps } from "../../components/Table/TableTypes";
import ErrorComponent from "../../components/ErrorComponent";
import { useGetLeaderSummaryByLeaderIdQuery } from "common/services/ReportsService";
import { getNameOrUsername } from "common/helpers/helpers";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";

const MyTeam = ({
  externalLink,
  tableColumns = [
    { name: "teamMemberName" },
    { name: "patientsAssigned" },
    // TBD - fix this MTD time entered field on the backend before commenting this back in
    // { name: "myTeamTimeEntered", size: 200 },
    { name: "patientCount", size: 150 },
    { name: "deleteTeamMember" }
  ]
}: TableProps) => {
  const { user } = useSelector((state: RootState) => state.auth);

  const { data, error, isError, isSuccess } =
    useGetLeaderSummaryByLeaderIdQuery(
      {
        team_leader_id: user?.user_id
      },
      { skip: user === undefined }
    );

  const { data: teamData } = useGetTeamsQuery(
    {
      leader_id: user?.user_id
    },
    { skip: user === undefined }
  );

  const team = useMemo(() => {
    if (teamData === undefined || teamData.length === 0 || data === undefined)
      return undefined;

    return {
      ...teamData[0],
      team_members: [...data].sort((a, b) =>
        getNameOrUsername(a.carer).localeCompare(getNameOrUsername(b.carer))
      )
    };
  }, [teamData, data]);

  return (
    <TableComponentContainer>
      <ComponentHeader>My Nurses</ComponentHeader>

      {isError && <ErrorComponent error={error} />}
      {isSuccess && team === undefined && <ErrorText>No team found</ErrorText>}

      {team && data && (
        <TeamDetails
          params={{
            team: team,
            outerContainerStyles: { showBorder: false }
          }}
          externalLink={externalLink}
          tableColumns={tableColumns}
        />
      )}
    </TableComponentContainer>
  );
};

export default MyTeam;
