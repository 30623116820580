import { apiVisits } from "./AxiosService";
import { QueryString_stringify } from "../helpers/QueryStringHelper";
import ReduxTagEnum from "../enums/ReduxTagEnum";
import GetVisitResponseType from "../types/Visits/GetVisitResponseType";
import CreateVisitRequestType from "../types/Visits/CreateVisitRequestType";
import EndVisitResponseType from "../types/Visits/EndVisitResponseType";
import EndVisitRequestType from "../types/Visits/EndVisitRequestType";
import { DateTime } from "luxon";
import appointmentsService from "./AppointmentsService";
import { getNameOrUsername } from "../helpers/helpers";
import VisitDispositionEnum from "../enums/Calendaring/Visits/VisitDispositionEnum";
import { getEncounterTypeForRole } from "../helpers/EncounterHelper";
import {
  CareFlowStateType,
  VisitStateType
} from "../types/Visits/CareFlowStateType";

export const DELAY_AFTER_VISIT_REQUEST_COMPLETED = 3000;

export const visitsService = apiVisits
  .enhanceEndpoints({
    addTagTypes: [ReduxTagEnum.Visits, ReduxTagEnum.EncounterVisits]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Queries
      getVisit: builder.query<GetVisitResponseType, any>({
        query: ({ visit_id }: { visit_id: string }) => {
          return {
            url: `/visits/${visit_id}?with_care_flow=true`,
            method: "GET"
          };
        }
      }),
      getVisits: builder.query<GetVisitResponseType[], any>({
        query: ({
          staff_id,
          patient_id,
          calendar_event_start_after,
          calendar_event_end_before
        }: {
          staff_id?: string;
          patient_id?: string;
          calendar_event_start_after: DateTime;
          calendar_event_end_before: DateTime;
        }) => {
          const params = QueryString_stringify({
            staff_id,
            patient_id,
            calendar_event_start_after: calendar_event_start_after?.toISO(),
            calendar_event_end_before: calendar_event_end_before?.toISO()
          });
          return { url: `/visits?${params}`, method: "GET" };
        },
        providesTags: (result, error, arg) => {
          const arr = [];
          if (result && arg.staff_id) {
            arr.push({ type: ReduxTagEnum.Visits, id: arg.staff_id });
          }
          if (result && arg.patient_id) {
            arr.push({ type: ReduxTagEnum.Visits, id: arg.patient_id });
          }
          return arr;
        }
      }),
      getEncounterVisits: builder.query<any[], any>({
        query: ({
          patient_id,
          submitted_by,
          encounter_starts_on_after,
          encounter_starts_on_before
        }: {
          submitted_by?: string;
          patient_id?: string;
          encounter_starts_on_after?: DateTime;
          encounter_starts_on_before?: DateTime;
        }) => {
          const params = QueryString_stringify({
            patient_id,
            submitted_by,
            encounter_starts_on_after: encounter_starts_on_after?.toISODate(),
            encounter_starts_on_before: encounter_starts_on_before?.toISODate()
          });
          return { url: `/encounter_visit?${params}`, method: "GET" };
        },
        providesTags: (result, error, arg) => {
          const arr = [];

          arr.push({ type: ReduxTagEnum.EncounterVisits });
          if (result && arg.submitted_by) {
            arr.push({ type: ReduxTagEnum.Visits, id: arg.staff_id });
          }
          if (result && arg.patient_id) {
            arr.push({
              type: ReduxTagEnum.EncounterVisits,
              id: arg.patient_id
            });
            arr.push({ type: ReduxTagEnum.Visits, id: arg.patient_id });
          }
          return arr;
        },
        transformResponse: (response: any[]) => {
          // flatten the response to an array of encounters with patient, user, and visit metadata
          const result = response?.map((item) => {
            const displayName = getNameOrUsername(item?.patient);
            if (!item?.encounters) {
              return {
                fullname: displayName,
                encounter: null,
                patient: item?.patient,
                user: item?.user,
                visit: item?.visit,
                // due to recent backend changes encounter may be null. Map this value so we can sort the table
                starts_on:
                  item?.visit?.encounter_started_on ??
                  item?.visit?.created_date,
                time: {
                  // due to recent backend changes encounter may be null. Map this value so we can sort the table
                  duration: item?.visit?.total_time,
                  talk_time: item?.visit?.talk_time,
                  total_time: item?.visit?.total_time
                }
              };
            }

            return item?.encounters?.map((encounter) => {
              return {
                fullname: displayName,
                encounter: encounter,
                patient: item?.patient,
                user: item?.user,
                visit: item?.visit,
                starts_on:
                  item?.visit?.encounter_started_on ??
                  encounter?.starts_on ??
                  item?.visit?.created_date,
                time: {
                  // due to recent backend changes encounter may be null. Map this value so we can sort the table
                  duration: encounter?.duration ?? item?.visit?.total_time,
                  talk_time:
                    // try to use visit talk time first, then encounter duration
                    item?.visit?.talk_time ?? encounter?.duration,
                  total_time:
                    // try to use visit total time first, then encounter duration
                    item?.visit?.total_time ?? encounter?.duration
                }
              };
            });
          });
          const flat = result?.flat();

          // The sorting algorithm in the table was not working as expected.
          // This solution works
          // https://copilotiq.atlassian.net/browse/QA-9?focusedCommentId=15926
          const addType = flat?.map((item) => {
            const type = getEncounterTypeForRole(
              item?.encounter?.reason ?? item?.visit?.visit_type,
              item?.encounter?.submitter_roles ??
                item?.visit?.submitted_by_roles,
              item.starts_on
            );
            return {
              type,
              ...item
            };
          });

          const sort = addType?.sort((a, b) => {
            const dateA = DateTime.fromISO(a?.starts_on);
            const dateB = DateTime.fromISO(b?.starts_on);

            return dateB?.toMillis() - dateA?.toMillis();
          });
          return sort;
        }
      }),
      getVisitNotes: builder.query<{ notes: string }, any>({
        query: ({ visit_id }: { visit_id: string }) => {
          return {
            url: `/visits/${visit_id}/notes`,
            method: "GET"
          };
        }
      }),
      // Mutations
      createVisit: builder.mutation<
        CreateVisitRequestType,
        {
          body: Partial<CreateVisitRequestType>;
          staff_id: string;
          patient_id: string;
          with_care_flow?: boolean;
        }
      >({
        query: ({ body, with_care_flow = false }) => {
          const params = QueryString_stringify({ with_care_flow });
          return {
            url: `/visits?${params}`,
            method: "POST",
            data: body
          };
        },
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;

            const arr = [];

            const appointmentsArr = [];
            if (props.staff_id) {
              arr.push({ type: ReduxTagEnum.Visits, id: props.staff_id });
              appointmentsArr.push({
                type: ReduxTagEnum.Appointments,
                id: props.staff_id
              });
            }
            if (props.patient_id) {
              arr.push({ type: ReduxTagEnum.Visits, id: props.patient_id });
              appointmentsArr.push({
                type: ReduxTagEnum.Appointments,
                id: props.patient_id
              });
            }

            setTimeout(() => {
              dispatch(visitsService.util.invalidateTags(arr));
              dispatch(
                appointmentsService.util.invalidateTags(appointmentsArr)
              );
            }, DELAY_AFTER_VISIT_REQUEST_COMPLETED);
          } catch (error) {}
        }
      }),
      updateVisit: builder.mutation<
        any,
        {
          body: any;
          visit_id: string;
          staff_id?: string;
          patient_id?: string;
        }
      >({
        query: ({ body, visit_id }) => ({
          url: `/visits/${visit_id}`,
          method: "PUT",
          data: body
        }),
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;

            const arr = [];

            const appointmentsArr = [];
            arr.push({ type: ReduxTagEnum.EncounterVisits });
            if (props.staff_id) {
              arr.push({ type: ReduxTagEnum.Visits, id: props.staff_id });
              appointmentsArr.push({
                type: ReduxTagEnum.Appointments,
                id: props.staff_id
              });
            }
            if (props.patient_id) {
              arr.push({ type: ReduxTagEnum.Visits, id: props.patient_id });
              appointmentsArr.push({
                type: ReduxTagEnum.Appointments,
                id: props.patient_id
              });
            }

            // setTimeout(() => {
            dispatch(visitsService.util.invalidateTags(arr));
            dispatch(appointmentsService.util.invalidateTags(appointmentsArr));
            // }, DELAY_AFTER_VISIT_REQUEST_COMPLETED);
          } catch (error) {}
        }
      }),
      updateVisitState: builder.mutation<
        any,
        {
          body: {
            care_flow_state?: CareFlowStateType;
            visit_state?: VisitStateType;
          };
          visit_id: string;
        }
      >({
        query: ({ body, visit_id }) => ({
          url: `/visits/${visit_id}/state`,
          method: "PUT",
          data: body
        })
      }),
      updateVisitDisposition: builder.mutation<
        any,
        {
          disposition: VisitDispositionEnum;
          visit_id: string;
          staff_id?: string;
          patient_id?: string;
        }
      >({
        query: ({ disposition, visit_id }) => ({
          url: `/visits/${visit_id}/disposition`,
          method: "PUT",
          data: {
            disposition
          }
        }),
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;

            const arr = [];

            const appointmentsArr = [];
            arr.push({ type: ReduxTagEnum.EncounterVisits });
            if (props.staff_id) {
              arr.push({ type: ReduxTagEnum.Visits, id: props.staff_id });
              appointmentsArr.push({
                type: ReduxTagEnum.Appointments,
                id: props.staff_id
              });
            }
            if (props.patient_id) {
              arr.push({ type: ReduxTagEnum.Visits, id: props.patient_id });
              appointmentsArr.push({
                type: ReduxTagEnum.Appointments,
                id: props.patient_id
              });
            }

            // setTimeout(() => {
            dispatch(visitsService.util.invalidateTags(arr));
            dispatch(appointmentsService.util.invalidateTags(appointmentsArr));
            // }, DELAY_AFTER_VISIT_REQUEST_COMPLETED);
          } catch (error) {}
        }
      }),
      deleteVisit: builder.mutation<
        any,
        {
          visit_id: string;
          staff_id?: string;
          patient_id?: string;
        }
      >({
        query: ({ visit_id }) => ({
          url: `/visits/${visit_id}`,
          method: "DELETE"
        }),
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;

            const arr = [];

            const appointmentsArr = [];
            arr.push({ type: ReduxTagEnum.EncounterVisits });
            if (props.staff_id) {
              arr.push({ type: ReduxTagEnum.Visits, id: props.staff_id });
              appointmentsArr.push({
                type: ReduxTagEnum.Appointments,
                id: props.staff_id
              });
            }
            if (props.patient_id) {
              arr.push({ type: ReduxTagEnum.Visits, id: props.patient_id });
              appointmentsArr.push({
                type: ReduxTagEnum.Appointments,
                id: props.patient_id
              });
            }

            setTimeout(() => {
              dispatch(visitsService.util.invalidateTags(arr));
              dispatch(
                appointmentsService.util.invalidateTags(appointmentsArr)
              );
            }, DELAY_AFTER_VISIT_REQUEST_COMPLETED);
          } catch (error) {}
        }
      }),
      endVisit: builder.mutation<
        EndVisitResponseType,
        {
          body: EndVisitRequestType;
          visit_id: string;
          staff_id: string;
          patient_id: string;
        }
      >({
        query: ({ body, visit_id }) => ({
          url: `/visits/${visit_id}/end`,
          method: "PUT",
          data: body
        }),
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;

            const arr = [];
            if (props.staff_id) {
              arr.push({ type: ReduxTagEnum.Visits, id: props.staff_id });
            }
            if (props.patient_id) {
              arr.push({ type: ReduxTagEnum.Visits, id: props.patient_id });
            }

            setTimeout(() => {
              dispatch(visitsService.util.invalidateTags(arr));
            }, DELAY_AFTER_VISIT_REQUEST_COMPLETED);
          } catch (error) {}
        }
      }),
      completeVisit: builder.mutation<
        EndVisitResponseType,
        {
          visit_id: string;
        }
      >({
        query: ({ visit_id }) => ({
          url: `/visits/${visit_id}/complete`,
          method: "POST"
        })
      })
    })
  });

export const {
  useGetVisitQuery,
  useGetVisitsQuery,
  useGetVisitNotesQuery,
  useGetEncounterVisitsQuery,
  useCreateVisitMutation,
  useUpdateVisitMutation,
  useUpdateVisitStateMutation,
  useUpdateVisitDispositionMutation,
  useDeleteVisitMutation,
  useEndVisitMutation,
  useCompleteVisitMutation
} = visitsService;
