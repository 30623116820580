import { useState } from "react";

import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import SmsIcon from "@mui/icons-material/Sms";
import { LoadingButton } from "@mui/lab";
import { DateTime } from "luxon";

import MemberType from "common/types/MemberType";
import { useSendJoinVideoCallMessageMutation } from "common/services/MemberEngagementService";
import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";
import { setAnswer, setSelectedSection } from "common/redux/VisitsSlice";
import { RootState, useAppDispatch } from "common/redux";

import { gray } from "../../../styling/colors";
import { callPatientModalSelector } from "../../../helpers/helpers";
import { Phone } from "@mui/icons-material";
import { CustomTooltip } from "../../../styling/StyleComponents";
import { Alert_closeAll, Alert_show } from "common/helpers/AlertHelper";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";

const RowContainer = styled("div")`
  display: flex;
  gap: 10px;
`;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  margin: 10px 0px; 0px; 0px;
`;

interface IProps {
  member: MemberType;
  field: CareFlowFieldType;
  visit: GetVisitResponseType;
}

const CHECKBOX_CONNECTED = "Connected";
const CHECKBOX_NOT_CONNECTED = "Not Connected";

const VisitFieldConnectWithMember = ({ member, field, visit }: IProps) => {
  const dispatch = useAppDispatch();

  const { answers } = useSelector((state: RootState) => state.visits);

  const [sentSmsDate, setSentSmsDate] = useState<DateTime>(null);
  const [step, setStep] = useState<number>(0);
  const [sendJoinVideoCallMessageMutation, { isLoading }] =
    useSendJoinVideoCallMessageMutation();

  const handleSmsText = async () => {
    await sendJoinVideoCallMessageMutation({
      member_id: member?.patient?.patient_id,
      action: "ON_DEMAND_APPOINTMENT_REMINDER"
    });

    setSentSmsDate(DateTime.now());
  };

  return (
    <Container>
      <RowContainer>
        <CustomTooltip title={"Calls the member from Zoom"} placement="top">
          <div>
            <Button
              variant="contained"
              onClick={() => {
                setStep(1);
                callPatientModalSelector(dispatch, member?.patient);
              }}
              startIcon={<Phone />}
              disabled={step !== 0}
            >
              Call
            </Button>
          </div>
        </CustomTooltip>

        <CustomTooltip
          title={
            "Click to automatically send an appointment reminder to the member"
          }
          placement="top"
        >
          <div>
            <LoadingButton
              variant="contained"
              loading={isLoading}
              disabled={step !== 1}
              onClick={() => {
                setStep(2);
                handleSmsText();
              }}
              startIcon={<SmsIcon />}
            >
              Send Text
            </LoadingButton>
          </div>
        </CustomTooltip>

        <CustomTooltip
          title={
            sentSmsDate &&
            `Successfully sent text message ${sentSmsDate.toRelative()}`
          }
          placement="top"
        >
          <div>
            <Button
              variant="contained"
              disabled={step !== 2}
              onClick={() => {
                callPatientModalSelector(dispatch, member?.patient);
              }}
              startIcon={<Phone />}
            >
              Call
            </Button>
          </div>
        </CustomTooltip>
      </RowContainer>

      {sentSmsDate && (
        <>
          <br />
          <Typography
            variant="body1"
            color="green"
          >{`Successfully sent text message ${sentSmsDate.toRelative()}`}</Typography>
        </>
      )}

      <br />
      <Typography variant="body1" color={gray[900]}>
        Did you connect with the member?
      </Typography>

      <RadioGroup
        name="radio-buttons-group"
        value={answers[field.field_id]}
        row
        onChange={(event) => {
          dispatch(
            setAnswer({ id: field.field_id, value: event.target.value })
          );

          if (event.target.value === CHECKBOX_NOT_CONNECTED) {
            Alert_show({
              dispatch,
              title: "Were you not able to connect with the member?",
              content: "If so, confirm below and proceed to the next section",
              size: "small",
              buttons: [
                {
                  text: "Confirm",
                  onPress: () => {
                    Alert_closeAll(dispatch);

                    const newSection = visit?.care_flow?.sections.find(
                      (item) => item.name === "Post-Call"
                    );

                    dispatch(
                      setSelectedSection({
                        section_id: newSection.section_id,
                        subsection_id: newSection.subsections[0].section_id
                      })
                    );
                  }
                },
                {
                  style: "cancel",
                  text: "Cancel",
                  onPress: () => Alert_closeAll(dispatch)
                }
              ]
            });
          }
        }}
      >
        <FormControlLabel
          key={CHECKBOX_CONNECTED}
          value={CHECKBOX_CONNECTED}
          control={<Radio />}
          label={CHECKBOX_CONNECTED}
        />

        <FormControlLabel
          key={CHECKBOX_NOT_CONNECTED}
          value={CHECKBOX_NOT_CONNECTED}
          control={<Radio />}
          label={CHECKBOX_NOT_CONNECTED}
        />
      </RadioGroup>
    </Container>
  );
};

export default VisitFieldConnectWithMember;
