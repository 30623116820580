import { ChangeEvent, useEffect, useState } from "react";
import { TextField, TextFieldProps } from "@mui/material";

interface IProps {
  debounceTimeout?: number;
}

const DebouncedInput = ({
  debounceTimeout = 2000,
  ...props
}: TextFieldProps & IProps) => {
  const { onChange, value } = props;

  const [changeEvent, setChangeEvent] =
    useState<ChangeEvent<HTMLTextAreaElement | HTMLInputElement>>();
  const [debouncedString, setDebouncedString] = useState<string>("");

  useEffect(() => {
    setDebouncedString(value as string);
  }, [value]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (changeEvent === undefined) return;
      onChange && onChange(changeEvent);
    }, debounceTimeout);

    return () => clearTimeout(timeoutId);
  }, [changeEvent, debounceTimeout, onChange]);

  const handleChange = (event) => {
    setChangeEvent(event);
    setDebouncedString(event.target.value);
  };

  return (
    <TextField
      {...props}
      inputProps={{
        // https://stackoverflow.com/a/16528080 OWASP recommendations for minimum max length for passwords
        // we need to set a max length to prevent ReDoS attacks
        // https://owasp.org/www-community/attacks/Regular_expression_Denial_of_Service_-_ReDoS
        maxLength: 128
      }}
      value={debouncedString}
      onChange={handleChange}
    />
  );
};

export default DebouncedInput;
