import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Typography, styled } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

import { DateTime } from "luxon";

import { RootState, useAppDispatch } from "common/redux";
import { setSelectedSection, stopTicking } from "common/redux/VisitsSlice";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import { getNameOrUsername, ONE_MINUTE } from "common/helpers/helpers";
import { VisitStateType } from "common/types/Visits/CareFlowStateType";

import { Modal } from "../../components/Modal";
import VisitContentSubSection from "./ui/VisitContentSubSection";
import { RowContainer } from "../MemberDetails/StartIntake/StyledComponents";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  CareFlowFieldType,
  CareFlowSubSectionType
} from "common/types/Visits/CareFlowResponseType";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Spacing = styled("div")`
  flex: 1;
`;

const ModalContainer = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ContentContainer = styled(Box)`
  ::-webkit-scrollbar {
    display: none;
  }
  overflow-y: scroll;
`;

const VisitContent = ({
  visit,
  syncCareFlowToAPI,
  isUpdateVisitSuccess,
  isUpdateVisitLoading
}: {
  visit: GetVisitResponseType;
  syncCareFlowToAPI: (status?: VisitStateType) => Promise<void>;
  isUpdateVisitSuccess: boolean;
  isUpdateVisitLoading: boolean;
}) => {
  const navigate = useNavigate();

  const { selectedSection, elapsedTime, selectedSubSection, answers } =
    useSelector((state: RootState) => state.visits);

  const [completePressed, setCompletePressed] = useState<boolean>();
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);

  const memberId = visit?.patient_id;
  const { data: member } = useGetMemberWithUsernameQuery(
    {
      username: memberId
    },
    { skip: memberId === undefined }
  );

  const dispatch = useAppDispatch();

  const currentSectionIndex = visit?.care_flow?.sections?.findIndex(
    (item) => item.section_id === selectedSection
  );

  const currentSubSections =
    visit?.care_flow?.sections[currentSectionIndex]?.subsections;
  const currentSubSectionIndex = currentSubSections?.findIndex(
    (item) => item.section_id === selectedSubSection
  );

  const length = visit?.care_flow?.sections?.length;
  const isLastSection = currentSectionIndex >= length - 1;

  const isLastSubsection =
    currentSubSectionIndex >= currentSubSections?.length - 1;

  const section = useMemo(() => {
    return visit?.care_flow?.sections?.find(
      ({ section_id }) => section_id === selectedSection
    );
  }, [selectedSection]);

  const elapsedTotalDateTime = useMemo(() => {
    const totalSeconds =
      elapsedTime?.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue?.actual_time_seconds || 0,
        0
      ) ?? 0;

    return DateTime.fromSeconds(totalSeconds);
  }, [elapsedTime]);

  const goNextSection = () => {
    if (isLastSection) return;

    let firstSubsection: CareFlowSubSectionType = null;
    const unansweredFields: CareFlowFieldType[] = [];
    const currentSection = visit?.care_flow?.sections[currentSectionIndex];
    currentSection.subsections.forEach((subsection) => {
      subsection.fields.forEach((field) => {
        if (field.is_required) {
          const isAnswered = answers[field.field_id] !== undefined;
          if (!isAnswered) {
            unansweredFields.push(field);
            if (!firstSubsection) firstSubsection = subsection;
          }
        }
      });
    });

    if (unansweredFields.length > 0) {
      Alert_show({
        dispatch,
        content: (
          <>
            <Typography variant="h4" textAlign={"center"}>
              The following fields are required to proceed to the next section:
            </Typography>
            {unansweredFields.map((field) => {
              return (
                <Typography
                  key={field.field_id}
                  variant="body1"
                  textAlign={"center"}
                >
                  - {field.name}
                </Typography>
              );
            })}
          </>
        ),
        buttons: [
          {
            text: "Review",
            onPress: () => {
              Alert_close({ dispatch });
              dispatch(
                setSelectedSection({
                  section_id: currentSection.section_id,
                  subsection_id: firstSubsection.section_id
                })
              );
            }
          }
        ]
      });
      return;
    }

    const newSection = visit?.care_flow?.sections[currentSectionIndex + 1];
    dispatch(
      setSelectedSection({
        section_id: newSection.section_id,
        subsection_id: newSection.subsections[0].section_id
      })
    );
  };
  const goNextSubsection = () => {
    if (isLastSubsection) return;

    const subSections =
      visit?.care_flow?.sections[currentSectionIndex].subsections;

    const currentSubSectionIndex = subSections.findIndex(
      (item) => selectedSubSection === item.section_id
    );

    dispatch(
      setSelectedSection({
        section_id: visit?.care_flow?.sections[currentSectionIndex].section_id,
        subsection_id: subSections[currentSubSectionIndex + 1].section_id
      })
    );
  };

  const handleSaveAndNextSection = () => {
    syncCareFlowToAPI();
    goNextSection();
  };

  const handleSaveAndNextSubsection = () => {
    syncCareFlowToAPI();
    goNextSubsection();
  };

  const handleSaveAndNext = () => {
    if (isLastSection && isLastSubsection) return;

    if (isLastSubsection) {
      handleSaveAndNextSection();
    } else {
      handleSaveAndNextSubsection();
    }
  };

  const handleComplete = async () => {
    dispatch(stopTicking());
    setCompletePressed(true);
    await syncCareFlowToAPI({
      status: "COMPLETED",
      total_time: Math.round(elapsedTotalDateTime.toMillis() / (60 * 1000))
    });

    setTimeout(() => setCompletePressed(false), 1000);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      syncCareFlowToAPI();
    }, ONE_MINUTE * 1000);

    return () => {
      syncCareFlowToAPI();
      clearInterval(interval);
    };
  }, []);

  const goToDashboardHandler = () => {
    navigate("/");
  };

  const completeVisitSuccess = isUpdateVisitSuccess && completePressed;
  const completeVisitLoading = isUpdateVisitLoading && completePressed;

  const elapsedTotalTimeModalString = useMemo(() => {
    return elapsedTotalDateTime.toFormat("mm:ss");
  }, [completeVisitSuccess]);

  useEffect(() => {
    if (completeVisitSuccess) setShowCompleteModal(true);
  }, [completeVisitSuccess]);

  useEffect(() => {
    if (section) {
      setSelectedSection({
        section_id: section.section_id,
        subsection_id: section.subsections[0].section_id
      });
    }

    return () => {
      if (section) syncCareFlowToAPI();
    };
  }, [section]);

  const { height } = useWindowDimensions();

  return (
    <Container>
      <ContentContainer height={height - 400}>
        <VisitContentSubSection
          subSection={section?.subsections[currentSubSectionIndex]}
          visit={visit}
        />
      </ContentContainer>
      <br />
      <RowContainer gap={"10px"}>
        <Spacing />

        {isLastSection && isLastSubsection ? (
          <LoadingButton
            onClick={handleComplete}
            loading={completeVisitLoading}
            variant="contained"
          >
            Complete
          </LoadingButton>
        ) : (
          <Button onClick={handleSaveAndNext} variant="contained">
            Next
          </Button>
        )}
      </RowContainer>

      <Modal
        isOpen={showCompleteModal}
        contentLabel="Complete CareFlow Modal"
        modalHeight="300px"
        modalWidth="400px"
      >
        <ModalContainer>
          <Typography variant="h6" color={"secondary"}>
            You have successfully completed your call with
          </Typography>
          <Typography variant="h4">
            {getNameOrUsername(member?.patient)}
          </Typography>
          <Typography variant="h6" color={"secondary"}>
            Total time spent: {elapsedTotalTimeModalString}
          </Typography>
          <Button variant="contained" onClick={goToDashboardHandler}>
            Go to Dashboard
          </Button>
          <Button
            variant="outlined"
            onClick={() => setShowCompleteModal(false)}
          >
            Cancel
          </Button>
        </ModalContainer>
      </Modal>
    </Container>
  );
};

export default VisitContent;
