import {
  CircularProgress,
  MenuItem,
  TextField,
  Typography
} from "@mui/material";
import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";
import { gray } from "../../../styling/colors";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "common/redux";
import { setAnswer, setSelectedSection } from "common/redux/VisitsSlice";
import { useGetLicensedStatesQuery } from "common/services/MemberService";

import STATES from "common/config/states.json";
import { Alert_closeAll, Alert_show } from "common/helpers/AlertHelper";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";

interface IProps {
  field: CareFlowFieldType;
  visit: GetVisitResponseType;
}

const VisitFieldInLicensedState = ({ field, visit }: IProps) => {
  const dispatch = useAppDispatch();
  const { answers } = useSelector((state: RootState) => state.visits);

  const { data, isLoading } = useGetLicensedStatesQuery();

  return (
    <div>
      {isLoading && <CircularProgress />}
      {data && (
        <TextField
          select
          label={field.label}
          placeholder={field.placeholder}
          value={answers[field.field_id] ?? ""}
          sx={{
            width: 300,
            input: {
              color: gray[900]
            }
          }}
          onChange={(event) => {
            const selectedState = STATES.find(
              (item) => item.value === event.target.value
            );

            dispatch(
              setAnswer({ id: field.field_id, value: selectedState.value })
            );

            if (
              data.findIndex((item) => item.state_id === event.target.value) ===
              -1
            ) {
              Alert_show({
                dispatch,
                title: "Is this member's current location?",
                content: (
                  <div>
                    <Typography variant="h4" textAlign={"center"}>
                      {selectedState.fullName}
                    </Typography>
                    <br />
                    <Typography variant="body1" textAlign={"center"}>
                      If so, please confirm below and proceed to the next
                      section.
                    </Typography>
                  </div>
                ),
                size: "small",
                buttons: [
                  {
                    text: "Confirm",
                    onPress: () => {
                      Alert_closeAll(dispatch);

                      const newSection = visit?.care_flow?.sections.find(
                        (item) => item.name === "Post-Call"
                      );

                      dispatch(
                        setSelectedSection({
                          section_id: newSection.section_id,
                          subsection_id: newSection.subsections[0].section_id
                        })
                      );
                    }
                  },
                  {
                    style: "cancel",
                    text: "Cancel",
                    onPress: () => Alert_closeAll(dispatch)
                  }
                ]
              });
            }
          }}
        >
          {STATES.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.value}
            </MenuItem>
          ))}
        </TextField>
      )}
    </div>
  );
};

export default VisitFieldInLicensedState;
