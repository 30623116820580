import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";
import CategoryChip from "../../../components/CategoryChip";
import { Autocomplete, styled, TextField } from "@mui/material";

const StyledAutocomplete = styled(Autocomplete)`
  display: inline-flex;
  width: 300px;
  margin: 10px;
`;

interface IProps {
  field: CareFlowFieldType;
}

const DATA = [
  {
    category: "LIVES_WITH",
    answer: "Son",
    notes: "Son's name is Alejandro",
    last_modified: "2016-05-25T09:08:34.123"
  },
  {
    category: "LIVES_WITH",
    answer: "Son",
    notes: "Son's name is Alejandro",
    last_modified: "2016-05-25T09:08:34.123"
  },
  {
    category: "LIVES_WITH",
    answer: "Son",
    notes: "Son's name is Alejandro",
    last_modified: "2016-05-25T09:08:34.123"
  },
  {
    category: "LIVES_WITH",
    answer: "Son",
    notes: "Son's name is Alejandro",
    last_modified: "2016-05-25T09:08:34.123"
  },
  {
    category: "LIVES_WITH",
    answer: "Son",
    notes: "Son's name is Alejandro",
    last_modified: "2016-05-25T09:08:34.123"
  },
  {
    category: "LIVES_WITH",
    answer: "Son",
    notes: "Son's name is Alejandro",
    last_modified: "2016-05-25T09:08:34.123"
  },
  {
    category: "LIVES_WITH",
    answer: "Son",
    notes: "Son's name is Alejandro",
    last_modified: "2016-05-25T09:08:34.123"
  },
  {
    category: "LIVES_WITH",
    answer: "Son",
    notes: "Son's name is Alejandro",
    last_modified: "2016-05-25T09:08:34.123"
  },
  {
    category: "LIVES_WITH",
    answer: "Son",
    notes: "Son's name is Alejandro",
    last_modified: "2016-05-25T09:08:34.123"
  },
  {
    category: "LIVES_WITH",
    answer: "Son",
    notes: "Son's name is Alejandro",
    last_modified: "2016-05-25T09:08:34.123"
  },
  {
    category: "LIVES_WITH",
    answer: "Son",
    notes: "Son's name is Alejandro",
    last_modified: "2016-05-25T09:08:34.123"
  },
  {
    category: "LIVES_WITH",
    answer: "Son",
    notes: "Son's name is Alejandro",
    last_modified: "2016-05-25T09:08:34.123"
  },
  {
    category: "LIVES_WITH",
    answer: "Son",
    notes: "Son's name is Alejandro",
    last_modified: "2016-05-25T09:08:34.123"
  },
  {
    category: "LIVES_WITH",
    answer: "Son",
    notes: "Son's name is Alejandro",
    last_modified: "2016-05-25T09:08:34.123"
  },
  {
    category: "LIVES_WITH",
    answer: "Son Last",
    notes: "Son's name is Alejandro",
    last_modified: "2016-05-25T09:08:34.123"
  }
];

const AUTOCOMPLETE_DATA = [
  { label: "LIVES_WITH-CHILDREN" },
  { label: "LIVES_WITH-SPOUSE" },
  { label: "LIVES_WITH-ALONE" },
  { label: "LIVES_WITH-NURSING HOME" },
  { label: "LIVES_WITH-EXTENDED FAMILY" },
  { label: "WORK_STATUS-RETIRED" },
  { label: "WORK_STATUS-FULL_TIME" },
  { label: "WORK_STATUS-PART_TIME" },
  { label: "MOBILITY-ACTIVE" },
  { label: "MOBILITY-LIMITED" },
  { label: "PREFERRED_LANGUAGES-EN" },
  { label: "PREFERRED_LANGUAGES-ES" },
  { label: "PREFERRED_LANGUAGES-OTHER" }
];
const VisitFieldMemberRelationshipNotes = ({ field }: IProps) => {
  return (
    <>
      {DATA.map((item, index) => {
        return (
          <CategoryChip
            key={index}
            answer={item.answer}
            category={item.category}
            notes={item.notes}
            last_modified={item.last_modified}
          />
        );
      })}

      <StyledAutocomplete
        disablePortal
        options={AUTOCOMPLETE_DATA}
        renderInput={(params) => (
          <TextField {...params} label="Assign Labels" />
        )}
      />
    </>
  );
};

export default VisitFieldMemberRelationshipNotes;
