import { useEffect, useState } from "react";

import { DateTime } from "luxon";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled
} from "@mui/material";
import DateRangePicker from "./DateRangePicker";

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

interface IProps {
  startDate: DateTime;
  endDate: DateTime;
  onStartDateChange: (startDate: DateTime) => void;
  onEndDateChange: (startDate: DateTime) => void;
  maxDate?: DateTime;
  dateRangeDefault?: string;
}

const DropdownDateRangePicker = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  dateRangeDefault = "7_days",
  maxDate = DateTime.now()
}: IProps) => {
  const [dateRangeSelector, setDateRangeSelector] =
    useState<string>(dateRangeDefault);

  useEffect(() => {
    let days = -1;
    switch (dateRangeSelector) {
      case "today":
        days = 0;
        break;
      case "7_days":
        days = 7;
        break;
      case "30_days":
        days = 30;
        break;
      case "month_to_date":
        // Overrides to month to date.
        days = -1;
        onStartDateChange(DateTime.now().startOf("month"));
        onEndDateChange(DateTime.now().endOf("day"));
        break;
      default:
        days = -1;
        break;
    }
    if (days === 0) {
      onStartDateChange(DateTime.now().minus({ days }).startOf("day"));
      // the backend accepts this value as strictly a date instead of a date time so we want this value to be the next day
      onEndDateChange(DateTime.now().plus({ days: 1 }).startOf("day"));
    } else if (days > 0) {
      onStartDateChange(DateTime.now().minus({ days }).startOf("day"));
      onEndDateChange(DateTime.now().endOf("day"));
    }
  }, [dateRangeSelector]);

  const handleToggleButtonChange = (event: SelectChangeEvent<string>) => {
    setDateRangeSelector(event.target.value);
  };

  return (
    <Row>
      <FormControl sx={{ width: 200 }}>
        <InputLabel id="demo-simple-select-label">Date Range</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={dateRangeSelector}
          label="Date Range"
          onChange={handleToggleButtonChange}
        >
          <MenuItem value={"today"}>Today</MenuItem>
          <MenuItem value={"7_days"}>Last 7 days</MenuItem>
          <MenuItem value={"30_days"}>Last 30 days</MenuItem>
          <MenuItem value={"month_to_date"}>Month to Date</MenuItem>
          <MenuItem value={"custom"}>Custom</MenuItem>
        </Select>
      </FormControl>
      {dateRangeSelector === "custom" && (
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={(date) => onStartDateChange(date.startOf("day"))}
          onEndDateChange={(date) => onEndDateChange(date.endOf("day"))}
          maxDate={maxDate}
        />
      )}
    </Row>
  );
};

export default DropdownDateRangePicker;
