import { DateTime } from "luxon";
import { useEffect } from "react";
import styled from "@emotion/styled";

import { TurqoiseButton, ErrorTextSpan } from "../../../styling";
import {
  Alert_close,
  Alert_loading,
  Alert_show
} from "common/helpers/AlertHelper";
import { getNameOrUsername } from "common/helpers/helpers";
import useRouteConfigByRole from "../../../hooks/useRouteConfigByRole";
import RolesEnum, {
  isIntakeNurseRole,
  isProviderRole,
  isTnRole
} from "common/enums/RolesEnum";
import departmentsData from "../../../config/departmentsData.json";
import {
  NewProviderEncounterReasons,
  arrayHasProviderSubmittedEncounter,
  tnVisitTypeReasons
} from "common/helpers/EncounterHelper";
import LocalizedStrings from "common/localizations/LocalizedStrings";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import { useUpdateMemberCarersMutation } from "common/services/MemberRegistrationService";
import ErrorComponent from "../../../components/ErrorComponent";

import { RootState, useAppDispatch } from "common/redux";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import { useSelector } from "react-redux";
import { Flexbox } from "../../../styling/NewStyleComponents";
import { Box } from "@mui/material";
import { MoreTime } from "@mui/icons-material";
import { StyledSelect } from "../../../components/Table/helpers/TableFilterHelpers";
import NumberInput from "../../../components/Input/NumberInput";
import { useFormik } from "formik";
import VisitReasonsEnum from "common/enums/Calendaring/Visits/VisitReasonsEnum";
import CommunicationTypeEnum from "common/enums/Calendaring/CommunicationTypeEnum";
import {
  useCreateEncounterMutation,
  useGetEncountersQuery
} from "common/services/EncountersService";
// import { useGetEncounterVisitsQuery } from "common/services/VisitsService";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 10px;
`;

const SubmitEncounterFormContainer = styled.div`
  width: 100%;
  margin-top: -2px;
  background: ${(props) => props.theme.color.white};
`;

const ReasonContainer = styled.div``;

const SubmitButton = styled(TurqoiseButton)`
  width: 20px;
`;

const ErrorContainer = styled.div<{
  showError?: string;
}>`
  height: min-content;
  position: fixed;
  visibility: ${(props) =>
    props?.showError === "true" ? "visible" : "hidden"};
`;

const StyledErrorTextSpan = styled(ErrorTextSpan)`
  margin-top: 12px;
`;

const PDBTextSubHeading = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  vertical-align: top;
  display: inline-flex;
  color: ${(props) => props?.theme?.color?.veryDarkBlue};
  margin: 0px 5px;
`;

interface FormikFormValues {
  timeSpent: number | string;
  reason: {
    value: string;
    label: string;
  };
  visitType: {
    value: string;
    label: string;
  };
}

const initialValues: FormikFormValues = {
  timeSpent: "",
  reason: { value: undefined, label: undefined },
  visitType: { value: undefined, label: undefined }
};

interface IProps {
  memberId: string;
  onChange?: (values: FormikFormValues) => void;
  hideSubmitButton?: boolean;
}

const SubmitEncounterForm = ({
  memberId,
  onChange = () => {},
  hideSubmitButton = false
}: IProps) => {
  const dispatch = useAppDispatch();
  const roleConfig = useRouteConfigByRole();
  const currentUserIsProvider = isProviderRole(roleConfig.role);

  const { user } = useSelector((state: RootState) => state.auth);

  const providerEmail = user?.email;

  const departmentId = departmentsData.find(
    (department) => department.Email === providerEmail
  )?.["Department ID"];

  const [
    createEncounterMutation,
    {
      data: createEncounterData,
      isSuccess: createEncounterIsSuccess,
      isLoading: createEncounterLoading,
      error: createEncounterError,
      isError: isCreateEncounterError,
      reset: resetCreateEncounter
    }
  ] = useCreateEncounterMutation();

  useEffect(() => {
    if (createEncounterError && isCreateEncounterError) {
      const id = "createEncounterError";
      Alert_show({
        dispatch,
        id,
        title: "Error",
        content: <ErrorComponent error={createEncounterError} />,
        type: "error",
        size: "small",
        buttons: [
          {
            text: "Close",
            style: "default",
            onPress: () => {
              resetCreateEncounter();
              Alert_close({ dispatch, id });
            }
          }
        ]
      });
    }
  }, [createEncounterError, isCreateEncounterError]);

  useEffect(() => {
    if (createEncounterIsSuccess) {
      const id = "createEncounterSuccess";
      Alert_show({
        dispatch,
        id,
        title: "Success",
        content: "Event successfully created!",
        type: "success",
        size: "small",
        buttons: [
          {
            text: "Close",
            style: "default",
            onPress: () => {
              Alert_close({ dispatch, id });
              resetCreateEncounter();
            }
          }
        ]
      });
    }
  }, [createEncounterIsSuccess]);

  const { data: patient } = useGetMemberWithUsernameQuery({
    username: memberId,
    linked_entities: [
      MemberLinkedEntitiesEnum.PROVIDER_METADATA,
      MemberLinkedEntitiesEnum.PROVIDER
    ]
  });

  const { data: dailyEncounters } = useGetEncountersQuery({
    // useGetEncounterVisitsQuery({
    patient_id: memberId,
    startsAfter: DateTime.now().startOf("day")
    // param for encounter-visit endpoint - comment this back in when we are ready to use this
    // encounter_starts_on_after: DateTime.now().startOf("day")
  });

  const { data: historicalEncounters } = useGetEncountersQuery({
    // useGetEncounterVisitsQuery({
    patient_id: memberId,
    startsAfter: DateTime.fromFormat("2021-01-01", "yyyy-MM-dd").startOf("day")
    // param for encounter-visit endpoint - comment this back in when we are ready to use this
    // encounter_starts_on_after: DateTime.fromFormat(
    //   "2021-01-01",
    //   "yyyy-MM-dd"
    // ).startOf("day")
  });

  const hasProviderSubmittedEncounter =
    arrayHasProviderSubmittedEncounter(historicalEncounters);

  const hasInvalidProviderAssigned = patient?.provider_metadata === undefined;

  const isCurrentProvider =
    user?.user_id === patient?.provider_metadata?.provider_id;

  const showProviderDropdown = isProviderRole(roleConfig.role);

  const showTnDropdown =
    isTnRole(roleConfig.role) ||
    // https://copilotiq.atlassian.net/browse/ENG-5162
    roleConfig.role === RolesEnum.NURSE_DIRECTOR;

  const validateFunc = (values: FormikFormValues) => {
    const errors: { [key: string]: string } = {};
    if (!values.timeSpent) {
      // @ts-ignore
      errors.role = "Required";
    }
    if (!values.reason?.value && showProviderDropdown) {
      errors.reason = "Required";
    }

    if (!values.visitType?.value && showTnDropdown) {
      errors.visitType = "Required";
    }

    return errors;
  };

  async function createEncounterHandler(
    duration: number,
    reason?: string,
    isNotConnected = false
  ) {
    await createEncounterMutation({
      body: {
        patient_id: memberId,
        submitted_by: user?.user_id,
        // don't send disposition
        // disposition: isNotConnected
        //   ? VisitDispositionEnum.NO_SHOW
        //   : // default outcome/disposition to COMPLETED
        //     VisitDispositionEnum.COMPLETED,
        reason,

        starts_on: DateTime.now().toUTC().toISO(),
        // hardcode as phone for TN and IN, NPs will use UpdateOutcomeNurseProviderModal
        // contact_attempts: [{ communication_type: CommunicationTypeEnum.PHONE }],

        modality: CommunicationTypeEnum.PHONE,
        // talk_time: connected === "No" ? 0 : duration,
        duration
      }
    });
    // await endAppointment({
    //   body: {
    //     patient_id: memberId,
    //     // don't send disposition
    //     // disposition: isNotConnected
    //     //   ? VisitDispositionEnum.NO_SHOW
    //     //   : // default outcome/disposition to COMPLETED
    //     //     VisitDispositionEnum.COMPLETED,
    //     visit_type: reason as VisitReasonsEnum,

    //     // hardcode as phone for TN and IN, NPs will use UpdateOutcomeNurseProviderModal
    //     contact_attempts: [{ communication_type: CommunicationTypeEnum.PHONE }],

    //     // talk_time: connected === "No" ? 0 : duration,
    //     total_time: duration
    //   }
    // });
  }

  const [
    updatePatientCarers,
    {
      error: updateProviderError,
      isSuccess: isupdateProviderSuccess,
      isError: isUpdateProviderError
    }
  ] = useUpdateMemberCarersMutation();

  useEffect(() => {
    const id = "updateProviderAssignment";
    if (isupdateProviderSuccess) {
      Alert_close({ dispatch, id });
      resetCreateEncounter();
    } else if (isUpdateProviderError) {
      Alert_close({ dispatch, id });
      resetCreateEncounter();

      // const duration = createEncounterData?.total_time;
      const duration = createEncounterData?.duration;

      if (duration && departmentId) {
        Alert_show({
          dispatch,
          id,
          title: "Update Provider Assignment",
          content: (
            <>
              <div>
                Successfully submitted {duration} minutes for this member.
                <br /> Should you be{" "}
                <b data-testid="usualProviderMessage">
                  set as the usual provider for{" "}
                  {getNameOrUsername(patient.patient)}
                </b>
                {/* */}?
              </div>
              {isUpdateProviderError && (
                // @ts-ignore
                <ErrorComponent error={updateProviderError} />
              )}
            </>
          ),
          type: "warning",
          size: "small",
          buttons: [
            {
              text: LocalizedStrings.cancel,
              style: "cancel",
              onPress: () => {
                Alert_close({ dispatch, id });
                resetCreateEncounter();
              }
            },
            {
              text: LocalizedStrings.submit,
              style: "default",
              onPress: async () => {
                await updateProviderHandler(user?.user_id);
              }
            }
          ]
        });
      }
    }
  }, [isupdateProviderSuccess, updateProviderError]);

  async function updateProviderHandler(provider_id: string) {
    await updatePatientCarers({
      patient_id: memberId,
      carers: {
        provider_id: provider_id
      }
    });
  }

  useEffect(() => {
    if (
      createEncounterIsSuccess &&
      // if the repeated encounter modal is still open
      dailyEncounters != undefined &&
      dailyEncounters.length > 0
    ) {
      // close it
      Alert_close({ dispatch, id: "hasRepeatedEncounter" });
    }
    if (
      createEncounterIsSuccess &&
      currentUserIsProvider &&
      !isCurrentProvider &&
      (!hasProviderSubmittedEncounter || hasInvalidProviderAssigned)
    ) {
      // const duration = createEncounterData?.total_time;
      const duration = createEncounterData?.duration;

      if (duration) {
        const id = "updateProviderAssignment";
        Alert_show({
          dispatch,
          id,
          title: "Update Provider Assignment",
          content: (
            <div>
              Successfully submitted {duration} minutes for this member. Should
              you be{" "}
              <b data-testid="usualProviderMessage">
                set as the usual provider for{" "}
                {getNameOrUsername(patient.patient)}
              </b>
              {/* */}?
            </div>
          ),
          type: "warning",
          size: "medium",
          buttons: [
            {
              text: LocalizedStrings.cancel,
              style: "cancel",
              onPress: () => {
                Alert_close({ dispatch, id });
                resetCreateEncounter();
              }
            },
            {
              text: LocalizedStrings.submit,
              style: "default",
              onPress: async () => {
                Alert_loading({ dispatch, id });
                await updateProviderHandler(user?.user_id);
                Alert_close({ dispatch, id });
                resetCreateEncounter();
              },
              hasLoadingState: true
            }
          ]
        });
      }
    }
  }, [createEncounterIsSuccess, createEncounterData, patient]);

  const onSubmit = async (
    values: FormikFormValues,
    { resetForm }: { resetForm: () => void }
  ) => {
    const duration = +values.timeSpent;

    const reason = values?.reason?.value;

    const visitType = values?.visitType?.value;

    const hasRepeatedEncounter =
      dailyEncounters != undefined && dailyEncounters.length > 0;

    // commenting out this code for now - we will need it again in a follow up ticket

    // const totalDuration = dailyEncounters?.reduce(
    //   (accumulator: number, encounter) => {
    //     let result = accumulator;
    //     if (
    //       typeof encounter?.encounter?.duration === "number" &&
    //       encounter?.encounter?.duration > 0
    //     ) {
    //       result = result + encounter?.encounter?.duration;
    //     } else if (
    //       typeof encounter?.visit?.total_time === "number" &&
    //       encounter?.visit?.total_time > 0
    //     ) {
    //       result = result + encounter?.visit?.total_time;
    //     }
    //     return result;
    //   },
    //   0
    // );

    if (hasRepeatedEncounter) {
      const id = "hasRepeatedEncounter";
      Alert_show({
        dispatch,
        id,
        title: "Create encounter confirmation",
        content: (
          <div>
            <div>
              Member {getNameOrUsername(patient.patient)} already has{" "}
              <b>time entered today</b>.
            </div>
            <div>Are you sure you want to submit this?</div>
          </div>
        ),
        type: "warning",
        size: "medium",
        buttons: [
          {
            text: "Yes, duplicate",
            style: "cancel",
            hasLoadingState: true,
            onPress: async () => {
              Alert_loading({ dispatch, id });
              if (isIntakeNurseRole(roleConfig.role)) {
                // if the user is an intake nurse, then we will always submit an initial visit
                await createEncounterHandler(
                  duration,
                  VisitReasonsEnum.NURSE_INTAKE
                );
              } else if (isTnRole(roleConfig.role)) {
                await createEncounterHandler(duration, visitType, true);
              } else {
                await createEncounterHandler(duration, reason);
              }
              Alert_close({ dispatch, id });
            }
          },
          {
            text: LocalizedStrings.cancel,
            style: "default",
            onPress: () => {
              Alert_close({ dispatch, id });
            }
          }
        ]
      });
    } else if (isIntakeNurseRole(roleConfig.role)) {
      // if the user is an intake nurse, then we will always submit an initial visit
      await createEncounterHandler(duration, VisitReasonsEnum.NURSE_INTAKE);
    } else if (isTnRole(roleConfig.role)) {
      await createEncounterHandler(duration, visitType, true);
    } else {
      await createEncounterHandler(duration, reason);
    }

    resetForm();
  };

  const tnVisitReasons = tnVisitTypeReasons();

  const { setFieldValue, values, handleSubmit, isValid } = useFormik({
    validate: validateFunc,
    initialValues,
    onSubmit
  });

  useEffect(() => {
    onChange(values);
  }, [values]);

  return (
    <Container>
      <Flexbox
        width="100%"
        gap="8px"
        flexDirection={showProviderDropdown ? "column" : "row"}
      >
        <Flexbox gap="4px">
          <SubmitEncounterFormContainer>
            <Box width="max-content">
              <NumberInput
                placeholder="1-60 min"
                id={"timeSpent"}
                name={"timeSpent"}
                data-testid="timeSpentInput"
                label={"Duration"}
                value={values.timeSpent}
                onValueChange={(value) => {
                  setFieldValue("timeSpent", value);
                }}
                min={1}
                max={60}
              />
              {showProviderDropdown && (
                <PDBTextSubHeading>
                  <ReasonContainer key={`reason${memberId}`}>
                    <StyledSelect
                      width="110px"
                      options={NewProviderEncounterReasons}
                      onChange={(e) => setFieldValue("reason", e)}
                      value={values?.["reason"]}
                      placeholder={"Visit Type"}
                    />

                    <ErrorContainer
                      showError={Boolean(
                        !values?.reason?.value && values?.timeSpent
                      ).toString()}
                    >
                      <StyledErrorTextSpan fontSize="14px">
                        Visit type is required
                      </StyledErrorTextSpan>
                    </ErrorContainer>
                  </ReasonContainer>
                </PDBTextSubHeading>
              )}
              {showTnDropdown && (
                <PDBTextSubHeading>
                  <ReasonContainer key={`visitType${memberId}`}>
                    <StyledSelect
                      width="120px"
                      options={tnVisitReasons}
                      onChange={(e) => setFieldValue("visitType", e)}
                      value={values?.["visitType"]}
                      placeholder={"Visit Type"}
                    />

                    <ErrorContainer
                      showError={Boolean(
                        !values?.visitType?.value && values?.timeSpent
                      ).toString()}
                    >
                      <StyledErrorTextSpan fontSize="14px">
                        Required
                      </StyledErrorTextSpan>
                    </ErrorContainer>
                  </ReasonContainer>
                </PDBTextSubHeading>
              )}
              {!hideSubmitButton && (
                <SubmitButton
                  variant="outlined"
                  data-testid="submitButton"
                  loading={createEncounterLoading}
                  sx={{ height: "53px" }}
                  disabled={
                    !values?.timeSpent ||
                    !isValid ||
                    (showProviderDropdown && !values?.reason?.value) ||
                    (showTnDropdown && !values?.visitType?.value) ||
                    createEncounterLoading
                  }
                  onClick={() => handleSubmit()}
                >
                  <MoreTime />
                </SubmitButton>
              )}
            </Box>
          </SubmitEncounterFormContainer>
        </Flexbox>
      </Flexbox>
    </Container>
  );
};

export default SubmitEncounterForm;
