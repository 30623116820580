enum MemberLinkedEntitiesEnum {
  NURSE = "nurse",
  PROVIDER = "provider",
  NPS = "nps",
  SUBMITTER = "submitter",
  PROVIDER_METADATA = "provider_metadata",
  PATIENT_INACTIVITY = "patient_inactivity",
  RETENTION_ACTIONS = "retention_actions",
  LATEST_ATTRITION_REASONS = "latest_attrition_reasons",
  ACCEPTED_CONSENTS = "accepted_consents",
  MISSING_LEGAL_FORMS = "missing_legal_forms",
  MISSING_LEGAL_FORMS_FULL = "missing_legal_forms_full",
  ACCEPTED_LEGAL_FORMS = "accepted_legal_forms",
  PATIENT_ACCESS_SUMMARY = "patient_access_summary",
  LATEST_ENCOUNTER = "latest_encounter",
  METADATA = "metadata"
}

export default MemberLinkedEntitiesEnum;
