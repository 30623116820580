import EnvVars from "./EnvVars";

const websiteUrl =
  EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV === "prod"
    ? "https://copilotiq.com"
    : "https://landing.dev.copilotiq.co";

const APIConstants = {
  APPOINTMENTS: `https://appointment-apis.${EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV}.copilotiq.co`,
  REMOTEIQ: `https://remoteiq-api.${EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV}.copilotiq.co`,
  CALENDAR: `https://calendar-apis.${EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV}.copilotiq.co`,
  PAB: `https://patients-api.${EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV}.copilotiq.co`,
  // PAB: `http://localhost:8089`,
  TASKING: `https://tasking.${EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV}.copilotiq.co`,
  LOGIN: `https://patients-api.${EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV}.copilotiq.co`,
  // LOGIN: `http://localhost:8089`,
  PARTNERS: `https://business-partner.${EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV}.copilotiq.co`,
  DEVICES: `https://devices.${EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV}.copilotiq.co`,
  ORDERS: `https://orders.${EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV}.copilotiq.co`,
  READINGS: `https://readings.${EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV}.copilotiq.co`,
  PATIENT_REGISTRATION: `https://patient-registration.${EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV}.copilotiq.co`,
  PATIENT_CONSENTS: `https://patient-consents.${EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV}.copilotiq.co`,
  TWILIO: `https://twilio-apis.${EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV}.copilotiq.co`,
  PATIENT_DOCUMENTS_S3: `https://patient-documents-s3.${EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV}.copilotiq.co`,
  PATIENT_DOCUMENTS: `https://patient-documents.${EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV}.copilotiq.co`,
  VIDEO: `https://video-apis.${EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV}.copilotiq.co`,
  VISITS: `https://visit-apis.${EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV}.copilotiq.co`,
  PATIENT_ENGAGEMENT: `https://patient-engagement.${EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV}.copilotiq.co`,
  PANEL_MANAGEMENT: `https://panel-management-apis.${EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV}.copilotiq.co`,
  MESSAGING: `https://messaging-apis.${EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV}.copilotiq.co`,
  WEBSITE: websiteUrl
};

export default APIConstants;
