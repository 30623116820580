import { useParams } from "react-router-dom";
import { Typography, styled } from "@mui/material";
import VisitSections from "./VisitSections";
import { RowContainer } from "../MemberDetails/StartIntake/StyledComponents";
import VisitContent from "./VisitContent";

import { checkIdValid } from "common/helpers/helpers";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import {
  useGetVisitQuery,
  useUpdateVisitStateMutation
} from "common/services/VisitsService";
import ErrorComponent from "../../components/ErrorComponent";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import { MemberHeader } from "../MemberDetails/Header/MemberHeader";
import { useEffect, useState } from "react";
import { RootState, useAppDispatch } from "common/redux";
import { initialize } from "common/redux/VisitsSlice";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import { VisitStateType } from "common/types/Visits/CareFlowStateType";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";

const Container = styled("div")`
  position: sticky;
  overflow: hidden;
  margin: 2.5%;
  flex-direction: column;
`;

const VisitFlow = () => {
  const { visitId } = useParams();
  const dispatch = useAppDispatch();

  const isValidId = checkIdValid(visitId);

  const [lastSaved, setLastSaved] = useState<DateTime>(null);

  const {
    answers,
    selectedSection,
    selectedSubSection,
    elapsedTime,
    generatedNotes
  } = useSelector((state: RootState) => state.visits);

  const [
    updateVisitStateMutation,
    { isLoading: isUpdateVisitLoading, isSuccess: isUpdateVisitSuccess }
  ] = useUpdateVisitStateMutation();

  const {
    data: visit,
    isLoading: isVisitLoading,
    error: visitError
  } = useGetVisitQuery(
    {
      visit_id: visitId
    },
    { skip: visitId === undefined || isValidId === false }
  );

  const memberId = visit?.patient_id;
  const {
    data: member,
    isLoading: isMemberLoading,
    error: memberError
  } = useGetMemberWithUsernameQuery(
    {
      username: memberId,
      linked_entities: [
        MemberLinkedEntitiesEnum.NURSE,
        MemberLinkedEntitiesEnum.PROVIDER
      ]
    },
    { skip: memberId === undefined || isValidId === false }
  );

  useEffect(() => {
    if (!visit) return;

    dispatch(initialize(visit));
  }, [visit]);

  const syncCareFlowToAPI = async (visit_state?: VisitStateType) => {
    const fieldValues = Object.entries(answers)?.map((item) => {
      return {
        field_id: item[0],
        value: item[1].toString()
      };
    });

    await updateVisitStateMutation({
      visit_id: visit.visit_id,
      body: {
        visit_state,
        care_flow_state: {
          current_section: selectedSection,
          current_subsection: selectedSubSection,
          time_spent: elapsedTime?.map(
            ({ subsection_id, actual_time_seconds }) => {
              return {
                section_id: subsection_id,
                actual_time_seconds
              };
            }
          ),
          field_values: fieldValues,
          generated_notes: generatedNotes
        }
      }
    });

    setLastSaved(DateTime.now());
  };

  if (!isValidId)
    return (
      <Container>
        <Typography variant="body1">{`Invalid Visit ID ${visitId}`}</Typography>
      </Container>
    );

  return (
    <Container sx={{ margin: "1%" }}>
      {isVisitLoading || isMemberLoading ? (
        <LoadingFallback count={3} />
      ) : (
        <>
          <MemberHeader
            key={`${visit?.patient_id}-header-visits`}
            memberId={visit?.patient_id}
            patient={member}
            isPatientError={memberError}
            hideButtons
            hideStartEncounter
          />
          <br />
          {visit?.care_flow ? (
            <RowContainer gap={"20px"} sx={{ alignItems: "flex-start" }}>
              <VisitSections
                visit={visit}
                isUpdateVisitLoading={isUpdateVisitLoading}
                lastSaved={lastSaved}
              />
              <VisitContent
                visit={visit}
                syncCareFlowToAPI={syncCareFlowToAPI}
                isUpdateVisitSuccess={isUpdateVisitSuccess}
                isUpdateVisitLoading={isUpdateVisitLoading}
              />
            </RowContainer>
          ) : (
            <ErrorComponent
              error={`Visit ID: ${visitId} does not have a careflow`}
            />
          )}
          <ErrorComponent error={visitError} />
        </>
      )}
    </Container>
  );
};

export default VisitFlow;
