import { createSlice } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

interface StateType {
  layoutNodeRef: React.RefObject<HTMLDivElement> | null;
  showSidebar: boolean;
  selectedMemberId: string | undefined;
  notificationCount: number;
  notifications: string[];
  timerStart: DateTime | null;
}

const initialState: StateType = {
  layoutNodeRef: null,
  showSidebar: true,
  selectedMemberId: undefined,
  notificationCount: 0,
  notifications: [],
  timerStart: null
};

export const LayoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setSelectedMemberId: (state, action) => {
      state.selectedMemberId = action.payload;
      state.timerStart = initialState.timerStart;
    },
    toggleSidebar: (state) => {
      state.showSidebar = !state.showSidebar;
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
      state.notificationCount = action.payload.length;
    },
    setLayoutNodeRef: (state, action) => {
      state.layoutNodeRef = action.payload;
    },
    setTimerStart: (state, action) => {
      state.timerStart = action.payload;
    }
  }
});

export const {
  setSelectedMemberId,
  toggleSidebar,
  setNotifications,
  setLayoutNodeRef,
  setTimerStart
} = LayoutSlice.actions;

export default LayoutSlice.reducer;
