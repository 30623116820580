import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { getNameOrUsername } from "common/helpers/helpers";
import { DateTime } from "luxon";
import TaskType from "common/types/TaskType";
import { ApproveFileIcon, LightRedDangerIcon } from "../../assets/images/icons";
import { gray } from "../../styling/colors";
import { useAddActionsToTaskMutation } from "common/services/TaskingService";
import replace from "lodash.replace";
import { MEMBERS_PATH } from "../../routes/RouteComponents";
import TaskTypeEnum from "common/enums/TaskTypeEnum";
import { getAthenaPatientUrl } from "common/helpers/EnvVarsHelper";

const TasksContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "height"
})<{ height?: string }>`
  border-radius: 4px;
  border: 1px solid ${gray[300]};
  margin: 12px 0;
  height: ${(props) => props.height ?? "clamp(300px, 40vh, 500px)"};
  overflow-y: scroll;
  background: #ffffff;
`;

const TaskContainer = styled("div", {
  shouldForwardProp: (prop) => !["backgroundColor"].includes(prop.toString())
})<{ backgroundColor?: string }>`
  position: relative;
  padding: 10px;
  border-bottom: 1px solid ${gray[300]};
  overflow: hidden;
  background: ${(props) => props.backgroundColor ?? "#FFFFFF"};

  :nth-of-type(1) {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  :nth-last-of-type(1) {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

const TaskContactAttemptsContainer = styled("div")`
  font-size: 12px;
  font-weight: 400;
  padding-left: 40px;
`;

const TaskSpan = styled("span", {
  shouldForwardProp: (prop) => prop !== "fontWeight"
})<{ fontWeight: string }>`
  font-style: normal;
  font-size: 14px;
  font-weight: ${(props) => props.fontWeight ?? "400"};
  line-height: 20px;
`;

const PatientLink = styled(Link)`
  color: ${(props) => props.theme.palette.primary.main};
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
`;

const AthenaLink = styled("a")`
  color: ${(props) => props.theme.palette.primary.main};
  cursor: pointer;
  font-weight: 600;
`;

const StyledDangerIcon = styled(LightRedDangerIcon)`
  width: 40px;
  height: 40px;
`;

const StyledApproveFileIcon = styled(ApproveFileIcon)`
  width: 24px;
  height: 24px;
`;

const DATE_FORMAT = "MMM d h:mm a ZZZZ";

function getTaskColor(status: string) {
  switch (status) {
    case "CRITICAL":
      return "#fee4e2";
    case "HIGH":
      return "#FEF3D8";
    default:
      return "#ffffff";
  }
}

function getAlertableReadingText(task: TaskType) {
  const { detail_attributes, type } = task?.task;

  if (type === TaskTypeEnum.BILLING_MISSING_DIAGNOSIS) {
    return (
      <>
        <AthenaLink
          target="_blank"
          href={getAthenaPatientUrl(task?.patient?.athena_id)}
        >
          (#{task?.patient?.athena_id})
        </AthenaLink>{" "}
        is missing diagnosis code. Please resolve once added in Athena.
      </>
    );
  } else if (type === TaskTypeEnum.BILLING_MISSING_INSURANCE) {
    return (
      <>
        <AthenaLink
          target="_blank"
          href={getAthenaPatientUrl(task?.patient?.athena_id)}
        >
          (#{task?.patient?.athena_id})
        </AthenaLink>{" "}
        is missing primary insurance. Please resolve once added in Athena.
      </>
    );
  } else {
    const eventDate = detail_attributes?.EVENT_DATE;
    // this comes back as a string from the API
    const isDelayed = detail_attributes?.DELAYED === "true";
    const superlative = detail_attributes?.READING_SUPERLATIVE;
    const readingType = detail_attributes?.READING_TYPE;

    // blood pressure
    if (readingType === "BLOOD_PRESSURE") {
      let readingDetailsText = `${detail_attributes?.SYSTOLIC}/${detail_attributes?.DIASTOLIC} mmHg`;

      if (superlative === "HIGH") {
        readingDetailsText += ` ${detail_attributes?.PULSE} bpm`;
        return `'s blood pressure is too high! (${readingDetailsText}${
          isDelayed
            ? `, ${DateTime.fromISO(eventDate).setZone("local").toFormat(DATE_FORMAT)}, delayed`
            : `, ${DateTime.fromISO(eventDate).setZone("local").toFormat(DATE_FORMAT)}`
        })`;
      } else {
        return `'s blood pressure is too low! (${readingDetailsText}${
          isDelayed
            ? `, ${DateTime.fromISO(eventDate).setZone("local").toFormat(DATE_FORMAT)}, delayed`
            : `, ${DateTime.fromISO(eventDate).setZone("local").toFormat(DATE_FORMAT)}`
        })`;
      }
    }
    if (readingType === "GLUCOSE") {
      const readingDetailsText = `${detail_attributes?.GLUCOSE} mg/DL`;

      if (superlative === "HIGH") {
        return `'s glucose is too high! (${readingDetailsText}${
          isDelayed
            ? `, ${DateTime.fromISO(eventDate).setZone("local").toFormat(DATE_FORMAT)}, delayed`
            : `, ${DateTime.fromISO(eventDate).setZone("local").toFormat(DATE_FORMAT)}`
        })`;
      } else {
        return `'s glucose is too low! (${readingDetailsText}${
          isDelayed
            ? `, ${DateTime.fromISO(eventDate).setZone("local").toFormat(DATE_FORMAT)}, delayed`
            : `, ${DateTime.fromISO(eventDate).setZone("local").toFormat(DATE_FORMAT)}`
        })`;
      }
    }

    return "";
  }
}

function getTaskText(task: TaskType, taskRead: boolean, assignee_id: string) {
  const name = getNameOrUsername(task?.patient);
  const patientId = task?.task?.patient_id;

  const [addActionsToTaskMutation] = useAddActionsToTaskMutation();
  const link = replace(MEMBERS_PATH, ":memberId", patientId);

  return (
    <TaskSpan fontWeight={taskRead ? "400" : "700"}>
      <PatientLink
        to={link}
        onClick={() => {
          if (!taskRead) {
            addActionsToTaskMutation({
              task_id: task?.task?.task_id,
              body: [
                {
                  type: "READ",
                  performed_by: assignee_id,
                  modified_by: assignee_id
                }
              ],
              assignee_id,
              refreshDataRouteChanged: true
            });
          }
        }}
      >
        {name}
      </PatientLink>
      {getAlertableReadingText(task)}
    </TaskSpan>
  );
}

function hasReadTask(t: TaskType) {
  return DateTime.fromISO(t?.task?.read_date).isValid;
}

export {
  TasksContainer,
  TaskContainer,
  TaskContactAttemptsContainer,
  StyledDangerIcon,
  StyledApproveFileIcon,
  getTaskColor,
  getTaskText,
  hasReadTask
};
