enum AppointmentTypeEnum {
  TELEHEALTH_NURSE_SETUP = "TELEHEALTH_NURSE_SETUP",
  NURSE_FOLLOWUP = "NURSE_FOLLOWUP",
  PROVIDER_FOLLOWUP = "PROVIDER_FOLLOWUP",
  SICK_TIME = "SICK_TIME",
  VACATION = "VACATION",
  PERSONAL_APPOINTMENT = "PERSONAL_APPOINTMENT",
  BREAK_MEAL = "BREAK_MEAL",
  INTERNAL_MEETING = "INTERNAL_MEETING",
  UNKNOWN = "UNKNOWN",
  TRAINING = "TRAINING",
  BEREAVEMENT = "BEREAVEMENT",
  JURY_DUTY = "JURY_DUTY"
}

export default AppointmentTypeEnum;
