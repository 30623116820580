import { useMemo } from "react";
import styled from "@emotion/styled";
import { Card, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { Circle } from "@mui/icons-material";

import MemberType from "common/types/MemberType";
import { getNameOrUsername } from "common/helpers/helpers";
import { useCreateOrderMutation } from "common/services/OrdersService";
import { useGetAthenaMemberDetailsQuery } from "common/services/MemberRegistrationService";
import SkuType from "common/types/SkuType";

import SKUS from "../../config/skuMappings.json";
import { TurqoiseButton } from "../../styling";
import ErrorComponent from "../../components/ErrorComponent";
import { canOrderSwag } from "common/enums/RolesEnum";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "common/redux";
import OrderTypeEnum from "common/enums/OrderTypeEnum";
import OrderType from "common/types/OrderType";
import OrderItemType from "common/types/OrderItemType";
import useGetOrderDevices from "../../hooks/data_loaders/useGetOrderDevices";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import { showProviderCreatedOrderWarning } from "./helpers";
import { Alert_show } from "common/helpers/AlertHelper";

const StyledTextField = styled(TextField)`
  width: 100px;
`;

const StyledButton = styled(TurqoiseButton)`
  width: 400px;
`;

const Row = styled.div`
  width: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px;
`;

const Gap = styled.div`
  flex: 1;
`;

const StyledCard = styled(Card)`
  margin: 10px 40px 0px 0px;
  padding: 12px;
`;

interface ListItemProps {
  item: SkuType;
  value: number;
  onValueChange: (number) => void;
}

interface FormType {
  TM0009: number;
  TT0003: number;
  TL001: number;
  TC0003: number;
  70710034: number;
  "GSG-v1": number;
  //"MUG-1": number;
}

interface IProps {
  patient?: MemberType;
}

const ListItem = ({ item, value, onValueChange }: ListItemProps) => {
  const { description, sku_type, sku } = item;

  return (
    <Row>
      <Circle sx={{ fontSize: 8, marginRight: 2 }} />
      <div>
        <Typography variant="body1">{`${description} `}</Typography>
        <Typography
          variant="body2"
          color={"gray"}
        >{`${sku} (${sku_type})`}</Typography>
      </div>
      <Gap />
      <StyledTextField
        label="Quantity"
        type="number"
        InputProps={{ inputProps: { min: 0, max: 8 } }}
        // This converts value from number to string, because MUI is ignoring the number,
        // and therefore we can set multiple leading zero's on the textfield.
        value={value + ""}
        onChange={(e) => {
          let value = +e.target.value;

          if (value > 8) value = 8;
          if (value < 0) value = 0;

          onValueChange(value);
        }}
      />
    </Row>
  );
};

const SupportOrderForm = ({ patient }: IProps) => {
  const dispatch = useAppDispatch();
  const { glucoseMeter, bloodPressureCuff } = SKUS.DEVICE;

  const { currentRole, user } = useSelector((state: RootState) => state.auth);

  const { data, isFetching } = useGetOrderDevices(patient?.patient?.patient_id);
  const { orders } = data;

  const athenaId = patient?.patient?.external_accounts?.athena;
  const { data: athenaPatient, error: errorAthena } =
    useGetAthenaMemberDetailsQuery(athenaId, {
      skip: athenaId === undefined,
      refetchOnMountOrArgChange: true
    });

  const [
    createSupportOrderMutation,
    {
      isLoading: supportOrderIsLoading,
      error: supportOrderError,
      isSuccess: supportOrderIsSuccess,
      reset: resetSupportOrder,
      error: createOrderError
    }
  ] = useCreateOrderMutation();

  function resetOrders() {
    resetSupportOrder();
  }

  const onSubmit = (values: FormType) => {
    resetOrders();

    const selectedDeviceSkus = Object.keys(values).filter(
      (key) => values[key] > 0 && SKUS.skus[key].sku_type === "DEVICE"
    );

    if (
      showProviderCreatedOrderWarning(currentRole, orders, selectedDeviceSkus)
    ) {
      Alert_show({
        dispatch,
        id: "noDeviceOrder",
        title: "Error",
        content: (
          <>
            You are not able to create this device order because a provider has
            not previously ordered this device(s) in remoteIQ. Please contact
            the assigned provider to have them create the order.
          </>
        ),
        type: "warning",
        size: "small"
      });
      return;
    }

    const { patient_id } = patient.patient;

    let address = patient.patient?.address
      ? // create a mutable copy of the object
        { ...patient.patient.address }
      : null;

    if (address?.street2 === null) {
      address.street2 = "";
    }

    const skuItems = Object.keys(values)
      .map((key) => ({
        sku: key,
        sku_type: SKUS.skus[key].sku_type,
        quantity: values[key]
      }))
      .filter((item) => item.quantity > 0) as OrderItemType[];

    let isRefill = true;

    if (skuItems.findIndex((item) => item.sku_type === "DEVICE") > -1) {
      isRefill = false;
    }

    const { city, postal_code, state, street1, street2 = "" } = athenaPatient;
    const athenaPatientAddress = {
      city,
      country: "US",
      postal_code,
      state,
      street1,
      street2: street2 ?? ""
    };

    let createOrderRequest: OrderType = {
      address: address || athenaPatientAddress,
      patient_id,
      order_type: OrderTypeEnum.SUPPORT,
      ordered_by: user?.user_id,
      items: skuItems
    };

    if (isRefill) {
      createOrderRequest.shipping = {
        shipping_method: "USPS"
      };
      createOrderRequest.order_type = OrderTypeEnum.REFILL;
    }
    createSupportOrderMutation(createOrderRequest);
  };

  const { handleSubmit, values, setFieldValue, isValid, dirty } =
    useFormik<FormType>({
      initialValues: {
        TM0009: 0,
        TT0003: 0,
        TL001: 0,
        TC0003: 0,
        70710034: 0,
        "GSG-v1": 0
        //"MUG-1": 0
      },
      onSubmit
    });

  const handleValueChange = (sku: string, value: number) => {
    setFieldValue(sku, value);
  };

  const listSkus = useMemo(() => {
    let keys = Object.keys(SKUS.skus).map((key) => ({
      sku: key,
      ...SKUS.skus[key]
    }));
    if (!canOrderSwag(currentRole)) {
      keys = keys.filter((key) => !key.sku.includes("MUG"));
    }

    // filter out weight scale from the support order form ENG-6236
    keys = keys.filter((key) => !key.sku.includes("EAN-13"));

    return keys;
  }, [glucoseMeter, bloodPressureCuff]);

  return (
    <form onSubmit={handleSubmit}>
      {errorAthena && (
        <StyledCard>
          <ErrorComponent
            error={{
              message: "Error: Selected patient does not have Athena account."
            }}
          />
        </StyledCard>
      )}
      <br />
      <Typography variant="h3">
        {getNameOrUsername(patient?.patient)}
      </Typography>
      {isFetching ? (
        <LoadingFallback />
      ) : (
        <>
          {listSkus.map((item) => {
            return (
              <ListItem
                key={item.sku}
                item={item}
                value={values[item.sku]}
                onValueChange={(value: number) =>
                  handleValueChange(item.sku, +value)
                }
              />
            );
          })}
        </>
      )}

      <ErrorComponent error={supportOrderError} />
      {supportOrderIsSuccess && (
        <Typography variant="body1" color="green">
          Order created successfully
        </Typography>
      )}

      <ErrorComponent error={createOrderError} />

      <br />
      <StyledButton
        loading={supportOrderIsLoading}
        disabled={
          supportOrderIsSuccess ||
          athenaPatient === undefined ||
          user === undefined ||
          patient === undefined ||
          !dirty ||
          !isValid
        }
        type="submit"
      >
        Submit Order
      </StyledButton>
    </form>
  );
};

export default SupportOrderForm;
