import {
  DashboardIcon,
  HelpIcon,
  PackageIcon,
  PersonIcon
} from "../assets/images/icons";

export const SIDEBAR_SECTIONS = {
  users: "USERS",
  nurses: "NURSES",
  providers: "PROVIDERS",
  members: "MEMBERS",
  retention: "RETENTION",
  inactives: "INACTIVES",
  reports: "REPORTS",
  support: "SUPPORT",
  myDashboard: "DASHBOARD",
  migrate: "MIGRATE"
};

export const SIDEBAR_DASHBOARD = {
  sectionName: SIDEBAR_SECTIONS.myDashboard,
  items: [
    {
      itemName: "Dashboard",
      itemLink: "/",
      ItemIcon: DashboardIcon
    }
  ]
};

export const SIDEBAR_CLINICAL_TEAMS = {
  itemName: "Clinical Teams",
  itemLink: "/all-teams",
  ItemIcon: PersonIcon
};

export const SIDEBAR_MY_MEMBERS = {
  itemName: "My Members",
  itemLink: "/members/nurseId/{{userId}}",
  ItemIcon: PersonIcon
};

export const SIDEBAR_UNASSIGNED_MEMBERS = {
  itemName: "Unassigned",
  itemLink: "/unassigned-members",
  ItemIcon: PersonIcon
};

export const SIDEBAR_ALL_MEMBERS = {
  itemName: "All Members",
  itemLink: "/members",
  ItemIcon: PersonIcon
};

export const SIDEBAR_AWAITING_PROVIDER = {
  itemName: "Awaiting Provider",
  itemLink: "/awaiting-provider",
  ItemIcon: PersonIcon
};

export const SIDEBAR_INACTIVE_MEMBERS = {
  itemName: "Inactive",
  itemLink: "/inactive-members",
  ItemIcon: PersonIcon
};

export const SIDEBAR_ORDERS = {
  itemName: "Orders",
  itemLink: "/orders",
  ItemIcon: PackageIcon
};

export const SIDEBAR_SUPPORT = {
  sectionName: SIDEBAR_SECTIONS.support,
  items: [
    {
      itemName: "Help",
      itemLink: "/help",
      ItemIcon: HelpIcon
    }
  ]
};

export const SIDEBAR_REPORTS = {
  sectionName: SIDEBAR_SECTIONS.reports,
  items: [
    {
      itemName: "Reports",
      itemLink: "/reports",
      ItemIcon: PersonIcon
    }
  ]
};

export const SIDEBAR_NPNS = {
  itemName: "Intake Nurses",
  itemLink: "/nmns",
  ItemIcon: PersonIcon
};

export const SIDEBAR_MEMBER_MANAGEMENT = (isNPS: boolean) => {
  const items = [
    {
      itemName: "Register Member",
      itemLink: "/register-member",
      ItemIcon: PersonIcon
    },
    {
      itemName: "Request Consents",
      itemLink: "/request-consents",
      ItemIcon: PersonIcon
    }
  ];

  if (isNPS) {
    items.push({
      itemName: "My Conversions",
      itemLink: "/my-conversions",
      ItemIcon: PersonIcon
    });
    items.push({
      itemName: "My Registrations",
      itemLink: "/my-registrations",
      ItemIcon: PersonIcon
    });
  }
  return {
    sectionName: SIDEBAR_SECTIONS.members,
    items
  };
};

export const SIDEBAR_INACTIVE_CANCELED_MEMBERS = {
  itemName: "Cancellations History",
  itemLink: "/cancellations-history",
  ItemIcon: PersonIcon
};

export const SIDEBAR_RETENTION = {
  itemName: "Retention",
  itemLink: "/retention/notset",
  ItemIcon: PersonIcon
};

export const SIDEBAR_MIGRATE_MEMBER = {
  sectionName: SIDEBAR_SECTIONS.migrate,
  items: [
    {
      itemName: "Migrate Member",
      itemLink: "/migrate-patient",
      ItemIcon: PersonIcon
    }
  ]
};

export const SIDEBAR_CREATE_TEAM = {
  itemName: "Create Team",
  itemLink: "/create-team",
  ItemIcon: PersonIcon
};
