import { useCallback, useState } from "react";
import { DateTime } from "luxon";
import { CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

import { useGetEncountersQuery } from "common/services/EncountersService";

import Table from "../../components/Table/Table";

import { ComponentHeader } from "../../styling";
import { BackButton } from "../../styling/StyleComponents";
import DateRangePicker from "../../components/DateRangePicker";
import { checkIdValid, getNameOrUsername } from "common/helpers/helpers";
import { useGetUserWithUsernameQuery } from "common/services/UserService";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import useRouteConfigByRole from "../../hooks/useRouteConfigByRole";
import { AssignNursePatientModal } from "../../helpers/components/Forms/AssignNursePatientModal/AssignNursePatientModal";
import { AssignProviderPatientModal } from "../../helpers/components/Forms/AssignProviderPatientModal/AssignProviderPatientModal";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import useSanitizedParams from "../../hooks/useSanitizedParams";
import ErrorComponent from "../../components/ErrorComponent";

const intakesColumns = [
  {
    name: "fullname",
    accessor: "fullname",
    filterEnabled: true,
    filterFn: "fuzzy"
  },
  { name: "status" },
  { name: "nurseAssigned" },
  { name: "phone" },
  { name: "birthdate" },
  {
    name: "date",
    id: "date",
    accessor: "encounter.starts_on",
    header: "Intake Date"
  },
  {
    name: "number",
    accessor: "encounter.duration",
    header: "Duration (min)"
  },
  { name: "patientActions", size: 75 }
];

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e6e7e9;
  border-radius: 4px;
  background: white;
  transition: height 0.66s ease-out;
  overflow-x: auto;
  padding: 30px 40px;
  margin-left: 36px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 20px;
`;

const DAYS = 30;
const TABLE_HEADER = `{{COUNT}} intake encounters. {{INTAKE_SUM}} minutes submitted.`;

interface IProps {
  externalLink: string;
  componentHeader: string;
  linked_entities: MemberLinkedEntitiesEnum[];
}

const Intakes = ({
  externalLink,
  componentHeader,
  linked_entities
}: IProps) => {
  const navigate = useNavigate();
  const roleConfig = useRouteConfigByRole();
  const { user } = useSelector((state: RootState) => state.auth);

  const { userId } = useSanitizedParams();
  const isValidId = userId === undefined ? true : checkIdValid(userId);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [providerModalOpen, setProviderModalOpen] = useState<boolean>(false);
  const [selectedPatientId, setSelectedPatientId] = useState<string>();

  const setSelectedPatientIdCallback = useCallback(
    (value: string) => {
      setSelectedPatientId(value);
    },
    [setSelectedPatientId]
  );

  const setModalStateCallback = (value: string) => {
    if (value === "open") {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  };
  const setProviderModalStateCallback = (value: string) => {
    if (value === "open") {
      setProviderModalOpen(true);
    } else {
      setProviderModalOpen(false);
    }
  };

  const now = DateTime.now();
  const maxDate = now.endOf("day");
  const [startDate, setStartDate] = useState<DateTime>(
    now.minus({ days: DAYS }).endOf("day")
  );
  const [endDate, setEndDate] = useState<DateTime>(maxDate);

  const { data, isSuccess, isLoading, isError, error } = useGetEncountersQuery(
    {
      submitted_by: userId || user?.user_id,
      startsAfter: startDate,
      startsBefore: endDate,
      linked_entities
    },
    { skip: user === undefined || isValidId === false || endDate > maxDate }
  );

  const { data: intakeUser } = useGetUserWithUsernameQuery(
    { username: userId || user?.user_id },
    {
      skip: user === undefined || isValidId === false
    }
  );

  if (!isValidId)
    return (
      <Typography variant="body1">{`Invalid User ID ${userId}`}</Typography>
    );

  return (
    <>
      {userId !== undefined && <BackButton onClick={() => navigate(-1)} />}
      <OuterContainer>
        <ComponentHeader>{`${componentHeader}: ${getNameOrUsername(
          intakeUser?.user
        )}`}</ComponentHeader>
        <br />

        <Row>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={(dateTime: DateTime) => {
              setStartDate(dateTime.startOf("day"));
            }}
            onEndDateChange={(dateTime: DateTime) => {
              if (!(dateTime > maxDate)) {
                setEndDate(dateTime.endOf("day"));
              }
            }}
            // we need to prevent the user from selecting the current date since encounters is not going to be available for the day https://copilotiq.atlassian.net/browse/ENG-5302
            maxDate={maxDate}
          />
        </Row>

        {isSuccess && data && (
          <Table
            tableHeader={TABLE_HEADER}
            tableColumns={intakesColumns}
            data={data}
            tableProps={{
              externalLink,
              roleConfig,
              setModalStateCallback,
              setProviderModalStateCallback,
              setSelectedPatientIdCallback
            }}
          />
        )}
        {isLoading && <CircularProgress />}
        {isError && <ErrorComponent error={error} />}
        <AssignNursePatientModal
          key={`assignnurse_${selectedPatientId}`}
          modalOpen={modalOpen}
          setModalStateCallback={setModalStateCallback}
          selectedPatientId={selectedPatientId}
        />
        <AssignProviderPatientModal
          key={`assignprovider_${selectedPatientId}`}
          modalOpen={providerModalOpen}
          setModalStateCallback={setProviderModalStateCallback}
          selectedPatientId={selectedPatientId}
        />
      </OuterContainer>
    </>
  );
};

export default Intakes;
