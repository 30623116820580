import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { gray } from "./colors";

const NewComponentCardContainer = styled.div`
  width: 100%;
  margin: 24px 48px;
`;

// this thing: https://www.figma.com/file/aOcHqeUbokfucG08hfv1el/User-Interface?type=design&node-id=195-68794&mode=design&t=8ZEZ2fGFFDA5cqs2-0
const BottomBorderToggleStyles = {
  border: "none",
  padding: "11px 0",
  margin: "0 22px 0 0",
  borderRadius: "0",
  borderBottom: "2px inset transparent",
  color: gray[600],
  fontWeight: 700,
  fontSize: "16px",
  fontFamily: "Inter",
  lineHeight: "24px",
  "&.Mui-selected": {
    backgroundColor: "transparent",
    color: "#215D86",
    borderBottom: "2px inset #215D86"
  }
};

const DefaultChipStyles = {
  padding: "0 2px",
  color: gray[600],
  fontWeight: 600,
  fontFamily: "Inter",
  fontSize: "14px",
  lineHeight: "20px"
};

const StyledOutlineButton = styled(Button)`
  border-radius: 4px;
  color: #215d86;
  border: 1px solid #215d86;
  background: #ffffff;

  &:hover {
    border: 1px solid #007ea7;
    background: rgba(0, 126, 167, 0.1);
  }
`;

const StyledOutlineLoadingButton = styled((props) => (
  <LoadingButton {...props} variant="contained">
    {props.children}
  </LoadingButton>
))<{
  loading?: boolean;
}>`
  border-radius: 4px;
  color: #215d86;
  border: 1px solid #215d86;
  background: #ffffff;
  box-shadow: none;

  &:hover {
    border: 1px solid #007ea7;
    background: rgba(0, 126, 167, 0.1);
    box-shadow: none;
  }
  text-transform: none;
`;

const StyledInternalLink = styled(Link)`
  color: #215d86;
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const Flexbox = styled(Box, {
  shouldForwardProp: (prop) =>
    ![
      "gap",
      "alignItems",
      "alignSelf",
      "justifyContent",
      "width",
      "height",
      "flexDirection",
      "margin",
      "flexBasis",
      "flexWrap",
      "padding",
      "fontSize",
      "fontWeight",
      "minHeight",
      "overflow",
      "transitionTime"
    ].includes(prop)
})<{
  gap?: string;
  alignItems?: string;
  alignSelf?: string;
  justifyContent?: string;
  width?: string;
  height?: string;
  flexDirection?: string;
  margin?: string;
  flexBasis?: string;
  flexWrap?: string;
  padding?: string;
  fontSize?: string;
  fontWeight?: string;
  minHeight?: string;
  overflow?: string;
  transitionTime?: number;
}>`
  display: flex;
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.height && `height: ${props.height};`}
  ${(props) => props.minHeight && `min-height: ${props.minHeight};`}
  ${(props) => props.flexDirection && `flex-direction: ${props.flexDirection};`}
  ${(props) => props.alignItems && `align-items: ${props.alignItems};`}
  ${(props) => props.alignSelf && `align-self: ${props.alignSelf};`}
  ${(props) =>
    props.justifyContent && `justify-content: ${props.justifyContent};`}
  ${(props) => props.gap && `gap: ${props.gap};`}
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.flexBasis && `flex-basis: ${props.flexBasis};`}
  ${(props) => props.flexWrap && `flex-wrap: ${props.flexWrap};`}
  ${(props) => props.padding && `padding: ${props.padding};`}
  ${(props) => props.fontSize && `font-size: ${props.fontSize};`}
  ${(props) => props.fontWeight && `font-weight: ${props.fontWeight};`}
  ${(props) => props.overflow && `overflow: ${props.overflow};`}
  ${(props) =>
    `transition: all ${
      props.transitionTime !== undefined ? props.transitionTime : 0.66
    }s ease-out;`}
`;

const CircleOutlinedContainer = styled.div`
  border-radius: 1000px;
  border: 1px solid ${gray[300]};
  background: #f9fafb;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OverviewCard = styled("div")<{
  flexBasis: string;
  background: string;
}>`
  border-radius: 8px;
  border: 1px solid ${gray[300]};
  background: ${({ background }) => background};
  padding: 16px;
  flex: 1 1 ${({ flexBasis }) => flexBasis};
  align-items: center;
  gap: 8px;
`;

export {
  NewComponentCardContainer,
  BottomBorderToggleStyles,
  DefaultChipStyles,
  StyledOutlineButton,
  StyledOutlineLoadingButton,
  StyledInternalLink,
  Flexbox,
  CircleOutlinedContainer,
  OverviewCard
};
