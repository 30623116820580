import { DateTime } from "luxon";
import { DefaultTableCell } from "../../styling/StyleComponents";

const dateCell = ({ getValue, format = "MM/dd/yyyy" }) => {
  return (
    <DefaultTableCell>
      {DateTime.fromSQL(getValue()).toFormat(format)}
    </DefaultTableCell>
  );
};

const defaultCell = ({ getValue }) => {
  const value = getValue();

  const replaced = value?.split("_");
  const capitalized = replaced
    ?.map((text) => {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    })
    .join(" ");

  return (
    <DefaultTableCell>
      {capitalized !== "" ? capitalized : "N/A"}
    </DefaultTableCell>
  );
};

export { dateCell, defaultCell };
