import RolesEnum from "common/enums/RolesEnum";
import {
  ALL_MEMBERS,
  ASSIGNED_MEMBERS,
  AWAITING_PROVIDER,
  INACTIVE_MEMBERS,
  MEMBER_CHART,
  ORDER_DETAILS,
  REPORT_DETAILS,
  REPORT_DETAILS_BY_MEMBER,
  REPORT_LIST,
  MEMBER_CHART_CALENDARING,
  STAFF_DETAILS
} from "../RouteComponents";
import {
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_DASHBOARD,
  SIDEBAR_INACTIVE_MEMBERS,
  SIDEBAR_MY_MEMBERS,
  SIDEBAR_ORDERS,
  SIDEBAR_REPORTS,
  SIDEBAR_SECTIONS,
  SIDEBAR_SUPPORT
} from "../RouteSidebar";
import RouteComponentTypeEnum from "common/enums/RouteComponentTypeEnum";

const NURSE_PROVIDER = {
  label: "Nurse Provider",
  routes: [
    {
      path: "/",
      components: [ALL_MEMBERS({})]
    },
    {
      path: "/nurses/nurseId/:userId",
      components: [{ type: RouteComponentTypeEnum.MEMBER_DETAILS_REDIRECT }]
    },
    STAFF_DETAILS,
    {
      path: "/members",
      components: [ALL_MEMBERS({})]
    },
    {
      path: "/awaiting-provider",
      components: [
        AWAITING_PROVIDER({
          tableColumns: [
            { name: "name" },
            { name: "phone" },
            { name: "birthdate" },
            { name: "nurseAssigned" }
          ]
        })
      ]
    },
    { path: "/inactive-members", components: [INACTIVE_MEMBERS(true)] },
    ...MEMBER_CHART(RolesEnum.NURSE_PROVIDER),
    ...MEMBER_CHART_CALENDARING(),
    {
      path: "/members/nurseId/:nurseId",
      components: [
        ASSIGNED_MEMBERS({
          promiseFunctionNames: ["getOrdersUsingPatientId"],
          tableColumns: [
            {
              name: "name",
              accessor: "patient.fullname",
              size: 180,
              filterEnabled: false,
              filterFn: "fuzzy"
            },
            { name: "status" },
            { name: "phone" },
            { name: "birthdate", size: 130 }
          ],
          componentHeader: "My Members"
        })
      ]
    },
    {
      path: "/orders",
      components: [
        {
          type: "order-devices"
        }
      ]
    },
    {
      path: "/devices/:memberId",
      components: [
        {
          type: "order-devices"
        }
      ]
    },
    ORDER_DETAILS(),
    {
      path: "/my-profile",
      components: [
        {
          type: "profile"
        }
      ]
    },
    {
      path: "/help",
      components: [
        {
          type: "help"
        }
      ]
    },
    REPORT_LIST,
    REPORT_DETAILS,
    REPORT_DETAILS_BY_MEMBER
  ],
  sidebar: [
    SIDEBAR_DASHBOARD,
    {
      sectionName: SIDEBAR_SECTIONS.members,
      items: [
        SIDEBAR_MY_MEMBERS,
        SIDEBAR_ALL_MEMBERS,
        SIDEBAR_INACTIVE_MEMBERS,

        SIDEBAR_ORDERS
      ]
    },
    SIDEBAR_REPORTS,
    SIDEBAR_SUPPORT
  ]
};

export default NURSE_PROVIDER;
