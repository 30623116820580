import RETENTION_SPECIALIST from "./roles/RETENTION_SPECIALIST";
import THN_MANAGER from "./roles/THN_MANAGER";
import NPN_MANAGER from "./roles/NPN_MANAGER";
import TH_NURSE from "./roles/TH_NURSE";
import NP_NURSE from "./roles/NP_NURSE";
import MD_PROVIDER from "./roles/MD_PROVIDER";
import NURSE_PROVIDER from "./roles/NURSE_PROVIDER";
import PROVIDER_MANAGER from "./roles/PROVIDER_MANAGER";
import NURSE_DIRECTOR from "./roles/NURSE_DIRECTOR";
import NPS from "./roles/NPS";
import NPS_MANAGER from "./roles/NPS_MANAGER";
import SALES_DIRECTOR from "./roles/SALES_DIRECTOR";
import ADMIN from "./roles/ADMIN";
import RCM_ADMIN from "./roles/RCM_ADMIN";
import TECHNICAL_SUPPORT from "./roles/TECHNICAL_SUPPORT";
import MEMBER_CARE_SPECIALIST from "./roles/MEMBER_CARE_SPECIALIST";

const config = {
  permission: {
    // Required by Typescript
    PATIENT: {
      label: "Patient",
      routes: [],
      sidebar: []
    },
    RETENTION_SPECIALIST,
    THN_MANAGER,
    NPN_MANAGER,
    TH_NURSE,
    NP_NURSE,
    MD_PROVIDER,
    NURSE_PROVIDER,
    PROVIDER_MANAGER,
    NURSE_DIRECTOR,
    NPS,
    NPS_MANAGER,
    SALES_DIRECTOR,
    ADMIN,
    RCM_ADMIN,
    TECHNICAL_SUPPORT,
    MEMBER_CARE_SPECIALIST
  }
};

export default config;
