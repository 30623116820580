import { useTransition } from "react";
import { FilterFn, Column } from "@tanstack/react-table";
import styled from "@emotion/styled";

import fuzzysort from "fuzzysort";
import { Box, MenuItem, TextField } from "@mui/material";

import { OrderStatuses } from "common/enums/OrderStatusEnum";
import { formatName, isFalsy } from "common/helpers/helpers";
import RolesEnum, { StaffRoles } from "common/enums/RolesEnum";

import OrderTypeEnum from "common/enums/OrderTypeEnum";

import {
  getRoleLabel,
  sanitizeLowercaseString
} from "../../../helpers/helpers";

import { TableColumn } from "../TableTypes";
import DropdownType from "../../../types/DropdownType";
import DebouncedInput from "../../../components/Input/DebouncedInput";
import { blue } from "../../../styling/colors";

const StyledInput = styled(DebouncedInput)`
  background: ${blue[50]};
`;

const FilterContainer = styled.div`
  margin-bottom: 24px;
`;

interface StyledSelectProps {
  options: DropdownType[];
  onChange: (value: DropdownType) => void;
  value: DropdownType | null;
  placeholder: string;
  width: string;
}

export const StyledSelect = ({
  options,
  onChange,
  value,
  placeholder,
  width
}: StyledSelectProps) => {
  return (
    <Box width={width}>
      <TextField
        select
        fullWidth={!isFalsy(width)}
        sx={width && { width: width }}
        value={value?.value || ""}
        label={placeholder}
        data-testid={placeholder}
        id={placeholder}
        defaultValue={""}
        onChange={(event) => {
          const foundItem = options.find(
            (item) => item.value === event.target.value
          );
          onChange(foundItem);
        }}
        // There is a console warning being thrown because of how MUI is implemented
        // https://github.com/mui/material-ui/issues/38869#issuecomment-1792192008
        // This will likely be fixed in MUI v6
        InputLabelProps={{ htmlFor: placeholder }}
        inputProps={{ id: placeholder }}
      >
        {options.map(({ value, label, disabled }) => {
          if (disabled)
            return (
              <MenuItem
                disabled={true}
                style={{ marginLeft: "-8px" }}
                key={value}
                value={value}
                data-testid={label}
              >
                {label}
              </MenuItem>
            );
          return (
            <MenuItem key={value} value={value} data-testid={label}>
              {label}
            </MenuItem>
          );
        })}
      </TextField>
    </Box>
  );
};

export const defaultFilterFn: FilterFn<any> = (row, columnId, value) => {
  const rowValue = row.getValue(columnId);
  // @ts-ignore
  const includesValue = rowValue.includes(value);
  return includesValue;
};

export const orderStatusFilterFn: FilterFn<any> = (row, columnId, value) => {
  const rowValue = row.getValue(columnId);
  return rowValue === value;
};

export const orderTypeFilterFn: FilterFn<any> = (row, columnId, value) => {
  const rowValue = row.getValue(columnId);
  return rowValue === value;
};

export const fuzzyFilterFn: FilterFn<any> = (row, columnId, value) => {
  const rowValue = row.getValue(columnId) as string;

  let multipleWords = false;
  let searchTermArray: string[] = [];

  if (value?.includes(" ")) {
    multipleWords = true;
    const removedExtraSpaces = value?.replace(/\s+/g, " ").trim();
    searchTermArray = removedExtraSpaces?.split(" ");
  }

  if (multipleWords) {
    let isGoodMatch = true;
    if (searchTermArray) {
      searchTermArray.forEach((word) => {
        const result = fuzzysort.single(word, rowValue as string);
        // if any of the search terms are not a good match, then the whole thing is not a good match
        if (!result || result?.score <= -10000) {
          isGoodMatch = false;
        }
      });
    }
    return isGoodMatch;
  } else {
    const result = fuzzysort.single(
      sanitizeLowercaseString(value),
      rowValue as string
    );
    return Boolean(
      rowValue?.includes(sanitizeLowercaseString(value)) ||
        (result?.score && result.score > -10000)
    );
  }
};

export function getFilterFns(columns: TableColumn[]) {
  const finalFilterFns: any = {};

  columns.forEach((column: TableColumn) => {
    const { filterEnabled, name, filterFn } = column;

    if (filterEnabled) {
      switch (filterFn) {
        case "fuzzy": {
          finalFilterFns[name] = fuzzyFilterFn;
          break;
        }
        case "orderStatus": {
          finalFilterFns[name] = orderStatusFilterFn;
          break;
        }
        case "orderType": {
          finalFilterFns[name] = orderTypeFilterFn;
          break;
        }
        default: {
          finalFilterFns[name] = defaultFilterFn;
          break;
        }
      }
    }
  });
  return finalFilterFns;
}

const ALL_OPTION = { value: "All", label: "All" };

function Filter({
  column,
  filterKeys
}: {
  column: Column<any, unknown>;
  filterKeys: string[];
}) {
  const { id } = column;

  const columnFilterValue = column.getFilterValue();
  const [isPending, startTransition] = useTransition();

  if (!filterKeys.includes(id)) return null;

  switch (id) {
    case "roles":
      const options = StaffRoles.sort((a, b) => a.localeCompare(b)).map(
        (role) => {
          return {
            value: role,
            label: getRoleLabel(role as RolesEnum)
          };
        }
      );

      // add as first element
      options.unshift(ALL_OPTION);

      const selectedOption = options.find(
        (option) => option.value === columnFilterValue
      );

      return (
        <FilterContainer>
          <StyledSelect
            width="200px"
            options={options}
            onChange={({ value }: DropdownType) => {
              startTransition(() => {
                if (value === "All") column.setFilterValue(undefined);
                else column.setFilterValue(value);
              });
            }}
            value={selectedOption}
            placeholder={`Filter by ${column.id}`}
          />
        </FilterContainer>
      );

    case "orderType":
      const orderTypeOptions = Object.keys(OrderTypeEnum).map((orderType) => {
        return {
          value: orderType,
          label: formatName(orderType)
        };
      });

      orderTypeOptions.unshift(ALL_OPTION);
      const selectedOrderTypeOption =
        orderTypeOptions.find((option) => {
          return option.value === columnFilterValue;
        }) ?? ALL_OPTION;

      return (
        <StyledSelect
          width="200px"
          options={orderTypeOptions}
          onChange={({ value }: DropdownType) => {
            startTransition(() => {
              if (value === "All") column.setFilterValue(undefined);
              else column.setFilterValue(value);
            });
          }}
          value={selectedOrderTypeOption}
          placeholder={`Filter by Type`}
        />
      );
    case "orderStatus":
      const orderStatusOptions = OrderStatuses.map((status) => {
        return {
          value: status,
          label: formatName(status)
        };
      });

      // add as first element
      orderStatusOptions.unshift(ALL_OPTION);

      let selectedOrderStatusOption =
        orderStatusOptions.find(
          (option) => option.value === columnFilterValue
        ) ?? ALL_OPTION;

      return (
        <FilterContainer>
          <StyledSelect
            width="200px"
            options={orderStatusOptions}
            onChange={({ value }: DropdownType) => {
              startTransition(() => {
                if (value === "All") column.setFilterValue(undefined);
                else column.setFilterValue(value);
              });
            }}
            value={selectedOrderStatusOption}
            placeholder={`Filter by Status`}
          />
        </FilterContainer>
      );

    case "name":
      return (
        <FilterContainer>
          <StyledInput
            debounceTimeout={300}
            value={(columnFilterValue ?? "") as string}
            onChange={(event) =>
              startTransition(() => {
                // @ts-ignore
                column.setFilterValue(event.target.value);
              })
            }
            type="text"
            id="search"
            placeholder={`Search...`}
          />
        </FilterContainer>
      );
    case "fullname":
      return (
        <FilterContainer>
          <StyledInput
            debounceTimeout={300}
            value={(columnFilterValue ?? "") as string}
            onChange={(event) =>
              startTransition(() => {
                // @ts-ignore
                column.setFilterValue(event.target.value);
              })
            }
            type="text"
            id="search"
            placeholder={`Search...`}
          />
        </FilterContainer>
      );
    default:
      return null;
  }
}

export function getFilters(tableHeaders: any[], filterKeys: string[]) {
  const filterComponents: any[] = [];

  filterKeys.forEach((key: string) => {
    const header = tableHeaders.find((header: any) => header.id === key);

    filterComponents.push(
      <Filter key={header.id} column={header.column} filterKeys={filterKeys} />
    );
  });
  return filterComponents;
}
