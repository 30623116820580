import { useMemo } from "react";
import { DateTime } from "luxon";

/**
 * Use this hook to "delay" a button loading state from resolving to complete on success.
 * This is useful for handling data race conditions where the backend needs some time to update.
 * Use this in combination with a setTimeout in onQueryStarted in the relevant apiService.ts file
 * @param {boolean} loadingBoolean
 * @param {number} fulfilledTimeStamp timestamp in milliseconds
 * @param {unknown} data data from RTK
 * @param {number} delay delay in milliseconds. Defaults to 2000 ms
 * @returns {boolean}
 */

function useGetDelayedLoadingBoolean(
  loadingBoolean,
  fulfilledTimeStamp = 0,
  data,
  delay = 2000
) {
  const isLoading = useMemo(() => {
    return (
      loadingBoolean ||
      (data &&
        // add delay to maintain loading state on success
        DateTime.fromMillis(fulfilledTimeStamp).diffNow().milliseconds >
          -1 * delay)
    );
  }, [data, loadingBoolean, fulfilledTimeStamp]);

  return isLoading;
}

export default useGetDelayedLoadingBoolean;
