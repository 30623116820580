import { useEffect } from "react";
import styled from "@emotion/styled";
import { useFormik } from "formik";

import { useChangePasswordMutation } from "common/services/UserService";
import UserType from "common/types/UserType";

import {
  StyledModal,
  ModalBody,
  BodyHeader,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader
} from "../../styling/StyleModal";

import {
  SuccessText,
  TurqoiseButton,
  WhiteButton
} from "../../styling/StyleComponents";
import { PASSWORD_VALIDATION_REGEXP } from "common/helpers/helpers";

import ErrorComponent from "../../components/ErrorComponent";
import PasswordInput from "../../components/Input/PasswordInput";

const SuccessContainer = styled.div`
  transition: height 0.66s ease-out;
  min-height: 28px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface FormType {
  old_password: string;
  new_password: string;
  new_password_repeat: string;
}

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  user: UserType;
}

const ChangePasswordModal = ({ isOpen, onRequestClose, user }: IProps) => {
  const [changePasswordMutation, { isError, isLoading, error, isSuccess }] =
    useChangePasswordMutation();

  const validate = (values: FormType) => {
    const errors = {};

    if (!values["old_password"]) {
      errors["old_password"] = "Required";
    }

    if (!values["new_password"]) {
      errors["new_password"] = "Required";
    } else if (!PASSWORD_VALIDATION_REGEXP.test(values["new_password"])) {
      errors["new_password"] =
        "Password must contain at least 12 characters, one uppercase, one number and one special case character";
    }
    if (!values["new_password_repeat"]) {
      errors["new_password_repeat"] = "Required";
    } else if (values["new_password"] !== values["new_password_repeat"]) {
      errors["new_password_repeat"] = "Passwords don't match.";
    }

    return errors;
  };

  useEffect(() => {
    if (isSuccess) onRequestClose();
  }, [isSuccess]);

  const onSubmit = async (values) => {
    await changePasswordMutation({
      email: user.user.email,
      new_password: values?.["new_password"],
      old_password: values?.["old_password"]
    });
  };

  const { handleSubmit, values, setFieldValue, errors, dirty, isValid } =
    useFormik<FormType>({
      initialValues: {
        old_password: "",
        new_password: "",
        new_password_repeat: ""
      },
      validate,
      onSubmit
    });

  return (
    <StyledModal
      isOpen={isOpen}
      contentLabel="Profile Modal"
      modalHeight="70vh"
      onRequestClose={onRequestClose}
    >
      <Form onSubmit={handleSubmit}>
        <ModalHeader onRequestClose={onRequestClose}>Password</ModalHeader>
        <ModalBody>
          <BodyHeader>
            Changes to your password will be reflected across your Copilot
            account.
          </BodyHeader>

          <div>
            <PasswordInput
              label="Old Password"
              value={values.old_password}
              onChange={(event) =>
                setFieldValue("old_password", event.target.value)
              }
              error={errors["old_password"] !== undefined}
              helperText={errors["old_password"]}
              fullWidth
            />
            <br />
            <br />
            <PasswordInput
              label="New Password"
              value={values.new_password}
              onChange={(event) =>
                setFieldValue("new_password", event.target.value)
              }
              error={errors["new_password"] !== undefined}
              helperText={errors["new_password"]}
              fullWidth
            />
            <br />
            <br />
            <PasswordInput
              label="New Password (Again)"
              value={values.new_password_repeat}
              onChange={(event) =>
                setFieldValue("new_password_repeat", event.target.value)
              }
              error={errors["new_password_repeat"] !== undefined}
              helperText={errors["new_password_repeat"]}
              fullWidth
            />

            <SuccessContainer>
              {isSuccess && (
                <SuccessText
                  margin={"12px 0 0 0"}
                >{`Password changed successfully.`}</SuccessText>
              )}
            </SuccessContainer>

            {isError && (
              <>
                <br />
                <ErrorComponent error={error} />
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <ModalFooterButtons>
            <TurqoiseButton
              loading={isLoading}
              disabled={!dirty || !isValid}
              type="submit"
            >
              Save
            </TurqoiseButton>
            <WhiteButton onClick={onRequestClose}>Cancel</WhiteButton>
          </ModalFooterButtons>
        </ModalFooter>
      </Form>
    </StyledModal>
  );
};

export default ChangePasswordModal;
