import {
  Alert_close,
  Alert_loading,
  Alert_show
} from "common/helpers/AlertHelper";
import { useAppDispatch } from "common/redux";
import { useDeleteCalendarEventMutation } from "common/services/CalendarService";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DELAY_AFTER_REQUEST_COMPLETED } from "../../pages/MemberDetails/Appointments/constants";
import ErrorComponent from "../ErrorComponent";
import RecurrenceUpdateTypeEnum from "common/enums/Calendaring/Appointments/RecurrenceUpdateTypeEnum";
import { BorderColorOutlined, Delete, MoreHoriz } from "@mui/icons-material";
import { Box, Popover, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { Flexbox } from "../../styling/NewStyleComponents";
import { setPtoEvent, setPtoEventId } from "common/redux/PtoSlice";
import LocalizedStrings from "common/localizations/LocalizedStrings";
import { CustomTooltip } from "../../styling/StyleComponents";
import { gray } from "../../styling/colors";

export default function EditDeleteMenu({
  id,
  event,
  staffId,
  setIsEditTimeOffModalOpen,
  refetchCalendarVisits
}) {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
    resetDeleteTimeOff();
  };

  const [
    deleteTimeOff,
    {
      isSuccess: deleteTimeOffSuccess,
      error: deleteTimeOffError,
      reset: resetDeleteTimeOff
    }
  ] = useDeleteCalendarEventMutation({});

  useEffect(() => {
    if (deleteTimeOffSuccess) {
      setTimeout(() => {
        Alert_close({ dispatch, id: "deleteTimeOff" });
        resetDeleteTimeOff();
        if (refetchCalendarVisits) refetchCalendarVisits();
        Alert_show({
          dispatch,
          title: "Time Off Deleted Successfully",
          id: "deleteTimeOffSuccess",
          content: <div>Time Off Deleted Successfully</div>,
          size: "small",
          buttons: [
            {
              text: "Close",
              onPress: () =>
                Alert_close({ dispatch, id: "deleteTimeOffSuccess" })
            }
          ]
        });
      }, DELAY_AFTER_REQUEST_COMPLETED);
    }
  }, [deleteTimeOffSuccess]);

  useEffect(() => {
    if (deleteTimeOffError) {
      Alert_close({ dispatch, id: "deleteTimeOff" });
      resetDeleteTimeOff();
      Alert_show({
        dispatch,
        title: "Error Deleting Time Off",
        id: "deleteTimeOffError",
        content: <ErrorComponent error={deleteTimeOffError} />,
        size: "small",
        buttons: [
          {
            text: "Close",
            onPress: () => Alert_close({ dispatch, id: "deleteTimeOffError" })
          }
        ]
      });
    }
  }, [deleteTimeOffError]);

  async function deleteEventHandler(id: string) {
    await deleteTimeOff({
      event_id: id,
      delete_type: RecurrenceUpdateTypeEnum.ONCE,
      staff_or_team_id: staffId
    });
  }

  const open = Boolean(anchorEl);
  const elementId = open ? "simple-popover" : undefined;

  const now = useMemo(() => DateTime.now(), []);
  const eventHasStarted = useMemo(() => {
    return DateTime.fromISO(event?.visitsRequest?.calendar_event_start) < now;
  }, [now, event]);

  const editOnClick = useCallback(() => {
    if (!eventHasStarted) {
      dispatch(setPtoEventId(id));
      dispatch(setPtoEvent(event));
      setIsEditTimeOffModalOpen(true);
      handleClose();
    }
  }, [eventHasStarted]);

  return (
    <div>
      <MoreHoriz
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        sx={{ color: "black", cursor: "pointer" }}
      />
      <Popover
        id={elementId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        slotProps={{ paper: { sx: { borderRadius: "8px" } } }}
      >
        <Box
          sx={{
            borderRadius: "8px",
            border: "1px solid #D0D5DD",
            cursor: "pointer"
          }}
        >
          {eventHasStarted ? (
            <CustomTooltip
              title={"You cannot edit a PTO event that has already started"}
            >
              <Flexbox
                padding="10px 12px"
                alignItems="center"
                sx={{ borderBottom: "1px solid #D0D5DD" }}
                onClick={editOnClick}
              >
                <BorderColorOutlined
                  sx={{ marginRight: "4px", color: gray[300] }}
                />
                <Typography color={gray[300]}>Edit</Typography>
              </Flexbox>
            </CustomTooltip>
          ) : (
            <Flexbox
              padding="10px 12px"
              alignItems="center"
              sx={{ borderBottom: "1px solid #D0D5DD" }}
              onClick={editOnClick}
            >
              <BorderColorOutlined sx={{ marginRight: "4px" }} />
              <Typography color="text.primary">Edit</Typography>
            </Flexbox>
          )}
          <Flexbox
            padding="10px 12px"
            alignItems="center"
            onClick={() => {
              handleClose();
              const modalId = "deleteTimeOff";
              Alert_show({
                dispatch,
                id: modalId,
                title: "Please confirm",
                content: (
                  <div>
                    Are you sure you want to delete this PTO period?
                    <br />
                    This action cannot be undone.
                  </div>
                ),
                type: "warning",
                size: "medium",
                buttons: [
                  {
                    text: LocalizedStrings.cancel,
                    style: "cancel",
                    onPress: () => {
                      Alert_close({ dispatch, id: modalId });
                      resetDeleteTimeOff();
                    }
                  },
                  {
                    text: LocalizedStrings.submit,
                    style: "default",
                    onPress: async () => {
                      Alert_loading({ dispatch, id: modalId });
                      await deleteEventHandler(id);
                    },
                    hasLoadingState: true
                  }
                ]
              });
            }}
          >
            <Delete sx={{ marginRight: "4px" }} />
            <Typography color="text.primary">Delete</Typography>
          </Flexbox>
        </Box>
      </Popover>
    </div>
  );
}
