import styled from "@emotion/styled";
import useGetPeriodReadings from "common/hooks/useGetPeriodReadings";
import MemberType from "common/types/MemberType";
import { ErrorText } from "../../../styling";
import { WarningAmberOutlined } from "@mui/icons-material";
import { Box, CircularProgress, Typography } from "@mui/material";
import { gray } from "../../../styling/colors";

const DataContainer = styled.div`
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.03em;
  color: rgba(20, 21, 25, 0.9);
`;

const Divider = styled.div`
  width: 1px;
  align-self: stretch;
  background-color: ${gray[300]};
`;

function getComplianceText(
  complianceDays: number | undefined,
  compliancePeriodError: any
) {
  if (compliancePeriodError) {
    return <ErrorText fontSize="12px">Not applicable</ErrorText>;
  }
  const complianceText = complianceDays
    ? `${complianceDays} days remaining`
    : "";
  return complianceText;
}

interface IProps {
  patient: MemberType;
}

const ComplianceBanner = ({ patient }: IProps) => {
  const { patient_id, timezone } = patient.patient;

  const {
    data: compliancePeriod,
    error,
    isLoading: loadingPeriod
  } = useGetPeriodReadings(patient_id, timezone);

  return (
    <Box
      display={"flex"}
      flex={1}
      alignItems={"center"}
      width={"210px"}
      justifyContent={"space-evenly"}
      gap={"10px"}
    >
      {loadingPeriod ? (
        <CircularProgress />
      ) : (
        <>
          <Box
            alignItems={"center"}
            display={"flex"}
            flex={1}
            flexDirection={"column"}
            gap={"5px"}
          >
            <WarningAmberOutlined color="error" />
            <Typography textAlign={"center"} variant="h6">
              Device Usage
            </Typography>
            <Typography textAlign={"center"} variant="body1">
              {getComplianceText(compliancePeriod?.daysRemaining, error)}
            </Typography>
          </Box>
          <Divider />
          <Box
            alignItems={"center"}
            display={"flex"}
            flex={1}
            flexDirection={"column"}
          >
            <DataContainer>
              <span
                style={
                  compliancePeriod?.readingDays <
                  compliancePeriod?.totalReadings
                    ? { color: "#FF5364", fontSize: "34px" }
                    : { color: "green" }
                }
              >
                {compliancePeriod?.readingDays}
              </span>
              /{compliancePeriod?.totalReadings}
            </DataContainer>
            <Typography textAlign={"center"} variant="h6">
              Days with <br /> Readings
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ComplianceBanner;
