import { QueryString_stringify } from "../helpers/QueryStringHelper";
import PageType from "../types/PageType";

import { apiPAB } from "./AxiosService";

import ReduxTagEnum from "../enums/ReduxTagEnum";
import { providesList } from "./ServicesCacheUtils";
import MemberType from "../types/MemberType";
import { UserQueryParams } from "./UserService";
import MemberLinkedEntitiesEnum from "../enums/MemberLinkedEntitiesEnum";
import MemberStatusEnum from "../enums/MemberStatusEnum";
import AcceptedConsentType from "../types/AcceptedConsentType";
import LicensedStateType from "../types/LicensedStateType";
import { getNameOrUsername } from "../helpers/helpers";
import MemberMetadataType from "../types/MemberMetadataType";
import PatientsMetadataResponse from "../types/PatientsMetadataResponse";
import MemberStatusHistoryType from "../types/MemberStatusHistoryType";
import MemberMetadataPreferencesType from "../types/Calendaring/TimePreferences/MemberMetadataPreferencesType";

const providesTags = (result: MemberType[] | undefined) => {
  return providesList<MemberType, ReduxTagEnum.Member>(
    result,
    ReduxTagEnum.Member,
    (item: MemberType) => item.patient.patient_id
  );
};

export const memberService = apiPAB
  .enhanceEndpoints({
    addTagTypes: [
      ReduxTagEnum.Member,
      ReduxTagEnum.LicensedStates,
      ReduxTagEnum.Tasks
    ]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMemberWithUsername: builder.query<
        MemberType,
        { username: string; linked_entities?: MemberLinkedEntitiesEnum[] }
      >({
        query: ({ username, linked_entities }) => {
          const queryParams = QueryString_stringify({
            linked_entities
          });
          return { url: `/patients/${username}?${queryParams}`, method: "GET" };
        },
        providesTags: (result) => [
          { type: ReduxTagEnum.Member, id: result?.patient.patient_id }
        ]
      }),
      getMembersWithQueryParams: builder.query<
        MemberType[],
        PageType &
          UserQueryParams & { linked_entities?: MemberLinkedEntitiesEnum[] }
      >({
        query: ({ size = 350, ...params }) => {
          const queryParams = QueryString_stringify({
            ...params,
            size
          });
          return { url: `/patients?${queryParams}`, method: "GET" };
        },
        providesTags,
        transformResponse: (response: MemberType[]) => {
          const finalResponse = [...response].map((patient) => {
            const displayName = getNameOrUsername(patient.patient);

            let latestContactCreatedAt = undefined;

            const retentionActionsArray = patient?.patient_retention_actions;
            if (retentionActionsArray?.length > 0) {
              const latestContact =
                retentionActionsArray[retentionActionsArray.length - 1];
              latestContactCreatedAt = latestContact?.created_at;
            }

            return {
              ...patient,
              patient: {
                ...patient.patient,
                fullname: displayName
              },
              fullname: displayName,
              latestContactCreatedAt
            };
          });

          return finalResponse;
        }
      }),
      getMemberWithAthenaId: builder.query<
        MemberType[],
        { athenaId: string; linked_entities?: MemberLinkedEntitiesEnum[] }
      >({
        query: ({ athenaId, linked_entities }) => {
          const queryParams = QueryString_stringify({
            athena: athenaId,
            linked_entities
          });
          return { url: `/patients/accounts?${queryParams}`, method: "GET" };
        },
        providesTags
      }),
      getConsentsForMember: builder.query<
        AcceptedConsentType[],
        { patient_id: string }
      >({
        query: ({ patient_id }) => {
          const queryParams = QueryString_stringify({
            patient_id
          });
          return {
            url: `/consents/accepted?${queryParams}`,
            method: "GET"
          };
        },
        providesTags: (result, error, arg) => [
          { type: ReduxTagEnum.Member, id: arg.patient_id }
        ],
        transformResponse: (response: AcceptedConsentType[]) => {
          const finalResponse = [...response].sort((a, b) =>
            a.consent_type.localeCompare(b.consent_type)
          );

          return finalResponse;
        }
      }),
      getLicensedStates: builder.query<LicensedStateType[], void>({
        query: () => {
          return {
            url: `/licensed_states`,
            method: "GET"
          };
        },
        providesTags: () => [{ type: ReduxTagEnum.LicensedStates }],
        transformResponse: (response: LicensedStateType[]) => {
          const finalResponse = [...response].sort((a, b) =>
            a.state_id.localeCompare(b.state_id)
          );

          return finalResponse;
        }
      }),
      getPatientMetadata: builder.query<
        PatientsMetadataResponse,
        Omit<
          {
            memberId: string;
          },
          "id"
        >
      >({
        query: ({ memberId }) => ({
          url: `/patients-metadata/${memberId}`,
          method: "GET"
        }),
        providesTags: (result, error, arg) =>
          result ? [{ type: ReduxTagEnum.Member, id: arg.memberId }] : []
      }),
      getPatientPreferences: builder.query<
        { preferences?: MemberMetadataPreferencesType },
        Omit<
          {
            memberId: string;
          },
          "id"
        >
      >({
        query: ({ memberId }) => ({
          url: `/patients-metadata/${memberId}/preferences`,
          method: "GET"
        }),
        providesTags: (result, error, arg) =>
          result ? [{ type: ReduxTagEnum.Member, id: arg.memberId }] : []
      }),
      getMemberStatusHistory: builder.query<
        MemberStatusHistoryType[],
        Omit<
          {
            memberId: string;
          },
          "id"
        >
      >({
        query: ({ memberId }) => ({
          url: `/patients/${memberId}/statushistory`,
          method: "GET"
        }),
        providesTags: (result, error, arg) =>
          result ? [{ type: ReduxTagEnum.Member, id: arg.memberId }] : []
      }),
      //Mutations
      createRetentionAction: builder.mutation<
        any,
        Omit<
          {
            action_performed: string;
            notes: string;
            patient_id: string;
            patient_status: MemberStatusEnum;
            patient_status_reason: string;
            retention_action_status: string;
            attributes: { [key: string]: string };
          },
          "id"
        >
      >({
        query: ({
          action_performed,
          notes,
          patient_id,
          patient_status_reason,
          attributes,
          retention_action_status,
          patient_status
        }) => ({
          url: `/patient_retention_actions`,
          data: {
            action_performed,
            notes,
            retention_action_status,
            patient_status_reason,
            patient_status,
            attributes,
            patient_id
          },
          method: "POST"
        }),
        invalidatesTags: (result, error, arg) =>
          result ? [{ type: ReduxTagEnum.Member, id: arg.patient_id }] : []
      }),
      updateMemberMetadata: builder.mutation<
        PatientsMetadataResponse,
        Omit<
          {
            memberId: string;
            data: MemberMetadataType;
          },
          "id"
        >
      >({
        query: ({ memberId, data }) => ({
          url: `/patients-metadata/${memberId}`,
          data,
          method: "PUT"
        }),
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;

            const array = [];
            if (props.memberId) {
              array.push({ type: ReduxTagEnum.Member, id: props.memberId });
            }

            setTimeout(() => {
              dispatch(memberService.util.invalidateTags(array));
            }, 500);
          } catch (error) {}
        }
      }),
      updateMemberExternal: builder.mutation<
        PatientsMetadataResponse,
        Omit<
          {
            patient_id: string;
            mobile?: string;
            external?: any;
          },
          "id"
        >
      >({
        query: ({ patient_id, external }) => {
          return {
            url: `/patients/${patient_id}/accounts-external`,
            data: {
              external
            },
            method: "PUT"
          };
        },
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;

            const array = [];
            if (props.patient_id) {
              array.push({ type: ReduxTagEnum.Member, id: props.patient_id });
            }

            setTimeout(() => {
              dispatch(memberService.util.invalidateTags(array));
            }, 500);
          } catch (error) {}
        }
      }),
      updateMemberPreferences: builder.mutation<
        PatientsMetadataResponse,
        Omit<
          {
            memberId: string;
            data: MemberMetadataPreferencesType;
          },
          "id"
        >
      >({
        query: ({ memberId, data }) => ({
          url: `/patients-metadata/${memberId}/preferences`,
          data,
          method: "PUT"
        }),
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;

            const array = [];
            if (props.memberId) {
              array.push({ type: ReduxTagEnum.Member, id: props.memberId });
            }

            setTimeout(() => {
              dispatch(memberService.util.invalidateTags(array));
            }, 500);
          } catch (error) {}
        }
      })
    })
  });

export const {
  useGetMemberWithUsernameQuery,
  useGetMembersWithQueryParamsQuery,
  useGetConsentsForMemberQuery,
  useGetMemberStatusHistoryQuery,
  useGetLicensedStatesQuery,
  useLazyGetMembersWithQueryParamsQuery,
  useLazyGetMemberWithAthenaIdQuery,
  useGetPatientMetadataQuery,
  useGetPatientPreferencesQuery,
  useCreateRetentionActionMutation,
  useUpdateMemberMetadataMutation,
  useUpdateMemberExternalMutation,
  useUpdateMemberPreferencesMutation
} = memberService;
