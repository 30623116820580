import { useCallback, useEffect, useMemo, useState } from "react";
import RolesEnum from "common/enums/RolesEnum";
import {
  HeaderComponent,
  IHeaderProps,
  TableComponentContainer
} from "../../styling/StyleComponents";
import useRouteConfigByRole from "../../hooks/useRouteConfigByRole";
import { TableColumn } from "../../components/Table/TableTypes";
import Table from "../../components/Table/Table";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import {
  useChangeUserStatusMutation,
  useGetUserWithQueryParamsQuery
} from "common/services/UserService";
import ErrorComponent from "../../components/ErrorComponent";

import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import { RootState, useAppDispatch } from "common/redux";
import ResetPasswordModal from "../../components/Modal/ResetPasswordModal";
import useGetAuthenticatedUser from "common/hooks/useGetAuthenticatedUser";
import { isFalsy } from "common/helpers/helpers";
import { useSelector } from "react-redux";

interface IProps extends IHeaderProps {
  userListFilters: {
    roles: RolesEnum[];
  };
  externalLink?: string;
  tableColumns: TableColumn[];
  tableHeader: string;
  filterByAuthenticatedUser: boolean;
}

const StaffList = ({
  userListFilters,
  componentHeader,
  ComponentTooltip,
  tableColumns,
  externalLink,
  tableHeader,
  filterByAuthenticatedUser
}: IProps) => {
  const roleConfig = useRouteConfigByRole();
  const [selectedUsername, setSelectedUsername] = useState<string | undefined>(
    undefined
  );

  const { currentRole } = useSelector((state: RootState) => state.auth);

  const [resetPasswordModalOpen, setResetPasswordModalOpen] =
    useState<boolean>(false);
  const setResetPasswordModalStateCallback = useCallback(
    (val: string) => {
      setResetPasswordModalOpen(val === "open");
    },
    [setResetPasswordModalOpen]
  );

  const dispatch = useAppDispatch();
  const { data: authenticatedUser } = useGetAuthenticatedUser();

  const {
    data: users,
    isLoading,
    error
  } = useGetUserWithQueryParamsQuery(userListFilters);

  const data = useMemo(() => {
    if (isFalsy(users) || isFalsy(authenticatedUser)) return undefined;
    if (filterByAuthenticatedUser) {
      return users.filter(
        (item) => item.team?.leader_id === authenticatedUser.user.user_id
      );
    }
    return users;
  }, [users, filterByAuthenticatedUser, authenticatedUser]);

  const [
    changeUserStatusMutation,
    {
      data: changeUserStatusData,
      isSuccess: isChangeUserStatusSuccess,
      isError: isChangeUserStatusError,
      error: changeUserStatusError
    }
  ] = useChangeUserStatusMutation();

  useEffect(() => {
    if (isChangeUserStatusSuccess && changeUserStatusData) {
      Alert_show({
        dispatch,
        id: "changeUserStatusSuccess",
        title: "Success",
        content: "User status changed successfully",
        type: "success",
        size: "small",
        buttons: [
          {
            text: "Ok",
            style: "default",
            onPress: () => {
              Alert_close({ dispatch, id: "changeUserStatusSuccess" });
            }
          }
        ]
      });
    }
    if (isChangeUserStatusError && changeUserStatusError) {
      Alert_show({
        dispatch,
        id: "changeUserStatusError",
        title: "Error",
        content: <ErrorComponent error={changeUserStatusError} />,
        type: "error",
        size: "small",
        buttons: [
          {
            text: "Close",
            style: "default",
            onPress: () => {
              Alert_close({ dispatch, id: "changeUserStatusError" });
            }
          }
        ]
      });
    }
  }, [
    isChangeUserStatusSuccess,
    isChangeUserStatusError,
    changeUserStatusData,
    changeUserStatusError
  ]);

  return (
    <TableComponentContainer>
      <HeaderComponent
        componentHeader={componentHeader}
        ComponentTooltip={ComponentTooltip}
        key={componentHeader}
      />
      {isLoading && <LoadingFallback delay={500} count={10} />}
      {!isLoading && data && data?.length >= 0 && (
        <Table
          tableHeader={tableHeader}
          tableColumns={tableColumns}
          estimateRowSize={(index: number) => {
            const hasRoleColumn = tableColumns.find(
              (column) => column.name === "roles"
            );
            if (hasRoleColumn) {
              const item = data[index];
              const roleAmount = item?.user?.roles?.length;
              return 45 + 20 * roleAmount;
            } else return 45;
          }}
          tableProps={{
            roleConfig,
            externalLink,
            changeUserStatusMutation,
            setSelectedUsername,
            setResetPasswordModalStateCallback,
            currentRole
          }}
          data={data}
        />
      )}
      {error && <ErrorComponent error={error} />}
      <ResetPasswordModal
        isOpen={resetPasswordModalOpen}
        username={selectedUsername}
        onRequestClose={() => {
          setResetPasswordModalOpen(false);
        }}
      />
    </TableComponentContainer>
  );
};

export default StaffList;
