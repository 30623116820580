import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";

import StartEncounterButton from "../../MemberDetails/Header/StartEncounterButton";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";

interface IProps {
  visit: GetVisitResponseType;
}

const VisitFieldStartEncounter = ({ visit }: IProps) => {
  const memberId = visit.patient_id;

  const { data: patient } = useGetMemberWithUsernameQuery(
    {
      username: memberId,
      linked_entities: [
        MemberLinkedEntitiesEnum.NURSE,
        MemberLinkedEntitiesEnum.PROVIDER,
        MemberLinkedEntitiesEnum.PROVIDER_METADATA,
        MemberLinkedEntitiesEnum.PATIENT_ACCESS_SUMMARY
      ]
    },
    { skip: memberId === undefined }
  );

  return <StartEncounterButton patient={patient} />;
};

export default VisitFieldStartEncounter;
