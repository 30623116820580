import RolesEnum from "common/enums/RolesEnum";
import {
  REGISTER_MEMBER,
  NPS_LIST,
  REQUEST_CONSENTS,
  MEMBER_CHART,
  ALL_TEAMS,
  TEAM_DETAILS,
  REPORT_LIST,
  REPORT_DETAILS,
  REPORT_DETAILS_BY_MEMBER
} from "../RouteComponents";
import {
  SIDEBAR_DASHBOARD,
  SIDEBAR_MEMBER_MANAGEMENT,
  SIDEBAR_REPORTS
} from "../RouteSidebar";
import { TeamTypeEnum } from "common/enums/TeamTypeEnum";

const NPS_MANAGER = {
  label: "NMS Manager",
  routes: [
    { path: "/", components: [REGISTER_MEMBER, NPS_LIST] },
    {
      path: "/my-profile",
      components: [
        {
          type: "profile"
        }
      ]
    },
    { path: "/request-consents", components: [REQUEST_CONSENTS] },
    { path: "/register-member", components: [REGISTER_MEMBER] },
    ...MEMBER_CHART(RolesEnum.NPS_MANAGER),
    ALL_TEAMS({
      types: [TeamTypeEnum.NPS]
    }),
    TEAM_DETAILS,
    REPORT_LIST,
    REPORT_DETAILS,
    REPORT_DETAILS_BY_MEMBER
  ],
  sidebar: [
    SIDEBAR_DASHBOARD,
    SIDEBAR_MEMBER_MANAGEMENT(false),
    SIDEBAR_REPORTS
  ]
};

export default NPS_MANAGER;
