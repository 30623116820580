// @ts-nocheck

import { forwardRef, useEffect, Fragment } from "react";
import { Formik, Form, Field, useFormikContext, FormikValues } from "formik";
import styled from "@emotion/styled";
import { ErrorTextSpan, ErrorText } from "../../../../styling";
import {
  CustomSelectComponent,
  FormLabel
} from "../../../../helpers/components/Forms/FormHelpers";
import { SuccessText } from "../../../../styling/StyleComponents";
import { useUpdateMemberCarersMutation } from "common/services/MemberRegistrationService";

import ErrorComponent from "../../../../components/ErrorComponent";
import DropdownNurseType from "common/types/DropdownNurseType";
import fuzzysort from "fuzzysort";
import { sanitizeLowercaseString } from "../../../helpers";

const AssignNursePatientFormContainer = styled.div`
  padding: 5%;
  width: 90%;
  background: #ffffff;
  max-width: 450px;
`;

const SuccessContainer = styled.div`
  transition: height 0.66s ease-out;
  min-height: 28px;
`;

const ErrorContainer = styled.div`
  transition: height 0.66s ease-out;
  min-height: 28px;
`;

const StyledErrorTextSpan = styled(ErrorTextSpan)`
  margin-top: 12px;
`;

// comment out until /reports is updated
const assignNursePatientDropdownFilter = (
  candidate: { label: string; value: string; data: any },
  input: string
) => {
  const displayName = candidate.data.label.displayLastNameFirst.toLowerCase();

  if (input) {
    let multipleWords = false;
    let searchTermArray: string[] = [];

    if (input.includes(" ")) {
      multipleWords = true;
      const removedExtraSpaces = input?.replace(/\s+/g, " ").trim();
      searchTermArray = removedExtraSpaces?.split(" ");
    }

    if (multipleWords) {
      let isGoodMatch = true;
      if (searchTermArray) {
        searchTermArray.forEach((word) => {
          const result = fuzzysort.single(word, displayName);
          // if any of the search terms are not a good match, then the whole thing is not a good match
          if (!result || result?.score <= -10000) {
            isGoodMatch = false;
          }
        });
      }
      return isGoodMatch;
    } else {
      const result = fuzzysort.single(
        sanitizeLowercaseString(input),
        displayName as string
      );
      return Boolean(
        displayName?.includes(sanitizeLowercaseString(input)) ||
          (result?.score && result.score > -10000)
      );
    }
  }
  return true;
};

const FormikFormWrapper = ({
  isSuccess,
  setFieldValue,
  values,
  errors,
  nursesList,
  updateFormDirty
}) => {
  const { dirty } = useFormikContext();

  useEffect(() => {
    updateFormDirty(dirty);
  }, [dirty]);

  let selectedOption;
  if (values?.["nurseSelection"]) {
    selectedOption = nursesList.find(
      (option) => option.value === values?.["nurseSelection"]?.value
    );
  }

  return (
    <Form>
      <Fragment key={"nurseSelection"}>
        <FormLabel htmlFor={"nurseSelection"}>
          Select a Nurse based on assigned member count
        </FormLabel>
        <Field
          isSearchable={true}
          component={CustomSelectComponent}
          options={nursesList}
          id={"nurseSelection"}
          name={"nurseSelection"}
          filterOption={assignNursePatientDropdownFilter}
          value={{
            label: selectedOption
              ? selectedOption?.label
              : values?.["nurseSelection"],
            value: values?.["nurseSelection"]
          }}
          getOptionLabel={(option) => {
            return (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>{option?.label?.displayLastNameFirst}</div>
                <div>{option?.label?.patient_count}</div>
              </div>
            );
          }}
          onChange={(e) => {
            setFieldValue("nurseSelection", e);
          }}
        ></Field>
        {errors?.["nurseSelection"] && (
          <ErrorContainer>
            <StyledErrorTextSpan>
              {errors?.["nurseSelection"]}
            </StyledErrorTextSpan>
          </ErrorContainer>
        )}
      </Fragment>
      <SuccessContainer>
        {isSuccess && (
          <SuccessText
            margin={"12px 0 0 0"}
          >{`Address updated successfully.`}</SuccessText>
        )}
      </SuccessContainer>
    </Form>
  );
};

interface IProps {
  setModalStateCallback: (val: string, errorMessage?: string) => void;
  selectedPatientId: string | undefined;
  updateFormDirty: (dirty: boolean) => void;
  // comment out until /reports is updated
  nursesList: DropdownNurseType[] | undefined;
  // nursesList: Array<{ label: string; value: string }> | undefined;
  setAssignNursePatientResponseCallback?: (val: any) => void;
  setIsAssignNurseLoading: Dispatch<SetStateAction<boolean>>;
}

const AssignNursePatientForm = forwardRef(
  (
    {
      setModalStateCallback,
      updateFormDirty,
      nursesList,
      selectedPatientId,
      setAssignNursePatientResponseCallback,
      setIsAssignNurseLoading
    }: IProps,
    ref
  ) => {
    const [
      assignNursePatientMutation,
      { isError, error, isSuccess, isLoading }
    ] = useUpdateMemberCarersMutation();

    const validateFunc = () => {
      const errors = {};

      return errors;
    };

    const onSubmit = async (values: FormikValues) => {
      setIsAssignNurseLoading(true);
      const assignNursePatientRequest = {
        patient_id: selectedPatientId,
        carers: {
          nurse_id: values.nurseSelection.value
        }
      };
      const { data, error } = await assignNursePatientMutation(
        assignNursePatientRequest
      );

      if (data && error === undefined) {
        setModalStateCallback("close");
        setAssignNursePatientResponseCallback &&
          setAssignNursePatientResponseCallback(data);
      }

      setIsAssignNurseLoading(false);
    };

    return (
      <AssignNursePatientFormContainer>
        <Formik
          isSuccess={isSuccess}
          validate={validateFunc}
          initialValues={{ nurseSelection: "" }}
          onSubmit={onSubmit}
          innerRef={ref}
          enableReinitialize
        >
          {({ setFieldValue, values, errors, touched }) => {
            return (
              <FormikFormWrapper
                updateFormDirty={updateFormDirty}
                nursesList={nursesList}
                setFieldValue={setFieldValue}
                values={values}
                errors={errors}
              />
            );
          }}
        </Formik>
        {isError && error?.status === 409 && (
          <ErrorText>Patient already associated with nurse.</ErrorText>
        )}
        {isError && error?.status !== 409 && <ErrorComponent error={error} />}
      </AssignNursePatientFormContainer>
    );
  }
);

export default AssignNursePatientForm;
