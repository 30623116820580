import { useEffect, useRef, useState } from "react";
import { Button, Typography, useTheme } from "@mui/material";
import MemberType from "common/types/MemberType";

import { AssignNursePatientModal } from "../../../helpers/components/Forms/AssignNursePatientModal/AssignNursePatientModal";

import { AssignProviderPatientModal } from "../../../helpers/components/Forms/AssignProviderPatientModal/AssignProviderPatientModal";
import styled from "@emotion/styled";
import { CustomTooltip } from "../../../styling/StyleComponents";
import { CheckCircleIcon, LinkIcon } from "../../../assets/images/icons";
import { copyToClipboard } from "../../../styling/CopyPatientLinkToClipboard";
import { Flexbox } from "../../../styling/NewStyleComponents";
import { isFalsy, maskPhoneNumber } from "common/helpers/helpers";
import EnvVars from "common/config/EnvVars";
import CarersMenuNew from "./CarersMenuNew";

const BUTTON_WIDTH = "136px";

const Row = styled("div")`
  display: flex;
  gap: 8px;
`;

const StyledLinkIcon = styled(LinkIcon, {
  shouldForwardProp: (prop) => prop !== "copiedToClipboard"
})<{ copiedToClipboard: boolean }>`
  // position: absolute;
  margin-left: 4px;
  display: ${(props) => (props.copiedToClipboard ? "none" : "block")};
  cursor: pointer;
  width: 16px;
  height: 16px;
`;

const StyledCheckCircleIcon = styled(CheckCircleIcon, {
  shouldForwardProp: (prop) => prop !== "copiedToClipboard"
})<{ copiedToClipboard: boolean }>`
  // position: absolute;
  margin-left: 4px;
  width: 16px;
  height: 16px;
  display: ${(props) => (props.copiedToClipboard ? "block" : "none")};
  transition: display 0.66s ease-in-out;
`;

const DisplayCarerName = ({
  theme,
  name,
  showCopy = false,
  zoomPhone = "",
  id = ""
}) => {
  const formattedPhone = maskPhoneNumber(zoomPhone);
  const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false);

  const timerRef = useRef(null);

  useEffect(() => {
    if (copiedToClipboard) {
      timerRef.current = setTimeout(() => {
        setCopiedToClipboard(false);
      }, 1000);
    }
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [copiedToClipboard]);

  return (
    <Typography
      color={theme.palette.grey[600]}
      variant="body2"
      overflow="hidden"
      textOverflow="ellipsis"
      noWrap
    >
      <Flexbox justifyContent="center" gap="2px" data-testid={id}>
        {showCopy && zoomPhone && (
          <span style={{ width: "20px", height: "20px" }}>
            <StyledLinkIcon
              copiedToClipboard={copiedToClipboard}
              onClick={async (e) => {
                let stuffToCopy = name;
                if (!isFalsy(formattedPhone)) {
                  stuffToCopy += ` ${formattedPhone}`;
                }
                await copyToClipboard(stuffToCopy);
                setCopiedToClipboard(true);
              }}
            />
            <StyledCheckCircleIcon copiedToClipboard={copiedToClipboard} />
          </span>
        )}
        {/* ?? checks for null or undefined
        || checks for "falsy" values
        in this case, name is first being parsed and set by the getNameOrUsername function to be "" which satisfies || but not ??
        In most other cases, ?? would work when we are rendering a value directly from the backend response */}
        {name || "N/A"}
        {showCopy && zoomPhone && <>&nbsp;{formattedPhone}</>}
      </Flexbox>
    </Typography>
  );
};

export default function CarersMenu(props: {
  patient: MemberType;
  isEditProviderAllowed: boolean;
  isEditNurseAllowed: boolean;
  providerName: string;
  nurseName: string;
  nurseZoomPhone: string | undefined;
}) {
  const {
    patient,
    isEditNurseAllowed,
    isEditProviderAllowed,
    providerName,
    nurseName,
    nurseZoomPhone
  } = props;

  // FEATURE FLAG: AUG-2024-CARE-FLOWS
  if (EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV === "dev") {
    return (
      <CarersMenuNew
        patient={patient}
        isEditProviderAllowed={isEditProviderAllowed}
        isEditNurseAllowed={isEditNurseAllowed}
        providerName={providerName}
        nurseName={nurseName}
        nurseZoomPhone={nurseZoomPhone}
      />
    );
  }
  const theme = useTheme();

  const [editProviderModalOpen, setEditProviderModalOpen] =
    useState<boolean>(false);
  const [editNurseModalOpen, setEditNurseModalOpen] = useState<boolean>(false);

  return (
    <Row>
      <CustomTooltip
        backgroundColor="#ffffff"
        placement="bottom"
        title={<DisplayCarerName theme={theme} name={providerName} />}
      >
        <div>
          <Button
            sx={{ fontSize: "20px" }}
            variant="outlined"
            color="primary"
            id="provider-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={() => setEditProviderModalOpen(true)}
            style={{
              display: "block",
              textAlign: "center",
              textTransform: "none",
              width: BUTTON_WIDTH
            }}
            disabled={!isEditProviderAllowed}
          >
            <Typography color={theme.palette.text.primary} variant="h6">
              Provider
            </Typography>
            <DisplayCarerName
              theme={theme}
              name={providerName}
              id="providerName"
            />
          </Button>
        </div>
      </CustomTooltip>

      <CustomTooltip
        backgroundColor="#ffffff"
        placement="bottom"
        title={
          <DisplayCarerName
            theme={theme}
            name={nurseName}
            showCopy={true}
            zoomPhone={nurseZoomPhone}
          />
        }
      >
        <div>
          <Button
            sx={{ fontSize: "20px" }}
            variant="outlined"
            color="primary"
            id="nurse-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={() => setEditNurseModalOpen(true)}
            style={{
              display: "block",
              textAlign: "center",
              textTransform: "none",
              width: BUTTON_WIDTH
            }}
            disabled={!isEditNurseAllowed}
          >
            <Typography color={theme.palette.text.primary} variant="h6">
              Nurse
            </Typography>
            <DisplayCarerName theme={theme} name={nurseName} id="nurseName" />
          </Button>
        </div>
      </CustomTooltip>

      {isEditProviderAllowed && (
        <AssignProviderPatientModal
          modalOpen={editProviderModalOpen}
          setModalStateCallback={() => setEditProviderModalOpen(false)}
          selectedPatientId={patient.patient.patient_id}
        />
      )}

      {isEditNurseAllowed && (
        <AssignNursePatientModal
          modalOpen={editNurseModalOpen}
          // tbd modify this and assignNursePatientModal
          setModalStateCallback={(val) => setEditNurseModalOpen(val === "open")}
          selectedPatientId={patient.patient.patient_id}
        />
      )}
    </Row>
  );
}
