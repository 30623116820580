import { useState } from "react";
import { styled } from "@mui/material/styles";

import { getErrorMessage } from "common/helpers/helpers";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import useGetAuthenticatedUser from "common/hooks/useGetAuthenticatedUser";

import ProfileForm from "./ProfileForm";
import { SuccessText, ErrorText, Section } from "../../styling/StyleComponents";

import ChangePasswordModal from "../../components/Modal/ChangePasswordModal";
import { Box, Button, Typography } from "@mui/material";
import useSanitizedParams from "../../hooks/useSanitizedParams";

const ProfileContainer = styled("div")`
  margin: 2.5%;
`;

const ModalTrigger = styled("div")`
  display: flex;
  height: 62px;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #dadcdf;
  border-bottom: 1px solid #dadcdf;
`;
const ModalTriggerContent = styled("div")`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ModalTriggerKey = styled("div")`
  width: 25%;
`;
const ModalTriggerValue = styled("div")``;

const Profile = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const { memberId: selectedUserId } = useSanitizedParams();

  const [changedPassword, setChangedPassword] = useState<boolean>(false);

  const isEditProfile = selectedUserId !== undefined;

  const authenticatedUserResponse = useGetAuthenticatedUser();
  const patientResponse = useGetMemberWithUsernameQuery(
    {
      username: selectedUserId
    },
    { skip: isEditProfile === false }
  );

  const user = authenticatedUserResponse?.data;

  const response =
    selectedUserId === undefined ? authenticatedUserResponse : patientResponse;

  const { isError, error, isSuccess } = response;

  const openModal = () => {
    setChangedPassword(false);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleErrorMessage = (error) => {
    switch (error?.response?.status) {
      case 404:
        return (
          "Request patient not found. ErrorCode: " + error?.response?.status
        );
      default: {
        return getErrorMessage(error);
      }
    }
  };

  return (
    <ProfileContainer>
      {isError ? (
        <ErrorText>{handleErrorMessage(error)}</ErrorText>
      ) : (
        <>
          {isSuccess && <ProfileForm user={user?.user} />}
          {isEditProfile === false && user && (
            <Section>
              <Box display={"flex"} alignItems={"center"}>
                <Typography flex={1} variant="h3" color="text.primary">
                  Security Info
                </Typography>
                <Button onClick={() => openModal()}>Edit Password</Button>
              </Box>
              <br />
              <ModalTrigger aria-label="openModal">
                <ModalTriggerContent>
                  <ModalTriggerKey>Password</ModalTriggerKey>
                  <ModalTriggerValue>********</ModalTriggerValue>
                  {changedPassword && (
                    <SuccessText margin={"12px 0 0 0"}>
                      Password changed successfully
                    </SuccessText>
                  )}
                </ModalTriggerContent>
              </ModalTrigger>
            </Section>
          )}
          <ChangePasswordModal
            isOpen={modalOpen}
            onRequestClose={() => closeModal()}
            user={user}
          />
        </>
      )}
    </ProfileContainer>
  );
};

export default Profile;
