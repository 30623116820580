import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import styled from "@emotion/styled";
import { DateTime } from "luxon";

import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import { RootState } from "common/redux";

import {
  BackButton,
  ComponentHeader,
  TurqoiseButton
} from "../../styling/StyleComponents";
import SearchPatientModal from "../../components/Modal/SearchPatientModal/SearchPatientModal";
import ReportSelector from "./report_types/ReportSelector";
import DateRangePicker from "../../components/DateRangePicker";
import DropdownType from "../../types/DropdownType";

import ReportTypes from "./ReportTypes";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select
} from "@mui/material";
import useSanitizedParams from "../../hooks/useSanitizedParams";
import MemberDetailsHeader from "../MemberDetails/Header/MemberDetailsHeader";
import ReportEnum from "../../enums/ReportEnum";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 85px;
`;

const ButtonContainer = styled.div`
  width: 120px;
`;

const SelectorContainer = styled.div`
  max-width: 500px;
`;

const ReportPage = () => {
  const params = useSanitizedParams();
  const reportId = params["reportId"];
  const paramsPatientId = params?.["memberId"];
  const navigate = useNavigate();

  const { currentRole } = useSelector((state: RootState) => state.auth);

  const reportType = useMemo(() => {
    return ReportTypes.filter((report) =>
      report.allowedRoles.includes(currentRole)
    ).find((report) => report.id === reportId);
  }, [currentRole, reportId]);

  const [isSearchPatientModalOpen, setSearchPatientModalOpen] =
    useState<boolean>(false);
  const [memberId, setMemberId] = useState<string>(paramsPatientId);

  const { data: patient } = useGetMemberWithUsernameQuery(
    { username: memberId! },
    { skip: memberId === undefined }
  );

  const now = DateTime.now();
  const [startDate, setStartDate] = useState<DateTime>(
    now
      .startOf("day")
      .minus({ days: reportType?.defaultDatePickerRangeDays ?? 30 })
  );
  const [endDate, setEndDate] = useState<DateTime>(now.endOf("day"));

  const [dropdownSelection, setDropdownSelection] = useState<DropdownType[]>(
    []
  );
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);

  const handleOnChange = (event) => {
    const newValue = event.target.value as string[];

    const length = newValue.length;
    const last = newValue[length - 1];
    const index = dropdownSelection.findIndex((item) => item.value === last);
    if (index >= 0) {
      // Exists => Deleting
      dropdownSelection.splice(index, 1);
      setDropdownSelection([...dropdownSelection]);
    }
    // Does not exist => Adding
    else
      setDropdownSelection([
        ...dropdownSelection,
        reportType.dropdown.find((item) => item.value === last)
      ]);
  };

  const handleCheckboxChange = (event) => {
    setCheckboxChecked(event.target.checked);
  };

  return (
    <div style={{ margin: "36px" }}>
      {paramsPatientId && (
        <BackButton containerMargin="36px 0" onClick={() => navigate(-1)} />
      )}
      {reportType?.id === ReportEnum.MEMBER_CHART_SHARE &&
        patient &&
        // add this condition to handle the case when the user navigates to the report page and searches for a member
        paramsPatientId && (
          <Box mb="32px">
            <MemberDetailsHeader key={`${paramsPatientId}-chart-share`} />
          </Box>
        )}
      <ComponentHeader>{reportType?.title}</ComponentHeader>
      <br />
      <Row>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={(dateTime: DateTime) => {
            if (dateTime?.isValid) {
              setStartDate(dateTime.startOf("day"));
            }
          }}
          onEndDateChange={(dateTime: DateTime) => {
            if (dateTime?.isValid) {
              setEndDate(dateTime.endOf("day"));
            }
          }}
          maxDate={DateTime.now()}
        />
      </Row>
      {reportType?.userRequired && !paramsPatientId && (
        <ButtonContainer>
          <TurqoiseButton
            sx={{ marginTop: 1 }}
            onClick={() => setSearchPatientModalOpen(true)}
          >
            Select Member
          </TurqoiseButton>
        </ButtonContainer>
      )}
      <br />
      {reportType?.dropdown !== undefined && (
        <SelectorContainer>
          <FormControl fullWidth>
            <InputLabel id="demo-multiple-chip-label">
              {reportType.dropdownProps.placeholder}
            </InputLabel>
            <Select
              {...reportType.dropdownProps}
              value={dropdownSelection}
              onChange={handleOnChange}
              input={
                <OutlinedInput
                  id="select-multiple-chip"
                  label={reportType.dropdownProps.placeholder}
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map(({ value, label }) => (
                    <Chip key={value} label={label} />
                  ))}
                </Box>
              )}
            >
              {reportType.dropdown.map(({ value, label }) => {
                return (
                  <MenuItem key={value} value={value}>
                    <Checkbox
                      checked={
                        dropdownSelection.findIndex(
                          (item) => item.value === value
                        ) > -1
                      }
                    />
                    <ListItemText primary={label} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <br />
          <br />
        </SelectorContainer>
      )}

      {reportType?.checkbox && (
        <>
          {reportType.checkboxProps.label}
          <Checkbox
            checked={checkboxChecked}
            onChange={handleCheckboxChange}
            inputProps={{ "aria-label": "controlled" }}
          />
          <br />
          <br />
        </>
      )}

      <ReportSelector
        patient={patient}
        paramsPatientId={paramsPatientId}
        startDate={startDate}
        endDate={endDate}
        dropdownSelection={dropdownSelection}
        checkboxChecked={checkboxChecked}
        reportType={reportType}
      />

      <SearchPatientModal
        isOpen={isSearchPatientModalOpen}
        handleClose={() => setSearchPatientModalOpen(false)}
        handleSelectedUser={(memberId) => setMemberId(memberId)}
      />
    </div>
  );
};

export default ReportPage;
