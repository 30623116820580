import { useCallback, useEffect, useMemo, useState } from "react";
import { Autocomplete, Box, MenuItem } from "@mui/material";

import { StyledTextField } from "../../../helpers/components/Forms/FormHelpers";
import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  StyledModal
} from "../../../styling/StyleModal";
import { TurqoiseButton, WhiteButton } from "../../../styling";
import { formatName, isFalsy } from "common/helpers/helpers";
import { useCreateCalendarEventMutation } from "common/services/CalendarService";
import { v4 as uuidv4 } from "uuid";
import AppointmentTypeEnum from "common/enums/Calendaring/Appointments/AppointmentTypeEnum";
import { RootState } from "common/redux";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import ErrorComponent from "../../../components/ErrorComponent";
import { DELAY_AFTER_REQUEST_COMPLETED } from "../../MemberDetails/Appointments/constants";
import { LoadingButton } from "@mui/lab";
import useGetDelayedLoadingBoolean from "common/hooks/useGetDelayedLoadingBoolean";
import DropdownNurseType from "common/types/DropdownNurseType";

const TimeOffReasons = [
  AppointmentTypeEnum.SICK_TIME,
  AppointmentTypeEnum.VACATION,
  AppointmentTypeEnum.PERSONAL_APPOINTMENT,
  AppointmentTypeEnum.BREAK_MEAL,
  AppointmentTypeEnum.INTERNAL_MEETING,
  AppointmentTypeEnum.TRAINING,
  AppointmentTypeEnum.BEREAVEMENT,
  AppointmentTypeEnum.JURY_DUTY
];

interface IProps {
  isVisible: boolean;
  onRequestClose: () => void;
  nurses: DropdownNurseType[];
  managerId: string;
}

const AddTimeOffModal = ({
  isVisible,
  onRequestClose,
  nurses,
  managerId
}: IProps) => {
  const [selectedNurseObject, setSelectedNurseObject] =
    useState<DropdownNurseType | null>(null);
  const [selectedNurseId, setSelectedNurseId] = useState<string>("");
  const [selectedNurseName, setSelectedNurseName] = useState<string>("");
  const [selectedTimeOffReason, setSelectedTimeOffReason] =
    useState<AppointmentTypeEnum>(undefined);
  const [selectedNurseTimezone, setSelectedNurseTimezone] =
    useState<string>("");
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  const { user } = useSelector((state: RootState) => state.auth);

  const resetSelectedNurse = useCallback(() => {
    setSelectedNurseObject(null);
    setSelectedNurseId("");
    setSelectedTimeOffReason(undefined);
    setSelectedNurseName("");
    setSelectedNurseTimezone("");
  }, [
    setSelectedNurseObject,
    setSelectedNurseId,
    setSelectedTimeOffReason,
    setSelectedNurseName,
    setSelectedNurseTimezone
  ]);

  const handleAddTimeOffModalClose = () => {
    onRequestClose();
    resetCreateCalendarEvent();
    resetSelectedNurse();
  };

  const [
    createCalendarEventMutation,
    {
      data: createCalendarEventData,
      isLoading: createCalendarEventIsLoading,
      fulfilledTimeStamp: createCalendarFulfilledTimestamp,
      error: createCalendarEventError,
      reset: resetCreateCalendarEvent
    }
  ] = useCreateCalendarEventMutation();

  useEffect(() => {
    if (createCalendarEventData) {
      setTimeout(() => {
        setShowConfirmationModal(false);
        resetSelectedNurse();
        resetCreateCalendarEvent();
      }, DELAY_AFTER_REQUEST_COMPLETED);
    }
  }, [createCalendarEventData]);

  const xTraceId = useMemo(() => `add-pto-${uuidv4()}`, []);

  const onSubmit = useCallback(() => {
    const now = DateTime.now().setZone(selectedNurseTimezone);
    const start = now.set({ hour: 8, minute: 30, second: 0, millisecond: 0 });
    const end = now.set({ hour: 17, minute: 0, second: 0, millisecond: 0 });

    createCalendarEventMutation({
      body: {
        start_date: start.toUTC().toISO(),
        end_date: end.toUTC().toISO(),
        appointment_type: selectedTimeOffReason,
        created_by: user?.user_id
      },
      staffId: selectedNurseId,
      xTraceId,
      managerId
    });
  }, [selectedNurseId, selectedTimeOffReason]);

  const isLoading = useGetDelayedLoadingBoolean(
    createCalendarEventIsLoading,
    createCalendarFulfilledTimestamp,
    createCalendarEventData,
    DELAY_AFTER_REQUEST_COMPLETED
  );

  return (
    <>
      <StyledModal
        isOpen={isVisible}
        onRequestClose={handleAddTimeOffModalClose}
        modalHeight="70vh"
        contentLabel="Change Role"
      >
        <ModalHeader onRequestClose={handleAddTimeOffModalClose}>
          Add Same Day Time Off for Today
        </ModalHeader>
        <ModalBody>
          <Autocomplete
            options={nurses}
            value={selectedNurseObject}
            getOptionLabel={(nurse) => {
              return nurse?.label?.displayLastNameFirst;
            }}
            onChange={(e, value) => {
              const selectedNurse = nurses?.find(
                (item) => item?.value === value?.value
              );
              const selectedNurseName =
                selectedNurse?.label?.displayFirstNameFirst;

              setSelectedNurseObject(value);
              setSelectedNurseId(value?.value);
              setSelectedNurseName(selectedNurseName);
              setSelectedNurseTimezone(value?.label?.timezone);
            }}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                label="Select Nurse"
                variant="outlined"
              />
            )}
          />

          <br />
          <StyledTextField
            select
            label="Select Reason"
            SelectProps={{
              variant: "outlined",
              value: selectedTimeOffReason,
              onChange: (event) => {
                setSelectedTimeOffReason(event.target.value);
              },
              MenuProps: { PaperProps: { sx: { maxHeight: 200 } } }
            }}
          >
            {TimeOffReasons?.map((reason) => {
              return (
                <MenuItem key={reason} value={reason}>
                  {formatName(reason)}
                </MenuItem>
              );
            })}
          </StyledTextField>
        </ModalBody>

        <ModalFooter>
          <ModalFooterButtons>
            <TurqoiseButton
              onClick={() => {
                setShowConfirmationModal(true);
                onRequestClose();
              }}
              disabled={
                isFalsy(selectedNurseId) || isFalsy(selectedTimeOffReason)
              }
            >
              Submit
            </TurqoiseButton>
          </ModalFooterButtons>
        </ModalFooter>
      </StyledModal>

      <StyledModal
        isOpen={showConfirmationModal}
        onRequestClose={() => {
          setShowConfirmationModal(false);
          setSelectedTimeOffReason(undefined);
          resetSelectedNurse();
        }}
        modalHeight="70vh"
        contentLabel="Change Role"
      >
        <ModalHeader
          onRequestClose={() => {
            setShowConfirmationModal(false);
            setSelectedTimeOffReason(undefined);
            resetSelectedNurse();
          }}
        >
          Add Same Day Time Off for Today
        </ModalHeader>
        <ModalBody>
          <Box>
            You're adding same-day time off for {selectedNurseName}. <br />
            This will put all of their appointments for today up for
            reassignment. This action cannot be undone.
          </Box>
          {createCalendarEventError && (
            <ErrorComponent
              error={createCalendarEventError}
              hideErrorCode
              showErrorResponseMessage
            />
          )}
        </ModalBody>

        <ModalFooter>
          <ModalFooterButtons>
            <LoadingButton
              variant="contained"
              loading={isLoading}
              onClick={onSubmit}
              disabled={isFalsy(selectedNurseId)}
              sx={{ textTransform: "none", height: 50 }}
            >
              Confirm
            </LoadingButton>
            <WhiteButton
              onClick={() => {
                setShowConfirmationModal(false);
                setSelectedTimeOffReason(undefined);
                resetSelectedNurse();
              }}
            >
              Go Back
            </WhiteButton>
          </ModalFooterButtons>
        </ModalFooter>
      </StyledModal>
    </>
  );
};

export default AddTimeOffModal;
