import { useEffect } from "react";
import styled from "@emotion/styled";
import { DateTime } from "luxon";
import { useFormik } from "formik";
import { TurqoiseButton, WhiteButton } from "../../../../styling";
import {
  ModalFooter,
  ModalFooterButtons
} from "../../../../styling/StyleModal";
import { TextFieldComponent } from "../FormHelpers";
import { MenuItem } from "@mui/material";
import {
  useAddActionsToTaskMutation,
  useUpdateTaskStatusMutation
} from "common/services/TaskingService";
import ErrorComponent from "../../../../components/ErrorComponent";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import { useAppDispatch } from "common/redux";
import CallOutcomeEnum from "common/enums/CallOutcomeEnum";
import { Flexbox } from "../../../../styling/NewStyleComponents";
import TaskType from "common/types/TaskType";
import { hasReadTask } from "../../../../pages/Tasks/helpers";
import TaskTypeEnum from "common/enums/TaskTypeEnum";

const StyledWhiteButton = styled(WhiteButton)`
  display: flex;
`;

const Form = styled.form`
  height: 100%;
`;

const OUTCOME_BILLING_OPTIONS = [
  { label: "Fixed", value: CallOutcomeEnum.FIXED },
  { label: "Dismiss", value: CallOutcomeEnum.DISMISS }
];
const OUTCOME_ALERTABLE_OPTIONS = [
  { label: "Contacted", value: CallOutcomeEnum.CONTACTED },
  { label: "Dismiss", value: CallOutcomeEnum.DISMISS },
  {
    label: "Left Voicemail",
    value: CallOutcomeEnum.LEFT_VOICEMAIL
  },
  { label: "No Answer", value: CallOutcomeEnum.NO_ANSWER }
];
interface FormValues {
  outcome: string;
  notes: string;
}

interface IProps {
  assigneeId: string;
  task: TaskType;
}

const UpdateCallOutcomeForm = ({ assigneeId, task }: IProps) => {
  const contactAttempts = task?.task?.activity?.number_of_contact_attempts;
  const taskId = task?.task?.task_id;
  const taskType = task?.task?.type;
  const taskRead = hasReadTask(task);

  const dispatch = useAppDispatch();
  const [
    updateTaskStatusMutation,
    {
      error: updateTaskStatusError,
      isSuccess: updateTaskStatusIsSuccess,
      isLoading: updateTaskStatusIsLoading
    }
  ] = useUpdateTaskStatusMutation();

  const [
    addActionsToTaskMutation,
    {
      isSuccess: addActionsToTaskSuccess,
      error: addActionsToTaskError,
      isLoading: addActionsToTaskIsLoading
    }
  ] = useAddActionsToTaskMutation();

  useEffect(() => {
    if (updateTaskStatusIsSuccess) {
      Alert_close({ dispatch, id: taskId });
      formik.resetForm();
      const id = `update_call_success_${taskId}_${assigneeId}`;
      Alert_show({
        dispatch,
        id,
        title: "Success",
        content: "Updated task status successfully.",
        type: "success",
        size: "small",
        buttons: [
          {
            text: "Close",
            onPress: () => {
              Alert_close({ dispatch, id });
            }
          }
        ]
      });
    }
  }, [updateTaskStatusIsSuccess]);
  useEffect(() => {
    if (updateTaskStatusError) {
      Alert_close({ dispatch, id: taskId });
      formik.resetForm();
      const id = `update_call_error_${taskId}_${assigneeId}`;
      Alert_show({
        dispatch,
        id,
        title: "Error",
        content: <ErrorComponent error={updateTaskStatusError} />,
        type: "error",
        size: "small",
        buttons: [
          {
            text: "Close",
            onPress: () => {
              Alert_close({ dispatch, id });
            }
          }
        ]
      });
    }
  }, [updateTaskStatusError]);
  useEffect(() => {
    if (addActionsToTaskSuccess) {
      Alert_close({ dispatch, id: taskId });
      formik.resetForm();
      const id = `add_call_action_success_${taskId}_${assigneeId}`;
      Alert_show({
        dispatch,
        id,
        title: "Success",
        content: "Updated call status successfully.",
        type: "success",
        size: "small",
        buttons: [
          {
            text: "Close",
            onPress: () => {
              Alert_close({ dispatch, id });
            }
          }
        ]
      });
    }
  }, [addActionsToTaskSuccess]);

  useEffect(() => {
    if (addActionsToTaskError) {
      Alert_close({ dispatch, id: taskId });
      formik.resetForm();
      const id = `add_call_action_error_${taskId}_${assigneeId}`;
      Alert_show({
        dispatch,
        id,
        title: "Error",
        content: <ErrorComponent error={addActionsToTaskError} />,
        type: "error",
        size: "small",
        buttons: [
          {
            text: "Close",
            onPress: () => {
              Alert_close({ dispatch, id });
            }
          }
        ]
      });
    }
  }, []);

  const validate = (values: FormValues) => {
    const errors = {};

    if (!values.outcome) {
      errors["outcome"] = "Required";
    }

    if (isBillingOption && values.outcome === CallOutcomeEnum.DISMISS) {
      if (values.notes.length < 1) {
        errors["notes"] = "Required";
      }

      if (values.notes.length > 5000) {
        errors["notes"] = "Notes cannot be more than 5000 characters";
      }
    }

    return errors;
  };

  const setFieldValue = (key, value) => {
    formik.setFieldValue(key, value).catch((error) => {});
  };

  const onSubmit = async (values: FormValues) => {
    const { outcome, notes } = values;
    const nowJS = new Date();
    const now = DateTime.fromJSDate(nowJS).toUTC().toISO();

    // if selected call outcome is contacted/dismiss or if this is the third contact attempt, mark as resolved
    if (
      !isBillingOption &&
      (outcome === CallOutcomeEnum.CONTACTED ||
        outcome === CallOutcomeEnum.DISMISS ||
        contactAttempts >= 2)
    ) {
      await updateTaskStatusMutation({
        task_id: taskId,
        status: "COMPLETED",
        assignee_id: assigneeId,
        ...(!taskRead && {
          body: [
            {
              type: "READ",
              occurred_at: DateTime.now().toUTC().toISO(),
              performed_by: assigneeId,
              modified_by: assigneeId
            },
            {
              type: "PHONE_CALL",
              outcome,
              performed_by: assigneeId,
              occurred_at: now,
              modified_by: assigneeId,
              notes
            }
          ]
        })
      });
    } else if (
      isBillingOption &&
      (outcome === CallOutcomeEnum.FIXED || outcome === CallOutcomeEnum.DISMISS)
    ) {
      await updateTaskStatusMutation({
        task_id: taskId,
        status: "COMPLETED",
        assignee_id: assigneeId,
        body: [
          {
            type: "RESOLVED",
            outcome,
            notes,
            occurred_at: DateTime.now().toUTC().toISO(),
            performed_by: assigneeId,
            modified_by: assigneeId
          }
        ]
      });
    } else {
      // add call action
      const addActionToTaskReqBody: any = [
        {
          type: "PHONE_CALL",
          outcome,
          performed_by: assigneeId,
          occurred_at: now,
          modified_by: assigneeId,
          notes
        }
      ];

      if (!taskRead) {
        addActionToTaskReqBody.push({
          type: "READ",
          performed_by: assigneeId,
          modified_by: assigneeId
        });
      }

      await addActionsToTaskMutation({
        task_id: taskId,
        body: addActionToTaskReqBody,
        assignee_id: assigneeId
      });
    }
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      outcome: "",
      notes: ""
    },
    enableReinitialize: true,
    validate,
    onSubmit
  });

  const handleModalClose = () => {
    formik.resetForm();
    Alert_close({ dispatch, id: taskId });
  };

  const isBillingOption =
    taskType === TaskTypeEnum.BILLING_MISSING_INSURANCE ||
    taskType === TaskTypeEnum.BILLING_MISSING_DIAGNOSIS;

  const OUTCOME_OPTIONS = isBillingOption
    ? OUTCOME_BILLING_OPTIONS
    : OUTCOME_ALERTABLE_OPTIONS;

  return (
    <Flexbox
      flexDirection="column"
      height="100%"
      justifyContent="space-between"
    >
      <Form onSubmit={formik.handleSubmit}>
        <Flexbox
          flexDirection="column"
          height="100%"
          justifyContent="space-between"
        >
          <Flexbox flexDirection="column">
            <TextFieldComponent
              sx={{ mb: "8px" }}
              formik={formik}
              fieldName={"outcome"}
              label={"Outcome"}
              select={true}
              SelectProps={{
                variant: "outlined",
                MenuProps: { PaperProps: { sx: { maxHeight: 200 } } }
              }}
              placeholder={"Choose Outcome"}
              fullWidth={true}
              setFieldValue={setFieldValue}
            >
              {OUTCOME_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextFieldComponent>

            <TextFieldComponent
              formik={formik}
              fieldName={"notes"}
              label={"Notes"}
              placeholder={"Enter Notes"}
              fullWidth={true}
              setFieldValue={setFieldValue}
              required={
                isBillingOption &&
                formik.values.outcome === CallOutcomeEnum.DISMISS
              }
            />
          </Flexbox>

          {isBillingOption &&
            formik.values.outcome === CallOutcomeEnum.DISMISS && (
              <ErrorComponent
                error={
                  "If this task is dismissed, billing for this member will not be possible and charges will be dismissed. Please justify with a note. If you think this is an error, please contact support."
                }
              />
            )}

          <ModalFooter>
            <ModalFooterButtons margin="0">
              <TurqoiseButton
                disabled={!formik.dirty || !formik.isValid}
                type="submit"
                loading={updateTaskStatusIsLoading || addActionsToTaskIsLoading}
              >
                {/* if selected call outcome is contacted or if this is the third
            contact attempt, mark as resolved */}
                {formik?.values?.outcome === CallOutcomeEnum.CONTACTED ||
                contactAttempts >= 2
                  ? "Resolve"
                  : "Update"}
              </TurqoiseButton>
              <StyledWhiteButton onClick={handleModalClose}>
                Cancel
              </StyledWhiteButton>
            </ModalFooterButtons>
          </ModalFooter>
        </Flexbox>
      </Form>
    </Flexbox>
  );
};

export { UpdateCallOutcomeForm };
